import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import './style.scss';

import ReactCrop from 'react-image-crop';
import { Button } from '@material-ui/core';
import Dialog from '../../common/Dialog';
import FormControl from '../../common/FormControl';
import * as PortfolioAction from '../../../store/actions/portfolio.action';
import * as PortfolioService from '../../../services/portfolio.service';
import * as ToastAction from '../../../store/actions/toast.action';
import * as CommonAction from '../../../store/actions/common.action';
import mobileDevices from '../../../constants/templates';
import { getCropFromDevice, getCroppedImage } from '../../../utils';
import Validators from '../../common/FormControl/validators';
import { patchFormData, setFormControlOptions, validateFormGroup } from '../../common/FormControl/utils';

let image = new Image();

const UploadDetailModal = ({
  open, tags, uploadingInfo, addMedia, editMedia, setTags, user,
  goToNextImage, goToPrevImage, setUploadingInfo, startUploading, finishUploading, createToast,
}) => {
  const form = useMemo(() => ({
    frame: {
      name: 'frame',
      type: 'autocomplete',
      placeholder: 'Select frame',
      options: ['Custom (No frame)'].concat(mobileDevices.map((device) => device.name)),
      controlOptions: {
        disableClearable: true,
      },
    },
    tags: {
      name: 'tags',
      type: 'autocomplete',
      label: 'Tags',
      placeholder: 'Add tags',
      value: [],
      options: [],
      controlOptions: {
        multiple: true,
        freeSolo: true,
      },
      validators: [Validators.required()],
    },
    fillLater: {
      name: 'fillLater',
      type: 'checkbox',
      label: 'I\'ll fill other details later.',
    },
    applySameTags: {
      name: 'applySameTags',
      type: 'checkbox',
      label: 'Apply same tags to all medias.',
    },
    description: {
      name: 'description',
      type: 'summer-note',
      label: 'Description *',
      maxLength: 400,
      validators: [Validators.required(), Validators.maxLength(400, true)],
    },
  }), []);

  const [device, setDevice] = useState('Custom (No frame)');
  const [crop, setCrop] = useState({
    unit: '%',
    x: 0,
    y: 0,
    width: 100,
    height: 100,
  });
  const [imageTags, setImageTags] = useState([]);
  const [description, setDescription] = useState('');
  const [fillLater, setFillLater] = useState(false);
  const [applySameTags, setApplySameTags] = useState(false);

  const tagNames = useMemo(() => tags.sort((tag1, tag2) => tag2.cnt - tag1.cnt).map((tag) => tag.name), [tags]);
  const file = useMemo(() => uploadingInfo.files[uploadingInfo.index], [uploadingInfo]);

  useEffect(() => {
    setFormControlOptions(form.tags, {
      options: tagNames,
    });
  }, [form.tags, tagNames]);

  useEffect(() => {
    patchFormData(form, {
      frame: device,
      tags: imageTags,
      description,
      fillLater,
      applySameTags,
    });
  }, [applySameTags, description, device, fillLater, form, imageTags]);

  useEffect(() => {
    if (fillLater) {
      form.tags.validators = [];
      form.description.validators = [];
    } else {
      form.tags.validators = [Validators.required()];
      form.description.validators = [Validators.required(), Validators.maxLength(400, true)];
    }
    validateFormGroup(form);
  }, [fillLater, form]);

  const onDeviceChanged = (deviceName) => {
    setDevice(deviceName);
    setCrop(getCropFromDevice(image, deviceName));
  };

  const getBestMatchDevice = useCallback(() => {
    if (image) {
      image.remove();
    }
    image = new Image();
    image.src = file.preview;
    image.onload = () => {
      image.onload = null;

      const imageScale = image.width / image.height;
      let minDist = 0.3;
      let matchDevice = 'Custom (No frame)';
      mobileDevices.forEach((device) => {
        const dist = Math.abs(imageScale - device.screenWidth / device.screenHeight);
        if (minDist > dist) {
          minDist = dist;
          matchDevice = device.name;
        }
      });

      onDeviceChanged(matchDevice);
    };
  }, [file]);

  useEffect(() => {
    if (!file)
      return;

    if (file.image) {
      setImageTags(file.image.tags.map((tag) => tag.name));
      setDescription(file.image.description);
      const { crop } = file.image;
      const device = mobileDevices.find((d) => d.name === crop.device);
      if (device) {
        crop.aspect = device.screenWidth / device.screenHeight;
      }
      setDevice(crop.device);
      setCrop(crop);
      image.src = file.preview;
    } else {
      if (!applySameTags) {
        setImageTags([]);
      }
      setDescription('');
      getBestMatchDevice();
    }
  }, [file, getBestMatchDevice]);

  const uploadImage = async (orgImage, imageCrop, onSuccess = null, imageId = 0, imageCnt = 1) => {
    let newImage = null;
    if (orgImage.type.startsWith('image'))
      newImage = await getCroppedImage(image, device, imageCrop, orgImage.type) || orgImage;
    const type = orgImage.type ? orgImage.type.split(/\W/)[0] : 'media';

    if (imageId === 0) {
      startUploading(`Uploading ${imageCnt === 1 ? type : `${imageCnt} medias`}. Please wait...`);
    }

    const versionIds = uploadingInfo.version.split('.');
    versionIds[versionIds.length - 1] = parseInt(versionIds[versionIds.length - 1], 10) + imageId + uploadingInfo.index;
    const version = versionIds.join('.');

    const result = await PortfolioService.uploadImage(
      {
        project: uploadingInfo.project,
        section: uploadingInfo.section,
        subSection: uploadingInfo.subSection,
        status: uploadingInfo.status,
        version,
        description,
        tags: imageTags,
        crop: JSON.stringify({
          device,
          ...imageCrop,
        }),
        orgImage,
        newImage,
        lastModifiedBy: user.name,
      },
      imageId,
      imageCnt,
    );
    if (imageId >= imageCnt - 1) {
      finishUploading();
    }

    if (result.error || !result.data.image) {
      createToast({
        type: 'error',
        title: 'Failed!',
        message: result.message || `Uploading ${type} has been failed.`,
      });
    } else {
      createToast({
        type: 'success',
        message: `${type.substr(0, 1).toUpperCase() + type.substr(1)} has been uploaded successfully.`,
      });
      addMedia(result.data.image);
      setTags(result.data.tags);

      if (onSuccess)
        onSuccess();
    }
  };

  const onSave = async () => {
    if (!validateFormGroup(form))
      return;

    const orgImage = uploadingInfo.files[uploadingInfo.index];
    let newImage = null;
    if (orgImage.type.startsWith('image')) {
      newImage = await getCroppedImage(image, device, crop, orgImage.type) || orgImage;
    }
    const type = orgImage.type ? orgImage.type.split(/\W/)[0] : 'media';
    const editingImage = orgImage.image;

    if (editingImage) {
      startUploading(`Uploading ${type}. Please wait...`);
      const result = await PortfolioService.editMedia({
        _id: editingImage._id,
        section: uploadingInfo.section,
        subSection: uploadingInfo.subSection,
        status: uploadingInfo.status,
        version: editingImage.version,
        lastModified: new Date(),
        description,
        tags: imageTags,
        type: orgImage.type,
        crop: JSON.stringify({
          ...crop,
          device,
        }),
        imageName: `${editingImage._id}.${editingImage.type.split(/\W/)[1]}`,
        newImage,
        orgImage: null,
        lastModifiedBy: user.name,
        saveHistory: false,
      });
      finishUploading();

      if (result.error || !result.data.image) {
        createToast({
          type: 'error',
          title: 'Failed!',
          message: result.message || 'Edit has been failed.',
        });
      } else {
        const { image, tags } = result.data;
        if (image.type.startsWith('image')) {
          image.url = URL.createObjectURL(newImage);
        }
        const type = image.type ? image.type.split(/\W/)[0] : 'media';
        createToast({
          type: 'success',
          message: `${type.substr(0, 1)
            .toUpperCase() + type.substr(1)} has been edited successfully.`,
        });
        editMedia(image);
        setTags(tags);
        goToNextImage();
      }
    } else {
      await uploadImage(orgImage, crop, goToNextImage);
    }
  };

  const loadImageWithCurrentFrame = (file) => (
    new Promise((resolve) => {
      if (image) {
        image.remove();
      }
      image = new Image();
      image.src = file.preview;
      image.onload = () => {
        image.onload = null;
        resolve(getCropFromDevice(image, device));
      };
      image.onerror = () => {
        image.onerror = null;
        resolve(null);
      };
    })
  );

  const onSaveAll = async () => {
    if (!validateFormGroup(form))
      return;

    const totalCount = uploadingInfo.files.length - uploadingInfo.index;
    const startIndex = uploadingInfo.index;
    for (let i = 0; i < totalCount; i++) {
      const orgImage = uploadingInfo.files[i + startIndex];
      let imageCrop = crop;
      if (i > 0)
        imageCrop = await loadImageWithCurrentFrame(orgImage);
      if (!imageCrop)
        continue;

      await uploadImage(orgImage, imageCrop, null, i, totalCount);
    }

    setUploadingInfo(null);
  };

  if (!file) {
    return null;
  }

  return (
    <Dialog
      title={`Upload Media (${uploadingInfo.index + 1} of ${uploadingInfo.files.length})`}
      className="image-detail-modal"
      open={open}
      onClose={goToNextImage}
      footerActions={(
        <div className="w-100 d-flex align-items-center justify-content-between">
          <div>
            {uploadingInfo.files.length > 1 && (
              <>
                <Button className="btn-warning size-sm px-3 mr-2" disabled={!uploadingInfo.index} onClick={goToPrevImage}>Back</Button>
                {
                  file.image
                    ? <Button className="btn-success size-sm px-3 mr-2" onClick={goToNextImage}>Next</Button>
                    : <Button className="btn-danger size-sm px-3 mr-2" onClick={goToNextImage}>Discard</Button>
                }
              </>
            )}
          </div>
          <div>
            {uploadingInfo.files.length === 1 && (
              <Button className="size-sm px-3 mr-2" onClick={goToNextImage}>Discard</Button>
            )}
            <Button className="btn-primary size-sm px-3" onClick={onSave}>Save</Button>
            {(uploadingInfo.index < uploadingInfo.files.length - 1) && (
              <Button className="btn-primary size-sm ml-2 px-3" onClick={onSaveAll}>
                {`Save All (${uploadingInfo.files.length - uploadingInfo.index})`}
              </Button>
            )}
          </div>
        </div>
      )}
    >
      <div className="">
        {
          file.type.startsWith('image')
            ? (
              <div className="text-center">
                <FormControl control={form.frame} size="sm" onChange={(_, newDevice) => onDeviceChanged(newDevice)} />
                <ReactCrop
                  src={file.preview}
                  crop={crop}
                  keepSelection
                  onChange={(newCrop, percentCrop) => setCrop(percentCrop)}
                />
              </div>
            )
            // eslint-disable-next-line jsx-a11y/media-has-caption
            : <video className="mw-100" controls src={file.preview} />
        }
      </div>

      <FormControl control={form.fillLater} size="sm" onChange={(_, checked) => setFillLater(checked)} />

      <FormControl control={form.tags} size="sm" onChange={(_, newTags) => setImageTags(newTags)} />

      {uploadingInfo.files.length > 1 && !uploadingInfo.index && (
        <FormControl control={form.applySameTags} size="sm" onChange={(_, checked) => setApplySameTags(checked)} />
      )}

      <FormControl control={form.description} size="sm" className="mb-0" onChange={(_, value) => setDescription(value)} />
    </Dialog>
  );
};

UploadDetailModal.propTypes = {
  open: PropTypes.bool.isRequired,
  tags: PropTypes.array,
  uploadingInfo: PropTypes.object.isRequired,
  setTags: PropTypes.func.isRequired,
  goToNextImage: PropTypes.func.isRequired,
  goToPrevImage: PropTypes.func.isRequired,
  setUploadingInfo: PropTypes.func.isRequired,
  addMedia: PropTypes.func.isRequired,
  editMedia: PropTypes.func.isRequired,
  createToast: PropTypes.func.isRequired,
  startUploading: PropTypes.func.isRequired,
  finishUploading: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

UploadDetailModal.defaultProps = {
  tags: [],
};

const mapStateToProps = (store) => ({
  tags: store.portfolioReducer.tags,
  uploadingInfo: store.portfolioReducer.uploadingInfo,
  user: store.authReducer.user,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setTags: PortfolioAction.setTags,
      goToNextImage: PortfolioAction.goToNextImage,
      goToPrevImage: PortfolioAction.goToPrevImage,
      setUploadingInfo: PortfolioAction.setUploadingInfo,
      addMedia: PortfolioAction.addMedia,
      editMedia: PortfolioAction.editMedia,
      createToast: ToastAction.createToast,
      startUploading: CommonAction.startUploading,
      finishUploading: CommonAction.finishUploading,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadDetailModal);
