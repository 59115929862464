import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

import { connect } from 'react-redux';
import Toast from '../Toast';

const ToastView = ({ toasts }) => (
  <div className="toast-view">
    {
      toasts.map((toast) => <Toast key={toast.id} {...toast} />)
    }
  </div>
);

ToastView.propTypes = {
  toasts: PropTypes.array.isRequired,
};

const mapStateToProps = (store) => ({
  toasts: store.toastReducer.toasts,
});

export default connect(mapStateToProps)(ToastView);
