import ACTION from '../actionTypes';

export function deleteToast(toastId) {
  return {
    type: ACTION.DELETE_TOAST,
    toastId,
  };
}

export function createToast(toast) {
  return {
    type: ACTION.CREATE_TOAST,
    toast,
  };
}
