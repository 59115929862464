import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import './style.scss';

import { Button, Grid } from '@material-ui/core';
import ImagesGridView from '../ImagesGridView';
import Dialog from '../../common/Dialog';
import FormControl from '../../common/FormControl';
import * as PortfolioAction from '../../../store/actions/portfolio.action';
import * as PortfolioService from '../../../services/portfolio.service';
import * as ToastAction from '../../../store/actions/toast.action';
import * as CommonAction from '../../../store/actions/common.action';
import Validators from '../../common/FormControl/validators';
import { setFormControlOptions, validateFormGroup } from '../../common/FormControl/utils';

const MassEditModal = ({
  open, images, onClose, tags, massEditMedias, setTags, startLoading, finishLoading, createToast,
}) => {
  const form = useMemo(() => ({
    section: {
      name: 'section',
      type: 'autocomplete',
      label: 'Section',
      placeholder: 'Select section',
      options: [],
      controlOptions: {
        freeSolo: true,
      },
    },
    subSection: {
      name: 'subSection',
      type: 'autocomplete',
      label: 'Sub Section',
      placeholder: 'Select sub section',
      options: [],
      controlOptions: {
        freeSolo: true,
      },
    },
    tags: {
      name: 'tags',
      type: 'autocomplete',
      label: 'Tags',
      placeholder: 'Add tags',
      value: [],
      options: [],
      controlOptions: {
        multiple: true,
        freeSolo: true,
      },
    },
    description: {
      name: 'description',
      type: 'summer-note',
      label: 'Description',
      maxLength: 400,
      validators: [Validators.maxLength(400, true)],
    },
  }), []);

  const [editingImages, setEditingImages] = useState(images);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    setEditingImages(images);
  }, [images]);

  useEffect(() => {
    const sections = [...new Set(images.map((image) => image.section).filter((name) => name))].sort();
    const subSections = [...new Set(images.map((image) => image.subSection).filter((name) => name))].sort();
    setFormControlOptions(form.section, {
      options: sections,
    });
    setFormControlOptions(form.subSection, {
      options: subSections,
    });
  }, [form.section, form.subSection, images]);

  useEffect(() => {
    setFormControlOptions(form.tags, {
      options: tags.sort((tag1, tag2) => tag2.cnt - tag1.cnt).map((tag) => tag.name),
    });
  }, [form.tags, tags]);

  const onRemoveMedia = (imageId) => {
    const filteredImages = editingImages.filter((image) => image._id !== imageId);
    setEditingImages(filteredImages);
    if (!filteredImages.length) {
      onClose();
    }
  };

  const onInputChange = () => {
    setHasChanges(
      form.section.value || form.subSection.value || form.tags.value.length || form.description.value,
    );
  };

  const onSave = async () => {
    if (!validateFormGroup(form)) {
      return;
    }

    const changes = {};
    if (form.section.value)
      changes.section = form.section.value;
    if (form.subSection.value)
      changes.subSection = form.subSection.value;
    if (form.tags.value.length)
      changes.tags = form.tags.value;
    if (form.description.value)
      changes.description = form.description.value;

    if (!Object.keys(changes).length)
      return;

    startLoading();
    const result = await PortfolioService.massEditMedias(
      editingImages.map((image) => image._id),
      changes,
    );
    finishLoading();

    if (result.error) {
      createToast({
        type: 'error',
        title: 'Failed!',
        message: result.message || 'Mass edit has been failed.',
      });
    } else {
      massEditMedias(result.data.imageIds, result.data.changes);
      setTags(result.data.tags);
      createToast({
        type: 'success',
        message: 'Mass edit has been finished successfully.',
      });
      onClose();
    }
  };

  return (
    <Dialog
      title="Mass Edit Media"
      className="mass-edit-modal"
      maxWidth="md"
      open={open}
      onClose={onClose}
      footerActions={(
        <>
          <Button className="size-sm px-3 mr-2" onClick={onClose}>Cancel</Button>
          <Button className="btn-primary size-sm px-3" disabled={!hasChanges} onClick={onSave}>Save</Button>
        </>
      )}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ImagesGridView images={editingImages} onRemoveMedia={onRemoveMedia} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl control={form.section} size="sm" className="mb-0" onChange={onInputChange} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl control={form.subSection} size="sm" className="mb-0" onChange={onInputChange} />
        </Grid>
        <Grid item xs={12}>
          <FormControl control={form.tags} size="sm" className="mb-0" onChange={onInputChange} />
        </Grid>
        <Grid item xs={12}>
          <FormControl control={form.description} size="sm" className="mb-0" onChange={onInputChange} />
        </Grid>
      </Grid>
    </Dialog>
  );
};

MassEditModal.propTypes = {
  open: PropTypes.bool,
  images: PropTypes.array,
  tags: PropTypes.array,
  onClose: PropTypes.func,
  massEditMedias: PropTypes.func.isRequired,
  setTags: PropTypes.func.isRequired,
  createToast: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
};

MassEditModal.defaultProps = {
  open: false,
  images: [],
  tags: [],
  onClose: null,
};

const mapStateToProps = (store) => ({
  tags: store.portfolioReducer.tags,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      massEditMedias: PortfolioAction.massEditMedias,
      setTags: PortfolioAction.setTags,
      createToast: ToastAction.createToast,
      startLoading: CommonAction.startLoading,
      finishLoading: CommonAction.finishLoading,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MassEditModal);
