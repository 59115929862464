export default (editor) => {
  const domc = editor.DomComponents;
  let ready = false;

  domc.addType('video', {
    model: {
      init() {
      },
    },

    view: {
      events: {
        dblclick() {
          const am = editor.AssetManager;
          am.render(am.getAll().filter(
            (asset) => asset.get('type') === 'video',
          ));
          editor.runCommand('open-assets', {
            accept: 'video/*',
            types: ['video'],
            target: editor.getSelected(),
          });
        },
      },
    },
  });

  editor.on('load', () => {
    ready = true;
  });

  editor.on('component:create', (model) => {
    if (!ready)
      return;

    if (model.get('type') === 'video') {
      const am = editor.AssetManager;
      am.render(am.getAll().filter(
        (asset) => asset.get('type') === 'video',
      ));
      editor.runCommand('open-assets', {
        accept: 'video/*',
        types: ['video'],
        target: model,
      });
    }
  });
};
