import axios from 'axios';

import config from '../constants/config';
import storage from './storage.service';

const tokenKey = config.TOKEN_KEY;
const baseURL = config.API_SERVER;

const http = axios.create({ baseURL: `${baseURL}/` });

function httpErrorHandler(err) {
  return {
    data: {
      error: true,
      message: err.statusText || 'Network Error!',
    },
  };
}

function get(url, headers = {}, params = {}) {
  const accessToken = storage.getItem(tokenKey);
  const authHeader = { Authorization: `Bearer ${accessToken}` };
  return http.get(url, {
    ...params,
    headers: { ...authHeader, ...headers },
  }).catch((e) => httpErrorHandler(e));
}

function post(url, data, headers = {}, params = {}) {
  const accessToken = storage.getItem(tokenKey);
  const authHeader = { Authorization: `Bearer ${accessToken}` };
  return http.post(url, data, {
    ...params,
    headers: { ...authHeader, ...headers },
  }).catch((e) => httpErrorHandler(e));
}

function put(url, data, headers = {}, params = {}) {
  const accessToken = storage.getItem(tokenKey);
  const authHeader = { Authorization: `Bearer ${accessToken}` };
  return http.put(url, data, {
    ...params,
    headers: { ...authHeader, ...headers },
  }).catch((e) => httpErrorHandler(e));
}

function remove(url, data, headers = {}) {
  const accessToken = storage.getItem(tokenKey);
  const authHeader = { Authorization: `Bearer ${accessToken}` };
  return http.delete(url, {
    headers: { ...authHeader, ...headers },
    data,
  }).catch((e) => httpErrorHandler(e));
}

export default {
  http,
  get,
  post,
  put,
  remove,
  httpErrorHandler,
};
