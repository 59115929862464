import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './style.scss';

const ConfirmEmail = ({
  location,
}) => {
  const email = useMemo(() => location.state.email, [location.state.email]);

  return (
    <div className="confirm-email">
      <div className="card-box">
        <h1 className="text-center mb-3">Confirm Email</h1>
        <div className="panel-body text-center">
          <img src="resources/images/mail_confirm.png" alt="EMAIL" className="thumb-lg mt-3 center-block" width="88px" />
          <p className="text-muted m-b-0 font-13p mt-3">
            An email has been send to&nbsp;
            <b>{email}</b>
            &nbsp;to reset password.
            <br />
            Be sure to check your spam folder if you do not see it.
          </p>
        </div>
      </div>

      <div className="text-center mt-3">
        Return to
        <Link to="/sign-in" className="text-primary ml-2">
          <b>Sign In</b>
        </Link>
      </div>
    </div>
  );
};

ConfirmEmail.propTypes = {
  location: PropTypes.object.isRequired,
};

export default ConfirmEmail;
