import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import './GridViewItem.scss';

import config from '../../../constants/config';

const GridViewItem = ({
  image, onRemoveMedia,
}) => {
  const url = useMemo(() => {
    if (image.type.startsWith('image')) {
      return `${config.PORTFOLIO_URL}/${image._id}.${image.type.split(/\W/)[1]}?q=${image.updatedAt}`;
    }
    return `${config.VIDEO_URL}/${image._id}.${image.type.split(/\W/)[1]}?q=${image.updatedAt}`;
  }, [image]);

  const onRemove = () => {
    if (!window.confirm(`Are you sure to remove this ${image.type.split(/\W/)[0]}?`)) {
      return;
    }

    onRemoveMedia(image._id);
  };

  return (
    <div className="grid-view-item">
      <div className="image">
        {
          image.type.startsWith('image')
            ? <img className="mw-100 mh-100" src={url} alt={image._id} />
            : (
              <div className="video-frame">
                <img
                  src="resources/images/video/video_frame.png"
                  alt={image._id}
                  className="video-frame-img"
                />
                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                <video className="mw-100 mh-100" src={url} />
              </div>
            )

        }
        <span className="back-drop section-name">{image.section}</span>
      </div>
      <div className="title">{image.subSection}</div>
      <i className="fa fa-times-circle remove-button" onClick={onRemove} />
    </div>
  );
};

GridViewItem.propTypes = {
  image: PropTypes.object.isRequired,
  onRemoveMedia: PropTypes.func.isRequired,
};

export default GridViewItem;
