import React from 'react';
import PropTypes from 'prop-types';
import './AuthLayout.scss';

export default function AuthLayout({ children: component }) {
  return (
    <div className="auth-layout">
      <img className="logo" src="/resources/images/logo.png" alt="Portfolio Tool" />
      {component}
    </div>
  );
}

AuthLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]).isRequired,
};
