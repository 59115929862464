import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './ClientMenu.scss';

import { AccountCircle, ChevronRight, Edit } from '@material-ui/icons';
import ProjectItem from './ProjectItem';

const ClientMenu = ({
  client, editable, opened, activeProject, filter, onEditClient, onSelectClient,
}) => {
  const isActive = useMemo(() => {
    if (!activeProject)
      return false;
    return client.projects.some((p) => p._id === activeProject._id);
  }, [activeProject, client.projects]);

  const isFilterSet = useMemo(() => filter.types.length || filter.years.length, [filter]);

  const filteredProjects = useMemo(() => {
    let filtered = client.projects.filter((project) => {
      if (filter.search && project.name.toLowerCase().indexOf(filter.search.toLowerCase()) === -1) {
        return false;
      }
      if (filter.types.length && !project.tags.find((tag) => filter.types.find((type) => type === tag.name))) {
        return false;
      }
      return !(filter.years.length && filter.years.indexOf(project.year) === -1);
    });
    if (filter.sortBy) {
      const sortOptions = {
        'Project Name': 'name',
        'Last Added': '_id',
        'Last Updated': 'updatedAt',
        'Project Type': 'tags',
        'Project Year': 'year',
      };
      const sortField = sortOptions[filter.sortBy];
      let direction = filter.sortOrder === 'asc' ? 1 : -1;
      if (filter.sortBy === 'Last Added' || filter.sortBy === 'Last Updated' || filter.sortBy === 'Project Year') {
        direction = -direction;
      }
      if (sortField) {
        filtered = filtered.sort((a, b) => {
          let value1 = a[sortField] || '###';
          let value2 = b[sortField] || '###';
          if (filter.sortBy === 'Project Type') {
            value1 = value1.map((tag) => tag.name).sort().join(',');
            value2 = value2.map((tag) => tag.name).sort().join(',');
          }
          return value1.localeCompare(value2) * direction;
        });
      }
    }
    return filtered;
  }, [client, filter]);

  const onSelectMenu = () => {
    onSelectClient(client);
  };

  const menuStyle = {
    maxHeight: `${44 * filteredProjects.length + 44}px`,
  };

  if (isFilterSet && !filteredProjects.length)
    return null;

  return (
    <div className={`client-menu ${opened ? 'opened' : 'collapsed'} ${isActive ? 'active' : ''}`} style={menuStyle}>
      <div className="menu-bar d-flex align-items-center justify-content-between" onClick={onSelectMenu}>
        <div className="client-info text-white d-flex align-items-center">
          <AccountCircle />
          <span className="text-lg text-ellipsis" title={client.name}>{client.name}</span>
        </div>
        <div className="menu-actions d-flex align-items-center">
          {editable && (
            <Edit
              className="icon-edit mr-1"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onEditClient(client);
              }}
            />
          )}
          <ChevronRight className="chevron" />
        </div>
      </div>

      <div className="project-list">
        {
          filteredProjects.map((project) => (
            <ProjectItem
              key={project._id}
              project={project}
              editable={editable}
            />
          ))
        }
      </div>
    </div>
  );
};

ClientMenu.propTypes = {
  client: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  filter: PropTypes.object.isRequired,
  opened: PropTypes.bool,
  activeProject: PropTypes.object,
  onEditClient: PropTypes.func.isRequired,
  onSelectClient: PropTypes.func.isRequired,
};

ClientMenu.defaultProps = {
  opened: false,
  editable: true,
  activeProject: null,
};

const mapStateToProps = (store) => ({
  activeProject: store.portfolioReducer.activeProject,
  filter: store.portfolioReducer.projectFilter,
});

export default connect(mapStateToProps)(ClientMenu);
