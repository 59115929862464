import ACTION from '../actionTypes';
import StorageService from '../../services/storage.service';
import config from '../../constants/config';

const initialState = {
  client: null,
  clients: [],
  tags: [],
  contacts: [],
  uploadingInfo: null,
  currentPage: '',
  sidebarCollapsed: false,
  editingProject: null,
  editingImage: null,
  editingContact: null,
  activeProject: null,
  activeTab: null,
  selectedImages: [],
  filteredImages: [],
  capturedImages: [],
  previewImage: null,
  isGlobalSearch: false,
  editPanelVisible: false,
  hasUnsavedData: false,
  weeklyReportedProjects: [],
  projectFilter: {
    isSet: false,
    search: '',
    sortBy: 'Project Name',
    sortOrder: 'asc',
    types: [],
    years: [],
  },
};

export default function portfolioReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION.SIGN_IN:
      return {
        ...initialState,
      };

    case ACTION.SIGN_OUT:
      return {
        ...initialState,
      };

    case ACTION.SET_CLIENT:
      if (state.activeTab === 'case-study' && state.hasUnsavedData
        && !window.confirm('If you navigate away from here without saving your data, changes will be lost. Are you sure to navigate?')) {
        break;
      }
      return {
        ...state,
        client: action.client,
        uploadingInfo: null,
        editingProject: null,
        editingImage: null,
        editingContact: null,
        activeProject: action.client.projects[0],
        previewImage: null,
        selectedImages: [],
        filteredImages: [],
        capturedImages: [],
      };

    case ACTION.SET_CLIENTS:
      return {
        ...state,
        clients: action.clients,
      };

    case ACTION.SET_TAGS:
      return {
        ...state,
        tags: action.tags,
      };

    case ACTION.ADD_CLIENT:
      return {
        ...state,
        clients: [...state.clients, action.client],
        client: action.client,
        activeProject: null,
      };

    case ACTION.EDIT_CLIENT: {
      const client = state.clients.find((c) => c._id === action.client._id);
      const newClient = {
        ...client,
        name: action.client.name,
        website: action.client.website,
        applyAsCompanyWebsite: action.client.applyAsCompanyWebsite,
        phone: action.client.phone,
        applyAsCompanyPhone: action.client.applyAsCompanyPhone,
        applyAsCompanyLogo: action.client.applyAsCompanyLogo,
      };
      const newClients = state.clients.map((c) => (c === client ? newClient : c));

      return {
        ...state,
        clients: newClients,
        client: newClient,
      };
    }

    case ACTION.ADD_PROJECT: {
      const client = state.clients.find((c) => c._id === action.project.client);
      const newClient = {
        ...client,
        projects: [...client.projects, action.project],
      };

      return {
        ...state,
        clients: state.clients.map((c) => (c === client ? newClient : c)),
        client: newClient,
        activeProject: action.project,
      };
    }

    case ACTION.EDIT_PROJECT: {
      const { _id, images, liveLinks, ...newContent } = action.project;
      const client = state.clients.find((c) => c._id === action.project.client);
      const project = client.projects.find((p) => p._id === action.project._id);
      const newProject = {
        ...project,
        ...newContent,
      };
      const newClient = {
        ...client,
        projects: client.projects.map((p) => (p === project ? newProject : p)),
      };

      return {
        ...state,
        clients: state.clients.map((c) => (c === client ? newClient : c)),
        client: newClient,
        activeProject: newProject,
      };
    }

    case ACTION.REMOVE_PROJECT: {
      const client = state.clients.find((c) => c._id === action.project.client);
      const id = client.projects.findIndex((project) => project._id === action.project._id);
      if (id === -1) {
        return state;
      }
      const imageIds = client.projects[id].images.map((image) => image._id);
      let { activeProject } = state;
      if (activeProject && activeProject._id === action.project._id) {
        activeProject = null;
        if (client.projects[id + 1]) {
          activeProject = client.projects[id + 1];
        } else if (client.projects[id - 1]) {
          activeProject = client.projects[id - 1];
        }
      }
      const newClient = {
        ...client,
        projects: client.projects.filter((p) => p._id !== action.project._id),
      };

      return {
        ...state,
        clients: state.clients.map((c) => (c === client ? newClient : c)),
        client: newClient,
        activeProject,
        hasUnsavedData: false,
        selectedImages: state.selectedImages.filter((image) => !imageIds.includes(image._id)),
      };
    }

    case ACTION.MOVE_PROJECT: {
      const sourceClient = state.clients.find((c) => c._id === action.project.client);
      const targetClient = state.clients.find((c) => c._id === action.client._id);
      const newProject = {
        ...action.project,
        client: targetClient._id,
      };
      const newTargetClient = {
        ...targetClient,
        projects: [...targetClient.projects, newProject],
      };
      const newSourceClient = {
        ...sourceClient,
        projects: sourceClient.projects.filter((p) => p._id !== action.project._id),
      };
      const newClients = state.clients.map((c) => {
        if (c === sourceClient)
          return newSourceClient;
        if (c === targetClient)
          return newTargetClient;
        return c;
      });
      return {
        ...state,
        clients: newClients,
        client: newTargetClient,
        activeProject: newProject,
      };
    }

    case ACTION.SET_ACTIVE_PROJECT: {
      if (state.activeTab === 'case-study' && state.hasUnsavedData
        && !window.confirm('If you navigate away from here without saving your data, changes will be lost. Are you sure to navigate?')) {
        break;
      }

      let client = null;
      if (action.project)
        client = state.clients.find((c) => c._id === action.project.client);

      StorageService.setItem(config.LAST_OPENED_PROJECT, action.project ? action.project._id : null);

      return {
        ...state,
        client,
        activeProject: action.project,
        hasUnsavedData: false,
      };
    }

    case ACTION.SET_ACTIVE_TAB: {
      if (state.activeTab === 'case-study' && state.hasUnsavedData
        && !window.confirm('If you navigate away from here without saving your data, changes will be lost. Are you sure to navigate?')) {
        break;
      }

      StorageService.setItem(config.LAST_OPENED_TAB, action.tab);

      return {
        ...state,
        activeTab: action.tab,
        hasUnsavedData: false,
      };
    }

    case ACTION.TOGGLE_SIDE_BAR:
      return {
        ...state,
        sidebarCollapsed: !state.sidebarCollapsed,
      };

    case ACTION.SHOW_SIDE_BAR:
      return {
        ...state,
        sidebarCollapsed: !action.show,
      };

    case ACTION.SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.url,
      };

    case ACTION.SET_UPLOADING_INFO:
      return {
        ...state,
        uploadingInfo: action.info,
      };

    case ACTION.GO_TO_NEXT_IMAGE:
      if (state.uploadingInfo.index === state.uploadingInfo.files.length - 1) {
        return {
          ...state,
          uploadingInfo: null,
        };
      }
      return {
        ...state,
        uploadingInfo: { ...state.uploadingInfo, index: state.uploadingInfo.index + 1 },
      };

    case ACTION.GO_TO_PREV_IMAGE:
      if (state.uploadingInfo.index > 0) {
        return {
          ...state,
          uploadingInfo: { ...state.uploadingInfo, index: state.uploadingInfo.index - 1 },
        };
      }
      break;

    case ACTION.ADD_MEDIA: {
      let projectId = action.image.project;
      if (typeof projectId === 'object')
        projectId = projectId._id;
      const project = state.client.projects.find((p) => p._id === projectId);
      const newProject = {
        ...project,
        images: [...project.images, action.image],
      };
      const client = state.clients.find((c) => c._id === project.client);
      const newClient = {
        ...client,
        projects: state.client.projects.map((p) => (p === project ? newProject : p)),
      };
      const newClients = state.clients.map((c) => (c === client ? newClient : c));
      let newUploadingInfo = null;
      if (state.uploadingInfo) {
        newUploadingInfo = {
          ...state.uploadingInfo,
          files: state.uploadingInfo.files.map((file, index) => {
            if (index === state.uploadingInfo.index) {
              return {
                name: file.name,
                preview: file.preview,
                type: file.type,
                image: action.image,
              };
            }
            return file;
          }),
        };
      }

      return {
        ...state,
        uploadingInfo: newUploadingInfo,
        activeProject: newProject,
        client: newClient,
        clients: newClients,
      };
    }

    case ACTION.EDIT_MEDIA: {
      let projectId = action.image.project;
      if (typeof projectId === 'object')
        projectId = projectId._id;
      const project = state.client.projects.find((p) => p._id === projectId);
      const newProject = {
        ...project,
        images: project.images.map((image) => (
          image._id === action.image._id ? action.image : image
        )),
      };
      const client = state.clients.find((c) => c._id === project.client);
      const newClient = {
        ...client,
        projects: state.client.projects.map((p) => (p === project ? newProject : p)),
      };
      const newClients = state.clients.map((c) => (c === client ? newClient : c));
      let newUploadingInfo = null;
      if (state.uploadingInfo) {
        newUploadingInfo = {
          ...state.uploadingInfo,
          files: state.uploadingInfo.files.map((file, index) => {
            if (index === state.uploadingInfo.index) {
              return {
                name: file.name,
                preview: file.preview,
                type: file.type,
                image: action.image,
              };
            }
            return file;
          }),
        };
      }

      return {
        ...state,
        uploadingInfo: newUploadingInfo,
        activeProject: newProject,
        client: newClient,
        clients: newClients,
      };
    }

    case ACTION.REMOVE_MEDIA: {
      let projectId = action.image.project;
      if (typeof projectId === 'object')
        projectId = projectId._id;
      const project = state.client.projects.find((p) => p._id === projectId);
      const newProject = {
        ...project,
        images: project.images.filter((image) => image._id !== action.image._id),
      };
      const client = state.clients.find((c) => c._id === project.client);
      const newClient = {
        ...client,
        projects: state.client.projects.map((p) => (p === project ? newProject : p)),
      };
      const newClients = state.clients.map((c) => (c === client ? newClient : c));

      return {
        ...state,
        activeProject: newProject,
        client: newClient,
        clients: newClients,
        filteredImages: state.filteredImages.filter((img) => img._id !== action.image._id),
        selectedImages: state.selectedImages.filter((img) => img._id !== action.image._id),
      };
    }

    case ACTION.REMOVE_MEDIAS: {
      const mediaIds = action.medias.map((m) => m._id);
      const newProject = {
        ...state.activeProject,
        images: state.activeProject.images.filter((image) => !mediaIds.includes(image._id)),
      };
      const newClient = {
        ...state.client,
        projects: state.client.projects.map((p) => (p === state.activeProject ? newProject : p)),
      };
      const newClients = state.clients.map((c) => (c === state.client ? newClient : c));

      return {
        ...state,
        activeProject: newProject,
        client: newClient,
        clients: newClients,
        filteredImages: state.filteredImages.filter((img) => !mediaIds.includes(img._id)),
        selectedImages: state.selectedImages.filter((img) => !mediaIds.includes(img._id)),
      };
    }

    case ACTION.REORDER_MEDIAS: {
      const images = state.activeProject.images.map((image) => {
        const order = action.orders.find((o) => o._id === image._id);
        if (!order) return image;
        return {
          ...image,
          order: order.order,
        };
      }).sort((a, b) => (a.order - b.order));
      const newProject = {
        ...state.activeProject,
        images,
      };
      const newClient = {
        ...state.client,
        projects: state.client.projects.map((p) => (p === state.activeProject ? newProject : p)),
      };
      const newClients = state.clients.map((c) => (c === state.client ? newClient : c));

      return {
        ...state,
        activeProject: newProject,
        client: newClient,
        clients: newClients,
      };
    }

    case ACTION.PREVIEW_IMAGE:
      return {
        ...state,
        previewImage: action.image,
      };

    case ACTION.SET_EDITING_PROJECT:
      return {
        ...state,
        editingProject: action.project,
      };

    case ACTION.SET_EDITING_IMAGE:
      return {
        ...state,
        editingImage: action.image,
      };

    case ACTION.TOGGLE_IMAGE_SELECT: {
      const id = state.selectedImages.findIndex((image) => image._id === action.image._id);
      return {
        ...state,
        selectedImages: (
          id === -1
            ? [...state.selectedImages, action.image]
            : [...state.selectedImages.slice(0, id), ...state.selectedImages.slice(id + 1, state.selectedImages.length)]
        ),
      };
    }

    case ACTION.SET_SELECTED_IMAGES:
      return {
        ...state,
        selectedImages: action.images,
      };

    case ACTION.SET_FILTERED_IMAGES:
      return {
        ...state,
        filteredImages: action.images,
      };

    case ACTION.SET_CONTACTS:
      return {
        ...state,
        contacts: action.contacts.sort((a, b) => a.primary.localeCompare(b.primary)),
      };

    case ACTION.ADD_CONTACT:
      return {
        ...state,
        contacts: [
          ...state.contacts,
          action.contact,
        ].sort((a, b) => a.primary.localeCompare(b.primary)),
      };

    case ACTION.EDIT_CONTACT:
      return {
        ...state,
        contacts: state.contacts.map((contact) => (contact._id === action.contact._id
          ? {
            ...contact,
            primary: action.contact.primary,
            email: action.contact.email,
            phone: action.contact.phone,
            website: action.contact.website,
            linkedIn: action.contact.linkedIn,
            reference: action.contact.reference,
            image: action.contact.image,
            preview: action.contact.preview,
          }
          : contact)).sort((a, b) => a.primary.localeCompare(b.primary)),
      };

    case ACTION.SET_EDITING_CONTACT: {
      return {
        ...state,
        editingContact: action.contact,
      };
    }

    case ACTION.REMOVE_CONTACT:
      return {
        ...state,
        contacts: state.contacts.filter((contact) => contact._id !== action.contactId),
      };

    case ACTION.SET_CASE_STUDY: {
      const newProject = {
        ...state.activeProject,
        caseStudy: action.caseStudyId,
      };
      const newClient = {
        ...state.client,
        projects: state.client.projects.map((p) => (p === state.activeProject ? newProject : p)),
      };
      const newClients = state.clients.map((c) => (c === state.client ? newClient : c));

      return {
        ...state,
        activeProject: newProject,
        client: newClient,
        clients: newClients,
      };
    }

    case ACTION.ADD_LIVE_LINK: {
      const newProject = {
        ...state.activeProject,
        liveLinks: [...state.activeProject.liveLinks, action.liveLink],
      };
      const newClient = {
        ...state.client,
        projects: state.client.projects.map((p) => (p === state.activeProject ? newProject : p)),
      };
      const newClients = state.clients.map((c) => (c === state.client ? newClient : c));

      return {
        ...state,
        activeProject: newProject,
        client: newClient,
        clients: newClients,
      };
    }

    case ACTION.EDIT_LIVE_LINK: {
      const newProject = {
        ...state.activeProject,
        liveLinks: state.activeProject.liveLinks.map((link) => (
          link._id === action.liveLink._id ? action.liveLink : link
        )),
      };
      const newClient = {
        ...state.client,
        projects: state.client.projects.map((p) => (p === state.activeProject ? newProject : p)),
      };
      const newClients = state.clients.map((c) => (c === state.client ? newClient : c));

      return {
        ...state,
        activeProject: newProject,
        client: newClient,
        clients: newClients,
      };
    }

    case ACTION.REMOVE_LIVE_LINK: {
      const newProject = {
        ...state.activeProject,
        liveLinks: state.activeProject.liveLinks.filter((link) => link._id !== action.liveLink._id),
      };
      const newClient = {
        ...state.client,
        projects: state.client.projects.map((p) => (p === state.activeProject ? newProject : p)),
      };
      const newClients = state.clients.map((c) => (c === state.client ? newClient : c));

      return {
        ...state,
        activeProject: newProject,
        client: newClient,
        clients: newClients,
      };
    }

    case ACTION.SET_GLOBAL_SEARCH:
      return {
        ...state,
        isGlobalSearch: action.isGlobal,
      };

    case ACTION.MASS_EDIT_MEDIAS: {
      const newProject = {
        ...state.activeProject,
        images: state.activeProject.images.map((image) => {
          if (action.imageIds.indexOf(image._id) === -1) {
            return image;
          }
          return {
            ...image,
            ...action.changes,
          };
        }),
      };
      const newClient = {
        ...state.client,
        projects: state.client.projects.map((p) => (p === state.activeProject ? newProject : p)),
      };
      const newClients = state.clients.map((c) => (c === state.client ? newClient : c));

      return {
        ...state,
        activeProject: newProject,
        client: newClient,
        clients: newClients,
        previewImage: null,
      };
    }

    case ACTION.MASS_MOVE_IMAGES: {
      const { targetClient, targetProject } = action;
      const newTargetProject = {
        ...targetProject,
        images: [...targetProject.images, ...action.images],
      };
      const imageIds = action.images.map((image) => image._id);
      const newSourceProject = {
        ...state.activeProject,
        images: state.activeProject.images.filter((image) => !imageIds.includes(image._id)),
      };
      if (targetClient === state.client) {
        const newClient = {
          ...state.client,
          projects: state.client.projects.map((p) => {
            if (p === state.activeProject)
              return newSourceProject;
            if (p === targetProject)
              return newTargetProject;
            return p;
          }),
        };
        const newClients = state.clients.map((c) => (c === state.client ? newClient : c));
        return {
          ...state,
          activeProject: newSourceProject,
          client: newClient,
          clients: newClients,
          previewImage: null,
        };
      }

      const newTargetClient = {
        ...targetClient,
        projects: targetClient.projects.map((p) => (p === targetProject ? newTargetProject : p)),
      };
      const newSourceClient = {
        ...state.client,
        projects: state.client.projects.map((p) => (p === state.activeProject ? newSourceProject : p)),
      };
      const newClients = state.clients.map((c) => {
        if (c === targetClient)
          return newTargetClient;
        if (c === state.client)
          return newSourceClient;
        return c;
      });
      return {
        ...state,
        activeProject: newSourceProject,
        client: newSourceClient,
        clients: newClients,
        previewImage: null,
      };
    }

    case ACTION.SHOW_EDIT_PANEL:
      return {
        ...state,
        editPanelVisible: action.show,
      };

    case ACTION.SET_HAS_UNSAVED_DATA:
      return {
        ...state,
        hasUnsavedData: action.hasData,
      };

    case ACTION.SET_CAPTURED_IMAGES:
      return {
        ...state,
        capturedImages: action.images,
      };

    case ACTION.SET_WEEKLY_REPORTED_PROJECTS:
      return {
        ...state,
        weeklyReportedProjects: action.projects,
      };

    case ACTION.ADD_WEEKLY_REPORTED_PROJECT:
      return {
        ...state,
        weeklyReportedProjects: [...state.weeklyReportedProjects, action.project],
      };

    case ACTION.REMOVE_WEEKLY_REPORTED_PROJECT:
      return {
        ...state,
        weeklyReportedProjects: state.weeklyReportedProjects.filter((p) => p !== action.project),
      };

    case ACTION.SET_PROJECT_FILTER:
      return {
        ...state,
        projectFilter: action.filter,
      };

    default:
      return state;
  }

  return state;
}
