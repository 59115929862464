import moment from 'moment';
import mobileDevices from '../constants/templates';
import config from '../constants/config';

export function getCroppedImage(image, deviceName, crop, mimeType = 'image/png') {
  if (!image.naturalWidth || !image.naturalHeight)
    return null;

  const width = (image.naturalWidth * crop.width) / 100;
  const height = (image.naturalHeight * crop.height) / 100;
  const offsetX = (image.naturalWidth * crop.x) / 100;
  const offsetY = (image.naturalHeight * crop.y) / 100;

  const device = mobileDevices.find((d) => d.name === deviceName);
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  if (device) {
    const screenWidth = Math.min(width, device.screenWidth);
    const screenHeight = Math.min(height, device.screenHeight);
    const totalWidth = (screenWidth / device.screenWidth) * device.width;
    const totalHeight = (screenHeight / device.screenHeight) * device.height;
    const screenX = (screenWidth / device.screenWidth) * device.offsetX;
    const screenY = (screenHeight / device.screenHeight) * device.offsetY;

    canvas.width = totalWidth;
    canvas.height = totalHeight;

    context.fillStyle = 'white';
    context.fillRect(screenX, screenY, screenWidth, screenHeight);
    context.drawImage(image, offsetX, offsetY, width, height, screenX, screenY, screenWidth, screenHeight);

    if (device.borderRadius) {
      const radius = (screenWidth / device.screenWidth) * device.borderRadius;
      context.clearRect(screenX - 5, screenY - 5, radius + 5, radius + 5);
      context.clearRect(screenX + screenWidth - radius, screenY - 5, radius + 5, radius + 5);
      context.clearRect(screenX - 5, screenY + screenHeight - radius, radius + 5, radius + 5);
      context.clearRect(screenX + screenWidth - radius, screenY + screenHeight - radius, radius + 5, radius + 5);
    }

    context.drawImage(device.image, 0, 0, totalWidth, totalHeight);

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(blob);
      });
    });
  }

  canvas.width = width;
  canvas.height = height;
  context.drawImage(image, offsetX, offsetY, width, height, 0, 0, width, height);

  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(blob);
    }, mimeType);
  });
}

export function getCropFromDevice(image, deviceName) {
  const device = mobileDevices.find((d) => d.name === deviceName);
  if (device) {
    const aspect = device.screenWidth / device.screenHeight;
    let { width } = image;
    let height = width / aspect;
    if (height > image.height) {
      height = image.height;
      width = height * aspect;
    }
    width = (width / image.width) * 100;
    height = (height / image.height) * 100;
    return {
      unit: '%',
      aspect,
      width,
      height,
      x: (100 - width) / 2,
      y: (100 - height) / 2,
    };
  }

  return {
    unit: '%',
    x: 0,
    y: 0,
    width: 100,
    height: 100,
  };
}

export function getHtmlTextLength(description) {
  if (!description)
    return 0;
  return description.replace(/<[^<]*>/g, '').replace(/&\w+;/g, ' ').length;
}

export function getImagesFromClipboard(e) {
  const images = [];
  for (const item of e.clipboardData.items) {
    if (item.type.indexOf('image') !== -1) {
      const file = item.getAsFile();
      file.preview = URL.createObjectURL(file);
      images.push(file);
    }
  }
  return images;
}

export function getCaseStudyHTML(type, html, css = '', projectName = '', hasPreview = true) {
  const previewCode = `
    <div class="preview-modal">
      <div class="preview-wrapper">
          <img onload="onImageLoad()">
      </div>
      <div class="action-buttons w-100 d-flex-center">
          <span class="btn-zoom-out action-button" data-toggle="tooltip" title="Zoom Out" onclick="onZoom(2/3)">-</span>
          <span class="btn-zoom-in action-button" data-toggle="tooltip" title="Zoom In" onclick="onZoom(1.5)">+</span>
      </div>
      <span class="action-button prev-button" data-toggle="tooltip" title="Previous" onclick="onNextImage(-1)">
          <span class="prev-icon"></span>
      </span>
      <span class="action-button next-button" data-toggle="tooltip" title="Next" onclick="onNextImage(1)">
          <span class="next-icon"></span>
      </span>
      <span class="action-button close-button" data-toggle="tooltip" title="Close" onclick="onCloseModal()">×</span>
  </div>
    <style>
        img {
            cursor: zoom-in;
        }
        .w-100 {
            width: 100% !important;
        }
        .d-flex-center {
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
        }
    
        .preview-modal {
            position: fixed;
            width: 100vw;
            height: 100vh;
            background: #0008;
            z-index: 1050;
            top: 0;
            left: 0;
            display: none;
        }
        .preview-modal .preview-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100vw;
            height: 100vh;
            overflow: auto;
        }
        .preview-modal img, .preview-modal video {
            filter: drop-shadow(2px 4px 6px black);
            cursor: initial !important;
        }
        .preview-modal .action-buttons {
            position: absolute;
            bottom: 10px;
        }
        .preview-modal .action-button:hover {
            background: black;
        }
        .preview-modal .action-button {
            color: white;
            font-size: 30px;
            font-weight: bold;
            background: #0008;
            border-radius: 50%;
            margin: 0 5px;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
        .preview-modal .close-button {
            position: absolute;
            right: 20px;
            top: 10px;
        }
        .preview-modal .prev-button {
            position: absolute;
            left: 10px;
            top: calc(50% - 20px);
        }
        .preview-modal .prev-button .prev-icon {
            width: 60%;
            height: 60%;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
        }
        .preview-modal .next-button {
            position: absolute;
            right: 20px;
            top: calc(50% - 20px);
        }
        .preview-modal .next-button .next-icon {
            width: 60%;
            height: 60%;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
        }
    </style>
    <script type="text/javascript">
    let images = [];
    let activeImage = null;

    window.addEventListener('click', (e) => {
        const el = e.target;
        if (el.tagName !== 'IMG' || !el.src)
            return;
        if (!el.parentNode)
            images = [el.src];
        else {
            images = [];
            el.parentNode.childNodes.forEach((child) => {
                if (child.tagName === 'IMG' && child.src)
                    images.push(child.src);
            });
        }
        activeImage = el.src;
        initPreviewModal();
    });

    function initPreviewModal() {
        const modal = document.querySelector('.preview-modal');
        if (!activeImage) {
          modal.style.display = 'none';
          return;
        }

        const previewImg = modal.querySelector('.preview-wrapper img');
        previewImg.src = activeImage;
        previewImg.alt = activeImage;
        modal.style.display = 'block';

        if (images.length > 1) {
          modal.querySelector('.prev-button').style.display = 'flex';
          modal.querySelector('.next-button').style.display = 'flex';
        } else {
          modal.querySelector('.prev-button').style.display = 'none';
          modal.querySelector('.next-button').style.display = 'none';
        }
    }

    function onCloseModal() {
      activeImage = null;
      initPreviewModal();
    }

    function onImageLoad() {
      const imageElement = document.querySelector('.preview-modal .preview-wrapper img');
      if (imageElement) {
        let width = imageElement.naturalWidth;
        let height = imageElement.naturalHeight;
        const maxWidth = window.innerWidth - 100;
        const maxHeight = window.innerHeight - 100;
        if (width > maxWidth) {
          height = (height / width) * maxWidth;
          width = maxWidth;
        }
        if (height > maxHeight) {
          width = (width / height) * maxHeight;
          height = maxHeight;
        }
        imageElement.width = width;
        imageElement.parentNode.style.justifyContent = 'center';
        imageElement.parentNode.style.alignItems = 'center';
      }
    }

    function onZoom(ratio) {
      const imageElement = document.querySelector('.preview-modal .preview-wrapper img');
      if (imageElement) {
        if (ratio < 1 && (imageElement.width < 150 || imageElement.height < 150)) {
          return;
        }

        imageElement.width *= ratio;
        if (imageElement.width > window.innerWidth) {
          imageElement.parentNode.style.justifyContent = 'flex-start';
        } else {
          imageElement.parentNode.style.justifyContent = 'center';
        }
        if (imageElement.height > window.innerHeight) {
          imageElement.parentNode.style.alignItems = 'flex-start';
        } else {
          imageElement.parentNode.style.alignItems = 'center';
        }
      }
    }

    function onNextImage(offset) {
      if (images.length < 2)
        return;
      const id = (images.indexOf(activeImage) + offset + images.length) % images.length;
      activeImage = images[id];
      initPreviewModal();
    }
</script>
  `;

  if (type === 'froala') {
    return `<!DOCTYPE html>
        <html lang="en">
        <head>
        <title>Case Study for ${projectName}</title>
        <link rel="stylesheet" type="text/css" href="${config.ASSETS_URL}/css/bootstrap.min.css">
        <link rel="stylesheet" type="text/css" href="${config.ASSETS_URL}/css/froala_style.min.css">
        <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.4.0/css/font-awesome.min.css" rel="stylesheet">
        </head>
        <body class="fr-view">
          ${html}
          ${hasPreview ? previewCode : ''}
        </body>
        </html>
      `;
  }

  return `${html}<style>${css}</style>${hasPreview ? previewCode : ''}`;
}

export function validateURL(url) {
  // try {
  //   if (!/^\w+:\/\//.test(url)) {
  //     url = `http://${url}`;
  //   }
  //   url = new URL(url);
  //   return true;
  // } catch (e) {
  //   return false;
  // }

  // return /^(https?:\/\/)?(www\.)?(\w+\.\w+)$/gi.test(url);

  let domain = url;
  let match = /^https?:\/\/(.*)$/gi.exec(domain);
  if (match) {
    domain = match[1] || '';
  }
  match = /^www\.(.*)$/gi.exec(domain);
  if (match) {
    domain = match[1] || '';
  }
  return /^\w+(\.\w+)+$/gi.test(domain);
}

export function dataURLtoBlob(dataUrl) {
  const arr = dataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

export function compareObject(a, b) {
  if (typeof a !== typeof b)
    return false;

  if (typeof a !== 'object')
    return a === b;

  const keys = [
    ...Object.keys(a),
    ...Object.keys(b),
  ];
  for (const key of keys) {
    if (!compareObject(a[key], b[key]))
      return false;
  }

  return true;
}

export function getFilteredImages(project, section, subSection, tags) {
  if (!project || !project.images) {
    return [];
  }

  return project.images.filter((image) => {
    if (section && image.section !== section)
      return false;
    if (subSection && image.subSection !== subSection)
      return false;
    return (!tags.length || tags.some((tag) => image.tags.some((t) => t.name === tag)));
  });
}

export async function convertSvgToPng(svgUrl) {
  const image = new Image();
  await new Promise((resolve) => {
    image.src = svgUrl;
    image.onload = () => {
      image.onload = null;
      resolve();
    };
  });

  const { width, height } = image;

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  canvas.width = width;
  canvas.height = height;
  context.drawImage(image, 0, 0, width, height, 0, 0, image.naturalWidth, image.naturalHeight);

  image.remove();

  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      try {
        blob.preview = URL.createObjectURL(blob);
        resolve(blob);
      } catch (e) {
        resolve(null);
      }
    });
  });
}

export async function convertSvgFiles(files) {
  const filteredFiles = [];
  for (const file of files) {
    if (file.type === 'image/svg+xml') {
      const pngFile = await convertSvgToPng(file.preview) || file;
      filteredFiles.push(pngFile);
    } else {
      filteredFiles.push(file);
    }
  }
  return filteredFiles;
}

export function parseQuery(queryString) {
  const queries = {};
  const tokens = queryString.split(/[?&]/g);
  for (let i = 1; i < tokens.length; i++) {
    const values = tokens[i].split('=');
    queries[values[0]] = values[1];
  }

  return queries;
}

export function prePopulateVersion(project, section, subSection) {
  if (!project)
    return null;

  const sectionIds = {};
  const subSectionIds = {};
  let maxMediaId = 0;
  project.images.forEach((media) => {
    if (!sectionIds[media.section]) {
      const sectionId = media.version.split('.')[1];
      if (sectionId)
        sectionIds[media.section] = sectionId;
    }

    if (media.section === section && media.subSection && !subSectionIds[media.subSection]) {
      const subSectionId = media.version.split('.')[2];
      if (subSectionId)
        subSectionIds[media.subSection] = subSectionId;
    }

    if (media.section === section && (!subSection || media.subSection === subSection))
      maxMediaId = Math.max(maxMediaId, media.version.split('.').reverse()[0]);
  });

  const projectId = project.versionId;
  const sectionId = sectionIds[section] || (Math.max(0, ...Object.values(sectionIds)) + 1);
  const subSectionId = subSectionIds[subSection] || (Math.max(0, ...Object.values(subSectionIds)) + 1);
  const mediaId = maxMediaId + 1;
  return `${projectId}.${sectionId}.${subSection ? `${subSectionId}.` : ''}${mediaId}`;
}

export function convertToRichText(text) {
  if (!text)
    return '';

  const lines = text
    .replace(/(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b[-a-zA-Z0-9()@:%_+.~#?&/=]*)/gm, '<a href="$1" target="_blank">$1</a>')
    .split(/\n/mg);

  if (lines.length === 1)
    return lines[0];

  return `<ul>
    ${lines.map((l) => `<li>${l}</li>`).join('')}
  </ul>`;
}

export const getCurrentWeek = (date = null) => {
  const today = date ? new Date(date) : new Date();
  const startDate = today.getDate() - today.getDay();
  const endDate = startDate + 6;

  const start = new Date(today.getFullYear(), today.getMonth(), startDate);
  const end = new Date(today.getFullYear(), today.getMonth(), endDate);

  const week = `${moment(start).format('MMMM, yyyy')} Week ${Math.ceil(start.getDate() / 7)}`;
  return `${week} (${moment(start).format('LL')} - ${moment(end).format('LL')})`;
};
