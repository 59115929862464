import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import 'froala-editor/js/plugins.pkgd.min';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/third_party/embedly.min';
import 'froala-editor/css/third_party/embedly.min.css';
import 'froala-editor/js/third_party/font_awesome.min';
import 'froala-editor/css/third_party/font_awesome.min.css';
import 'froala-editor/js/third_party/spell_checker.min';
import 'froala-editor/css/third_party/spell_checker.min.css';
import 'froala-editor/js/third_party/image_tui.min';
import 'froala-editor/css/third_party/image_tui.min.css';
import FroalaWysiwyg from 'react-froala-wysiwyg';

import './style.scss';
import config from '../../../../../constants/config';
import * as PortfolioService from '../../../../../services/portfolio.service';
import * as PortfolioAction from '../../../../../store/actions/portfolio.action';

let editor = null;

const FroalaEditor = ({
  caseStudy, project, onEditorInit, setHasUnsavedData,
}) => {
  const onManualControllerReady = (controller) => {
    controller.initialize();
    editor = controller.getEditor();
    onEditorInit(editor);
  };

  const onModelChange = () => {
    if (editor && editor.undo_index > 1) {
      setHasUnsavedData(true);
    }
  };

  const froalaEditorConfig = useMemo(() => ({
    key: 'dKA5cC2G2B2H2J2B4zPAENHMi1JPQRFZBTBa1WWEPYDbA2B6C4B3F4B2A2C3H2F1==',
    placeholderText: 'Edit Case Study Here!',
    imageUploadParam: 'image',
    imageUploadURL: `${config.API_SERVER}/image-manager`,
    imageUploadMethod: 'POST',
    imageUploadParams: { projectId: project._id, type: 'froala' },
    imageMaxSize: 50 * 1024 * 1024, // 50MB
    imageManagerLoadURL: `${config.API_SERVER}/image-manager`,
    imageManagerLoadMethod: 'GET',
    imageManagerLoadParams: { projectId: project._id, type: 'froala' },
    height: 'calc(100vh - 200px)',
    events: {
      'image.removed': (images) => {
        const filePath = images[0].dataset.name;
        PortfolioService.removeCaseStudyImage(filePath).then();
      },
    },
  }), [project]);

  if (!caseStudy) {
    return null;
  }

  return (
    <FroalaWysiwyg
      config={froalaEditorConfig}
      model={caseStudy.content}
      onManualControllerReady={onManualControllerReady}
      onModelChange={onModelChange}
    />
  );
};

FroalaEditor.propTypes = {
  caseStudy: PropTypes.object,
  project: PropTypes.object.isRequired,
  onEditorInit: PropTypes.func,
  setHasUnsavedData: PropTypes.func.isRequired,
};

FroalaEditor.defaultProps = {
  caseStudy: null,
  onEditorInit: null,
};

const mapDispatchToProps = (dispatch) => (
  bindActionCreators(
    {
      setHasUnsavedData: PortfolioAction.setHasUnsavedData,
    },
    dispatch,
  )
);

export default connect(null, mapDispatchToProps)(FroalaEditor);
