import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './style.scss';

import { Button } from '@material-ui/core';
import config from '../../../../constants/config';
import ImageChangeModal from '../../ImageChangeModal';
import * as PortfolioAction from '../../../../store/actions/portfolio.action';
import * as PortfolioService from '../../../../services/portfolio.service';
import * as ToastAction from '../../../../store/actions/toast.action';
import * as CommonAction from '../../../../store/actions/common.action';
import { ROLES } from '../../../../constants';

const BackgroundImageTab = ({
  user, activeProject, editProject, createToast, startLoading, finishLoading,
}) => {
  const [showChangeModal, setShowChangeModal] = useState(false);

  const backgroundImage = useMemo(() => (
    activeProject && activeProject.background
      ? activeProject.backPreview || `${config.PROJECT_URL}/${activeProject.background}`
      : null
  ), [activeProject]);

  const onChangeBackground = async (image) => {
    const isCreate = !activeProject.background;
    const imageName = `${activeProject}_back.png`;
    startLoading();
    const result = await PortfolioService.setProjectBackground(activeProject, image, imageName);
    finishLoading();
    if (result.error) {
      createToast({
        message: result.message || `${isCreate ? 'Creating' : 'Changing'} background image has been failed.`,
        type: 'error',
      });
    } else {
      editProject({
        ...activeProject,
        background: imageName,
        backPreview: image.preview,
        updatedAt: new Date().toUTCString(),
      });
      createToast({
        message: `Background image has been ${isCreate ? 'created' : 'changed'} successfully.`,
        type: 'success',
      });
    }
  };

  const onRemoveBackground = async () => {
    if (!window.confirm('Are you sure you want to remove background image?')) {
      return;
    }

    startLoading();
    const result = await PortfolioService.removeProjectBackground(activeProject);
    finishLoading();
    if (result.error) {
      createToast({
        message: result.message || 'Removing project background has been failed.',
        type: 'error',
      });
    } else {
      editProject({
        ...activeProject,
        background: null,
        backPreview: null,
        updatedAt: new Date().toUTCString(),
      });
      createToast({
        message: 'Background image has been removed successfully.',
        type: 'success',
      });
    }
  };

  if (!activeProject) {
    return null;
  }

  return (
    <>
      <div className="text-center">
        {
          backgroundImage
            ? (
              <>
                {user.role !== ROLES.CLIENT ? (
                  <>
                    <div className="tab-header d-flex align-items-center justify-content-center">
                      <Button
                        className="btn-warning size-sm px-3"
                        onClick={() => setShowChangeModal(true)}
                      >
                        Change Background
                      </Button>
                      <Button
                        className="btn-danger size-sm px-3 ml-3"
                        onClick={onRemoveBackground}
                      >
                        Remove Background
                      </Button>
                    </div>
                  </>
                ) : (
                  <h5 className="text-left">Background Image</h5>
                )}
                <img src={backgroundImage} className="mw-100 mh-100" alt="Background" />
              </>
            )
            : (
              <div className="my-5 py-4">
                {user.role !== ROLES.CLIENT && (
                  <Button className="btn-primary size-sm px-4 mb-4" onClick={() => setShowChangeModal(true)}>
                    Add Background Image
                  </Button>
                )}
                <p className="no-data m-0 p-0">{'Background image for this project hasn\'t been uploaded.'}</p>
              </div>
            )
        }
      </div>

      {showChangeModal && (
        <ImageChangeModal
          open={showChangeModal}
          title={backgroundImage ? 'Change Background Image' : 'Add Background Image'}
          image={backgroundImage ? { type: 'image/png', preview: backgroundImage } : null}
          accept={['image/*']}
          placeholder="Drag and drop image here or click"
          onClose={() => setShowChangeModal(false)}
          onChange={onChangeBackground}
        />
      )}
    </>
  );
};

BackgroundImageTab.propTypes = {
  user: PropTypes.object.isRequired,
  activeProject: PropTypes.object,
  editProject: PropTypes.func.isRequired,
  createToast: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
};

BackgroundImageTab.defaultProps = {
  activeProject: null,
};

const mapStateToProps = (store) => ({
  activeProject: store.portfolioReducer.activeProject,
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators(
    {
      editProject: PortfolioAction.editProject,
      createToast: ToastAction.createToast,
      startLoading: CommonAction.startLoading,
      finishLoading: CommonAction.finishLoading,
    },
    dispatch,
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(BackgroundImageTab);
