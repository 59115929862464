import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './style.scss';
import Reorder from 'react-reorder';

import { DeleteOutline } from '@material-ui/icons';
import { IconButton, Tooltip } from '@material-ui/core';
import SearchBox from './SearchBox';
import GlobalSearchResult from './GlobalSearchResult';
import ProjectImage from './ProjectImage';
import MassEditModal from '../../MassEditModal';
import MassMoveModal from '../../MassMoveModal';
import UploadDetailModal from '../../UploadDetailModal';
import * as PortfolioAction from '../../../../store/actions/portfolio.action';
import * as PortfolioService from '../../../../services/portfolio.service';
import * as ToastAction from '../../../../store/actions/toast.action';
import * as CommonAction from '../../../../store/actions/common.action';
import { ROLES } from '../../../../constants';

const ScreenshotsTab = ({
  user, activeProject, uploadingInfo, selectedImages, filteredImages, isGlobalSearch,
  reorderMedias, removeMedias, createToast, startLoading, finishLoading,
}) => {
  const [showMassEditModal, setShowMassEditModal] = useState(false);
  const [showMassMoveModal, setShowMassMoveModal] = useState(false);

  const localSelectedImages = useMemo(() => {
    if (!activeProject) {
      return [];
    }
    const imageIds = activeProject.images.map((img) => img._id);
    return selectedImages.filter((image) => imageIds.indexOf(image._id) !== -1);
  }, [activeProject, selectedImages]);

  const onScroll = useCallback(() => {
    const container = document.querySelector('.images-panel .scrollable-container');
    const searchBox = document.querySelector('.images-panel .search-box');
    const btnGroup = document.querySelector('.images-panel .button-group');
    if (!container || !searchBox || !btnGroup) {
      return;
    }

    if (container.scrollTop > searchBox.clientHeight + 20) {
      btnGroup.style.top = '10px';
    } else {
      btnGroup.style.top = `${searchBox.clientHeight + 30 - container.scrollTop}px`;
    }
  }, []);

  useEffect(() => {
    onScroll();
  });

  const onReorder = (event, previousIndex, nextIndex) => {
    const orders = [];
    const images = filteredImages;
    if (previousIndex < nextIndex) {
      const startOrder = images[nextIndex].order;
      for (let i = nextIndex; i > previousIndex; i--) {
        images[i].order = images[i - 1].order;
        orders.push({
          _id: images[i]._id,
          order: images[i].order,
          subSection: images[i].subSection,
        });
      }
      images[previousIndex].order = startOrder;
      orders.push({
        _id: images[previousIndex]._id,
        order: startOrder,
      });
    } else {
      const startOrder = images[nextIndex].order;
      for (let i = nextIndex; i < previousIndex; i++) {
        images[i].order = images[i + 1].order;
        orders.push({
          _id: images[i]._id,
          order: images[i].order,
        });
      }
      images[previousIndex].order = startOrder;
      orders.push({
        _id: images[previousIndex]._id,
        order: startOrder,
      });
    }
    PortfolioService.setImagesOrder(orders).then();
    reorderMedias(orders);
  };

  const onDeleteMedias = () => {
    if (!window.confirm(`Do you really want to delete ${localSelectedImages.length} file(s)?`)) {
      return;
    }

    const removedMedias = [];
    startLoading();
    Promise.all(localSelectedImages.map((media) => (
      PortfolioService.removeMedia(media._id).then(() => {
        removedMedias.push(media);
      })
    ))).then(() => {
      finishLoading();
      removeMedias(removedMedias);
      createToast({
        type: 'success',
        message: `You have been deleted ${localSelectedImages.length} file(s)`,
      });
    }).catch(() => {
      createToast({
        type: 'error',
        message: 'Deleting files has been failed',
      });
    });
  };

  const imagesContent = (
    isGlobalSearch
      ? (
        <GlobalSearchResult images={filteredImages} />
      )
      : (
        <Reorder
          className="d-flex flex-wrap justify-content-around"
          reorderId="screenshots"
          holdTime={200}
          onReorder={onReorder}
        >
          {
            filteredImages.map((image) => (
              <div className="project-media-wrapper" key={image._id}>
                <ProjectImage image={{ ...image, project: activeProject }} />
              </div>
            ))
          }
        </Reorder>
      )
  );

  return (
    <>
      <SearchBox project={activeProject} />
      <div className="mt-4">
        <div className="d-flex align-items-center justify-content-between">
          <h6 className="text-uppercase">Uploaded Screenshots</h6>
          {user.role !== ROLES.CLIENT && (
            <div>
              <Tooltip title="Move Media" placement="top" arrow>
                <IconButton
                  className="btn-outline-secondary size-sm ml-2"
                  disabled={isGlobalSearch || !localSelectedImages.length}
                  onClick={() => setShowMassMoveModal(true)}
                >
                  <img src="/resources/images/svgs/move.svg" alt="" width={14} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Mass Edit Media" placement="top" arrow>
                <IconButton
                  className="btn-outline-secondary size-sm ml-2"
                  disabled={isGlobalSearch || !localSelectedImages.length}
                  onClick={() => setShowMassEditModal(true)}
                >
                  <img src="/resources/images/svgs/edit-square.svg" alt="" width={14} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete Media" placement="top" arrow>
                <IconButton
                  className="btn-outline-secondary size-sm ml-2"
                  disabled={isGlobalSearch || !localSelectedImages.length}
                  onClick={onDeleteMedias}
                >
                  <DeleteOutline />
                </IconButton>
              </Tooltip>
            </div>
          )}
        </div>
        <div className="mt-4 mb-0">
          {
            filteredImages.length
              ? imagesContent
              : <p className="no-data">No screenshots are available.</p>
          }
        </div>
      </div>
      {Boolean(uploadingInfo) && (
        <UploadDetailModal open />
      )}
      {showMassMoveModal && (
        <MassMoveModal open onClose={() => setShowMassMoveModal(false)} images={localSelectedImages} />
      )}
      {showMassEditModal && (
        <MassEditModal open onClose={() => setShowMassEditModal(false)} images={localSelectedImages} />
      )}
    </>
  );
};

ScreenshotsTab.propTypes = {
  user: PropTypes.object.isRequired,
  activeProject: PropTypes.object,
  uploadingInfo: PropTypes.object,
  selectedImages: PropTypes.array,
  filteredImages: PropTypes.array,
  isGlobalSearch: PropTypes.bool,
  reorderMedias: PropTypes.func.isRequired,
  removeMedias: PropTypes.func.isRequired,
  createToast: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
};

ScreenshotsTab.defaultProps = {
  activeProject: null,
  uploadingInfo: null,
  selectedImages: [],
  filteredImages: [],
  isGlobalSearch: false,
};

const mapStateToProps = (store) => ({
  tags: store.portfolioReducer.tags,
  activeProject: store.portfolioReducer.activeProject,
  uploadingInfo: store.portfolioReducer.uploadingInfo,
  selectedImages: store.portfolioReducer.selectedImages,
  filteredImages: store.portfolioReducer.filteredImages,
  isGlobalSearch: store.portfolioReducer.isGlobalSearch,
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators(
    {
      setFilteredImages: PortfolioAction.setFilteredImages,
      reorderMedias: PortfolioAction.reorderMedias,
      removeMedias: PortfolioAction.removeMedias,
      createToast: ToastAction.createToast,
      startLoading: CommonAction.startLoading,
      finishLoading: CommonAction.finishLoading,
    },
    dispatch,
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(ScreenshotsTab);
