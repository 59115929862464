import React, { useEffect, useMemo, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './style.scss';

import Dropzone from 'react-dropzone';
import { Button, Grid, IconButton, Tooltip } from '@material-ui/core';
import * as PortfolioAction from '../../../store/actions/portfolio.action';
import * as PortfolioService from '../../../services/portfolio.service';
import * as ToastAction from '../../../store/actions/toast.action';
import * as CommonAction from '../../../store/actions/common.action';
import config from '../../../constants/config';
import ContactItem from '../ContactItem';
import ContactsModal from '../ContactsModal';
import { getImagesFromClipboard } from '../../../utils';
import Validators from '../../common/FormControl/validators';
import { patchFormData, validateFormControl, validateFormGroup } from '../../common/FormControl/utils';
import Dialog from '../../common/Dialog';
import FormControl from '../../common/FormControl';
import { PersonAdd } from '@material-ui/icons';

const EditProjectModal = ({
  open, clients, tags, contacts, editingProject,
  setTags, setEditingProject, addProject, editProject, createToast, startLoading, finishLoading,
}) => {
  const form = useMemo(() => ({
    client: {
      name: 'client',
      type: 'autocomplete',
      label: 'Client *',
      placeholder: 'Select client',
      options: [],
      validators: [Validators.required()],
    },
    name: {
      name: 'name',
      label: 'Project Name *',
      placeholder: 'Project name',
      validators: [Validators.required()],
    },
    year: {
      name: 'name',
      type: 'number',
      label: 'Project Year *',
      placeholder: 'Project year',
    },
    tags: {
      name: 'tags',
      type: 'autocomplete',
      label: 'Project Type *',
      placeholder: 'Add tags',
      value: [],
      options: [],
      controlOptions: {
        multiple: true,
        freeSolo: true,
      },
      validators: [Validators.required()],
    },
    clientWebsite: {
      name: 'clientWebsite',
      label: 'Client\'s Website',
      placeholder: 'Client\'s website',
      validators: [Validators.url()],
    },
    applyAsCompanyWebsite: {
      name: 'applyAsCompanyWebsite',
      label: 'Apply as company website',
      type: 'checkbox',
    },
    clientPhone: {
      name: 'clientPhone',
      label: 'Client\'s Phone',
      placeholder: 'Client\'s phone',
      validators: [],
    },
    applyAsCompanyPhone: {
      name: 'applyAsCompanyPhone',
      label: 'Apply as company phone',
      type: 'checkbox',
    },
    introduction: {
      name: 'introduction',
      type: 'summer-note',
      label: 'Project Introduction',
      maxLength: 800,
      validators: [Validators.maxLength(800, true)],
    },
  }), []);

  const [currentProject, setCurrentProject] = useState({
    client: '',
    name: '',
    year: '',
    tags: [],
    clientWebsite: '',
    applyAsCompanyWebsite: false,
    clientPhone: '',
    applyAsCompanyPhone: false,
    logo: null,
    applyAsCompanyLogo: false,
    background: null,
    contacts: [],
  });
  const [introduction, setIntroduction] = useState('');
  const [showContacts, setShowContacts] = useState(false);

  const clientNames = useMemo(() => clients.map((c) => c.name).sort(), [clients]);
  const tagNames = useMemo(() => tags.sort((tag1, tag2) => tag2.cnt - tag1.cnt).map((tag) => tag.name), [tags]);
  const selectedContacts = useMemo(() => (
    contacts.filter((contact) => currentProject.contacts.includes(contact._id))
  ), [contacts, currentProject.contacts]);

  useEffect(() => {
    Object.assign(form.client, { options: clientNames });
  }, [clientNames, form.client]);

  useEffect(() => {
    Object.assign(form.tags, { options: tagNames });
  }, [form.tags, tagNames]);

  useEffect(() => {
    const nameValidator = ({ value }) => {
      if (value.toLowerCase() === editingProject.name.toLowerCase())
        return null;

      const client = clients.find((c) => c.name === currentProject.client);
      if (!client)
        return null;

      const project = client.projects.find((p) => p.name.toLowerCase() === value.toLowerCase());
      if (project)
        return `Project name already exists - ${project.name}`;
      return null;
    };

    form.name.validators = [
      Validators.required(),
      nameValidator,
    ];

    if (form.name.value)
      validateFormControl(form.name);
  }, [clients, currentProject.client, editingProject.name, form.name]);

  const onIntroductionChange = (_, value) => {
    setIntroduction(value);
  };

  useEffect(() => {
    const client = clients.find((c) => c._id === editingProject.client);
    setCurrentProject({
      client: client ? client.name : '',
      name: editingProject.name || '',
      year: editingProject._id ? (editingProject.year || '') : new Date().getFullYear().toString(),
      clientWebsite: editingProject.clientWebsite || '',
      applyAsCompanyWebsite: !!editingProject.applyAsCompanyWebsite,
      clientPhone: editingProject.clientPhone || '',
      applyAsCompanyPhone: !!editingProject.applyAsCompanyPhone,
      applyAsCompanyLogo: !!editingProject.applyAsCompanyLogo,
      logo: (editingProject.logo
        ? ({
          name: 'Logo',
          preview: editingProject.preview || `${config.PROJECT_URL}/${editingProject.logo}`,
        })
        : null),
      background: (editingProject.background
        ? ({
          name: 'Background',
          preview: editingProject.backPreview || `${config.PROJECT_URL}/${editingProject.background}`,
        })
        : null),
      tags: (editingProject.tags
        ? editingProject.tags.map((tag) => tag.name)
        : []),
      contacts: editingProject.contacts || [],
      introduction: editingProject.introduction,
    });
    setIntroduction(editingProject.introduction);
    setShowContacts(false);

    form.clientWebsite.validators = editingProject.applyAsCompanyWebsite
      ? [Validators.required(), Validators.url()]
      : [Validators.url()];
    form.clientPhone.validators = editingProject.applyAsCompanyPhone
      ? [Validators.required()]
      : [];
  }, [clients, editingProject]);

  useEffect(() => {
    patchFormData(form, currentProject);
  }, [currentProject, form]);

  const onInputChange = (field, value) => {
    if (field === 'applyAsCompanyWebsite') {
      form.clientWebsite.validators = value
        ? [Validators.required(), Validators.url()]
        : [Validators.url()];
      validateFormControl(form.clientWebsite);
    }
    if (field === 'applyAsCompanyPhone') {
      form.clientPhone.validators = value
        ? [Validators.required(), Validators.url()]
        : [Validators.url()];
      validateFormControl(form.clientPhone);
    }

    setCurrentProject({
      ...currentProject,
      [field]: value,
    });
  };

  const onDrop = (field, files) => {
    setCurrentProject({
      ...currentProject,
      [field]: files ? files[0] : null,
    });
  };

  const onPasteImage = (e) => {
    if (e.nativeEvent.path.find((el) => el.className === 'project-logo')) {
      const images = getImagesFromClipboard(e);
      if (images.length) {
        onDrop('logo', images);
      }
    }
    if (e.nativeEvent.path.find((el) => el.className === 'project-background')) {
      const images = getImagesFromClipboard(e);
      if (images.length) {
        onDrop('background', images);
      }
    }
  };

  const onClose = () => {
    setEditingProject(null);
  };

  const onCreate = async () => {
    if (!validateFormGroup(form)) {
      return;
    }

    const client = clients.find((c) => c.name === currentProject.client);

    startLoading();
    const result = await PortfolioService.createProject({
      clientId: client._id,
      name: currentProject.name,
      year: currentProject.year,
      tags: currentProject.tags,
      introduction,
      clientWebsite: currentProject.clientWebsite,
      applyAsCompanyWebsite: currentProject.applyAsCompanyWebsite,
      clientPhone: currentProject.clientPhone,
      applyAsCompanyPhone: currentProject.applyAsCompanyPhone,
      applyAsCompanyLogo: currentProject.applyAsCompanyLogo,
      contacts: currentProject.contacts,
    });
    finishLoading();

    if (result.error || !result.data.project) {
      createToast({
        message: result.message || 'Creating project has been failed',
        type: 'error',
      });
      return;
    }

    const { project, tags } = result.data;
    setTags(tags);

    if (currentProject.logo) {
      const logoName = currentProject.logo.name;
      const fileExt = logoName.slice(logoName.lastIndexOf('.'));
      project.logo = project._id + fileExt;
      startLoading();
      const res = await PortfolioService.setProjectLogo(project, currentProject.logo, project.logo);
      finishLoading();
      if (res.error) {
        project.logo = null;
        createToast({
          message: res.message || 'Setting project logo has been failed',
          type: 'error',
        });
      }
    }
    if (currentProject.background) {
      project.background = `${project._id}_back.png`;
      startLoading();
      const res = await PortfolioService.setProjectBackground(project, currentProject.background, project.background);
      finishLoading();
      if (res.error) {
        project.background = null;
        createToast({
          message: res.message || 'Setting project background has been failed',
          type: 'error',
        });
      }
    }

    addProject(project);
    createToast({
      message: 'New project has been created!',
      type: 'success',
    });
    onClose();
  };

  const onEdit = async () => {
    if (!validateFormGroup(form)) {
      return;
    }

    startLoading();
    const result = await PortfolioService.editProject({
      _id: editingProject._id,
      name: currentProject.name,
      year: currentProject.year,
      tags: currentProject.tags,
      introduction,
      clientWebsite: currentProject.clientWebsite,
      applyAsCompanyWebsite: currentProject.applyAsCompanyWebsite,
      clientPhone: currentProject.clientPhone,
      applyAsCompanyPhone: currentProject.applyAsCompanyPhone,
      applyAsCompanyLogo: currentProject.applyAsCompanyLogo,
      contacts: currentProject.contacts,
    });
    finishLoading();
    if (result.error || !result.data.project) {
      createToast({
        message: result.message || 'Editing project has been failed',
        type: 'error',
      });
      return;
    }

    const { project, tags } = result.data;
    setTags(tags);

    if (currentProject.logo && currentProject.logo.name !== 'Logo') {
      const logoName = currentProject.logo.name;
      const fileExt = logoName.slice(logoName.lastIndexOf('.'));
      project.logo = project._id + fileExt;
      startLoading();
      const res = await PortfolioService.setProjectLogo(project, currentProject.logo, project.logo);
      finishLoading();
      if (res.error) {
        project.logo = null;
        project.preview = null;
        createToast({
          message: res.message || 'Setting project logo has been failed.',
          type: 'error',
        });
      } else {
        project.preview = currentProject.logo.preview;
      }
    }
    if (!currentProject.logo && editingProject.logo) {
      startLoading();
      const res = await PortfolioService.removeProjectLogo(project);
      finishLoading();
      if (res.error) {
        createToast({
          message: res.message || 'Removing project logo has been failed.',
          type: 'error',
        });
      } else {
        project.logo = null;
        project.preview = null;
      }
    }

    if (currentProject.background && currentProject.background.name !== 'Background') {
      project.background = `${project._id}_back.png`;
      startLoading();
      const res = await PortfolioService.setProjectBackground(project, currentProject.background, project.background);
      finishLoading();
      if (res.error) {
        project.background = null;
        project.backPreview = null;
        createToast({
          message: res.message || 'Setting project background has been failed.',
          type: 'error',
        });
      } else {
        project.backPreview = currentProject.background.preview;
      }
    }
    if (!currentProject.background && editingProject.background) {
      startLoading();
      const res = await PortfolioService.removeProjectBackground(project);
      finishLoading();
      if (res.error) {
        createToast({
          message: res.message || 'Removing project background has been failed.',
          type: 'error',
        });
      } else {
        project.background = null;
        project.backPreview = null;
      }
    }

    editProject(project);
    createToast({
      message: 'Projects has been edited successfully.',
      type: 'success',
    });
    onClose();
  };

  const onRemoveContact = (contact) => {
    setCurrentProject({
      ...currentProject,
      contacts: currentProject.contacts.filter((id) => id !== contact._id),
    });
  };

  const onChangeContacts = (contacts) => {
    setCurrentProject({
      ...currentProject,
      contacts,
    });
  };

  return (
    <>
      <Dialog
        title={editingProject._id ? 'Edit Project' : 'Create Project'}
        className="create-project-modal"
        open={open}
        onClose={onClose}
        onPaste={onPasteImage}
        footerActions={(
          <>
            <Button className="size-sm px-3 mr-2" onClick={onClose}>Cancel</Button>
            {
              editingProject._id
                ? <Button className="btn-primary size-sm px-3" onClick={onEdit}>Save</Button>
                : <Button className="btn-primary size-sm px-3" onClick={onCreate}>Create</Button>
            }
          </>
        )}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl control={form.client} size="sm" className="mb-0" onChange={onInputChange} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl control={form.name} size="sm" className="mb-0" onChange={onInputChange} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl control={form.year} size="sm" className="mb-0" onChange={onInputChange} />
          </Grid>
          <Grid item xs={12}>
            <FormControl control={form.tags} size="sm" className="mb-0" onChange={onInputChange} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl control={form.clientWebsite} size="sm" className="mb-0" onChange={onInputChange} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl control={form.applyAsCompanyWebsite} size="sm" className="mb-0" onChange={onInputChange} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl control={form.clientPhone} size="sm" className="mb-0" onChange={onInputChange} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl control={form.applyAsCompanyPhone} size="sm" className="mb-0" onChange={onInputChange} />
          </Grid>
          <Grid item xs={12}>
            <FormControl control={form.introduction} size="sm" className="mb-0" onChange={onIntroductionChange} />
          </Grid>
          <Grid item xs={12}>
            <div className="project-logo">
              <label className="text-gray text-sm mb-2">Project Logo</label>
              <Dropzone
                name="file"
                className="dropzone"
                accept="image/*"
                multiple={false}
                onDrop={(files) => onDrop('logo', files)}
              >
                {
                  currentProject.logo
                    ? (
                      <div className="preview">
                        <img className="image" alt={currentProject.logo.name} src={currentProject.logo.preview} />
                        <div className="backdrop">Change Logo</div>
                      </div>
                    )
                    : (
                      <div className="dropify-message">
                        <i className="fa fa-file-image-o" />
                        <p>Drag and drop logo here or click</p>
                      </div>
                    )
                }
              </Dropzone>
              {Boolean(currentProject.logo) && (
                <div className="text-center mt-1">
                  <span className="text-primary text-sm hover-underline cursor-pointer" onClick={() => onInputChange('logo', null)}>Remove</span>
                </div>
              )}
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="project-background">
              <label className="text-gray text-sm mb-2">Project Background</label>
              <Dropzone
                name="file"
                className="dropzone"
                accept="image/*"
                multiple={false}
                onDrop={(files) => onDrop('background', files)}
              >
                {
                  currentProject.background
                    ? (
                      <div className="preview">
                        <img className="image" alt={currentProject.background.name} src={currentProject.background.preview} />
                        <div className="backdrop">Change Background</div>
                      </div>
                    )
                    : (
                      <div className="dropify-message">
                        <i className="fa fa-file-image-o" />
                        <p>Drag and drop background here or click</p>
                      </div>
                    )
                }
              </Dropzone>
              {Boolean(currentProject.background) && (
                <div className="text-center mt-1">
                  <span className="text-primary text-sm hover-underline cursor-pointer" onClick={() => onInputChange('background', null)}>Remove</span>
                </div>
              )}
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="d-flex justify-content-between align-items-center mb-2">
              <label className="text-gray text-sm mb-0">Contacts</label>
              <Tooltip title="Add contacts" placement="top" arrow>
                <IconButton className="btn-primary size-sm" onClick={() => setShowContacts(true)}>
                  <PersonAdd />
                </IconButton>
              </Tooltip>
            </div>
            <div className="contacts">
              {selectedContacts.map((contact) => (
                <ContactItem
                  key={contact._id}
                  contact={contact}
                  removable
                  onDeselect={() => onRemoveContact(contact)}
                />
              ))}
              {!selectedContacts.length && (
                <p className="text-center mb-0">No contacts selected. Please select from contacts.</p>
              )}
            </div>
          </Grid>
        </Grid>
      </Dialog>

      {showContacts && (
        <ContactsModal
          open
          selectable
          selected={currentProject.contacts}
          onClose={() => setShowContacts(false)}
          onSelectionChanged={onChangeContacts}
        />
      )}
    </>
  );
};

EditProjectModal.propTypes = {
  open: PropTypes.bool,
  clients: PropTypes.array,
  tags: PropTypes.array,
  contacts: PropTypes.array,
  editingProject: PropTypes.object.isRequired,
  setTags: PropTypes.func.isRequired,
  setEditingProject: PropTypes.func.isRequired,
  addProject: PropTypes.func.isRequired,
  editProject: PropTypes.func.isRequired,
  createToast: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
};

EditProjectModal.defaultProps = {
  open: false,
  clients: [],
  tags: [],
  contacts: [],
};

const mapStateToProps = (store) => ({
  clients: store.portfolioReducer.clients,
  tags: store.portfolioReducer.tags,
  contacts: store.portfolioReducer.contacts,
  editingProject: store.portfolioReducer.editingProject,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setTags: PortfolioAction.setTags,
      setEditingProject: PortfolioAction.setEditingProject,
      addProject: PortfolioAction.addProject,
      editProject: PortfolioAction.editProject,
      createToast: ToastAction.createToast,
      startLoading: CommonAction.startLoading,
      finishLoading: CommonAction.finishLoading,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProjectModal);
