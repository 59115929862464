import React, { useEffect, useMemo, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './EditReportModal.scss';

import { Button, Grid } from '@material-ui/core';
import Dropzone from 'react-dropzone';
import Dialog from '../../../common/Dialog';
import FormControl from '../../../common/FormControl';
import * as PortfolioService from '../../../../services/portfolio.service';
import * as ToastAction from '../../../../store/actions/toast.action';
import * as CommonAction from '../../../../store/actions/common.action';
import config from '../../../../constants/config';
import { getCurrentWeek } from '../../../../utils';
import Validators from '../../../common/FormControl/validators';
import { patchFormData, validateFormControl, validateFormGroup } from '../../../common/FormControl/utils';

const EditReportModal = ({
  open, report, project, onClose, onUpload, onDelete, createToast, startLoading, finishLoading,
}) => {
  const form = useMemo(() => ({
    accomplish: {
      name: 'accomplish',
      label: 'What did you accomplish this week? *',
      multiline: true,
      validators: [Validators.required()],
    },
    plan: {
      name: 'plan',
      label: 'What are your plans for next week? *',
      multiline: true,
      validators: [Validators.required()],
    },
    blockers: {
      name: 'blockers',
      label: 'What are your blockers?',
      multiline: true,
    },
    actions: {
      name: 'actions',
      label: 'Action items',
      multiline: true,
    },
    status: {
      name: 'status',
      type: 'autocomplete',
      label: 'Status',
      options: ['On Track', 'Discovery', 'Design', 'Development', 'Deployed', 'Late', 'Other'],
    },
    statusText: {
      name: 'statusText',
      placeholder: 'Input status here',
    },
    color: {
      name: 'color',
      type: 'autocomplete',
      label: 'Color',
      options: ['Blue', 'Green', 'Yellow', 'Red'],
    },
  }), []);

  const [currentReport, setCurrentReport] = useState({
    accomplish: '',
    plan: '',
    blockers: '',
    actions: '',
    status: null,
    statusText: '',
    color: null,
    audio: null,
  });

  useEffect(() => {
    patchFormData(form, currentReport);
  }, [currentReport, form]);

  useEffect(() => {
    if (currentReport.status === 'Other') {
      form.statusText.validators = [Validators.required()];
    } else {
      form.statusText.validators = [];
    }
    validateFormControl(form.statusText);
  }, [currentReport.status, form.statusText]);

  useEffect(() => {
    if (!report)
      return;

    let status = report.status;
    let statusText = '';
    if (status && !form.status.options.slice(0, -1).includes(status)) {
      statusText = status;
      status = 'Other';
    }

    setCurrentReport({
      accomplish: report.accomplish || '',
      plan: report.plan || '',
      blockers: report.blockers || '',
      actions: report.actions || '',
      status,
      statusText,
      color: report.color,
      audio: (report.audio
        ? ({
          name: '_ORG_AUDIO_',
          preview: `${config.REPORT_URL}/${report.audio}?q=${report.updatedAt}`,
        })
        : null),
    });
  }, [form.status.options, report]);

  const onInputChange = (field, value) => {
    setCurrentReport({
      ...currentReport,
      [field]: value,
    });
  };

  const onDrop = (field, files) => {
    setCurrentReport({
      ...currentReport,
      [field]: files ? files[0] : null,
    });
  };

  const onCreate = async () => {
    if (!validateFormGroup(form))
      return;

    startLoading();
    const result = await PortfolioService.createWeeklyReport({
      ...currentReport,
      status: currentReport.status === 'Other' ? currentReport.statusText : currentReport.status,
      project: project._id,
    });
    finishLoading();

    if (result.error || !result.data.weeklyReport) {
      createToast({
        message: result.message || 'Creating weekly report has been failed',
        type: 'error',
      });
      return;
    }

    onUpload(result.data.weeklyReport);
  };

  const onEdit = async () => {
    if (!validateFormGroup(form))
      return;

    startLoading();

    const result = await PortfolioService.updateWeeklyReport({
      ...currentReport,
      _id: report._id,
      status: currentReport.status === 'Other' ? currentReport.statusText : currentReport.status,
      audio: currentReport.audio && currentReport.audio.name !== '_ORG_AUDIO_' ? currentReport.audio : null,
      fileName: currentReport.audio ? report.audio : null,
    });
    finishLoading();
    if (result.error || !result.data.weeklyReport) {
      createToast({
        message: result.message || 'Editing weekly report has been failed',
        type: 'error',
      });
      return;
    }

    onUpload(result.data.weeklyReport);
  };

  const onRemove = async () => {
    if (!window.confirm('Are you sure to remove this weekly report?')) {
      return;
    }

    startLoading();
    const result = await PortfolioService.removeWeeklyReport(report);
    finishLoading();
    if (result.error) {
      createToast({
        message: result.message || 'Deleting weekly report has been failed',
        type: 'error',
      });
      return;
    }

    onDelete();
  };

  return (
    <Dialog
      title={report ? getCurrentWeek(report.createdAt) : 'Create Weekly Report'}
      className="create-weekly-report-modal"
      open={open}
      onClose={onClose}
      footerActions={(
        <>
          {report && (
            <Button className="btn-danger size-sm px-3 mr-2" onClick={onRemove}>Delete</Button>
          )}
          <Button className="size-sm px-3 mr-2 ml-auto" onClick={onClose}>Cancel</Button>
          {
            report
              ? <Button className="btn-primary size-sm px-3" onClick={onEdit}>Save</Button>
              : <Button className="btn-primary size-sm px-3" onClick={onCreate}>Create</Button>
          }
        </>
      )}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl control={form.accomplish} size="sm" className="mb-0" onChange={onInputChange} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl control={form.plan} size="sm" className="mb-0" onChange={onInputChange} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl control={form.blockers} size="sm" className="mb-0" onChange={onInputChange} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl control={form.actions} size="sm" className="mb-0" onChange={onInputChange} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl control={form.status} size="sm" className="mb-0" onChange={onInputChange} />
          {(currentReport.status === 'Other') && (
            <FormControl control={form.statusText} size="sm" className="mt-1 mb-0" onChange={onInputChange} />
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl control={form.color} size="sm" className="mb-0" onChange={onInputChange} />
        </Grid>
      </Grid>

      <div className="report-audio mt-3">
        <label className="text-gray text-sm mb-2">Audio</label>
        <Dropzone
          name="file"
          className="dropzone"
          accept={['audio/*', 'video/*']}
          multiple={false}
          onDrop={(files) => onDrop('audio', files)}
        >
          {currentReport.audio ? (
            <div className="preview">
              {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
              <audio className="image" src={currentReport.audio.preview} controls />
              <div className="backdrop">Change Audio</div>
            </div>
          ) : (
            <div className="dropify-message">
              <i className="fa fa-file-audio-o" />
              <p>Drag and drop audio here or click</p>
            </div>
          )}
        </Dropzone>
        {Boolean(currentReport.audio) && (
          <div className="text-center mt-1">
            <span
              className="text-sm hover-underline cursor-pointer"
              onClick={() => onInputChange('audio', null)}
            >
              Remove
            </span>
          </div>
        )}
      </div>
    </Dialog>
  );
};

EditReportModal.propTypes = {
  open: PropTypes.bool,
  report: PropTypes.object,
  project: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  createToast: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
};

EditReportModal.defaultProps = {
  open: false,
  report: null,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createToast: ToastAction.createToast,
      startLoading: CommonAction.startLoading,
      finishLoading: CommonAction.finishLoading,
    },
    dispatch,
  );
}

export default connect(null, mapDispatchToProps)(EditReportModal);
