import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './style.scss';

import { Button } from '@material-ui/core';
import ClientMenu from './ClientMenu';
import EditClientModal from '../Project/EditClientModal';
import EditProjectModal from '../Project/EditProjectModal';
import * as PortfolioAction from '../../store/actions/portfolio.action';
import * as PortfolioService from '../../services/portfolio.service';
import * as ToastAction from '../../store/actions/toast.action';
import * as CommonAction from '../../store/actions/common.action';
import ROUTES from '../../constants/routes.json';
import { ROLES } from '../../constants';

const SideBar = ({
  user, client, clients, editingProject, collapsed,
  setClients, setTags, setContacts, setActiveProject, setActiveTab, setWeeklyReportedProjects,
  createToast, startLoading, finishLoading,
}) => {
  const [openedClient, setOpenedClient] = useState(null);
  const [editingClient, setEditingClient] = useState(null);

  useEffect(() => {
    setOpenedClient(client);
  }, [client]);

  useEffect(() => {
    if (!user?.allowReport)
      return;

    PortfolioService.getWeeklyReportedProjects().then((result) => {
      if (result.error) {
        // createToast({
        //   message: result.message || 'Loading weekly reported projects failed',
        //   type: 'error',
        // });
      } else {
        setWeeklyReportedProjects(result.data.projects);
      }
    });
  }, [createToast, setWeeklyReportedProjects, user]);

  const setInitialOpenedClient = useCallback((clients) => {
    const lastProjectId = user.lastVisit.project;
    let lastClient;
    let lastProject;
    for (const client of clients) {
      lastProject = client.projects.find((p) => p._id === lastProjectId);
      if (lastProject) {
        lastClient = client;
        break;
      }
    }
    if (!lastProject && clients.length > 0) {
      lastClient = clients[0];
      if (lastClient.projects.length)
        lastProject = lastClient.projects[0];
    }
    setOpenedClient(lastClient);
    setActiveProject(lastProject);
    setActiveTab(user.lastVisit.tab || 'screenshot');
  }, [setActiveProject, setActiveTab]);

  useEffect(() => {
    startLoading();
    PortfolioService.getClients({ params: { expand: ['project.all'] } }).then((result) => {
      finishLoading();
      if (result.error) {
        createToast({
          message: result.message,
          type: 'error',
        });
      } else {
        setClients(result.data.clients);
        setInitialOpenedClient(result.data.clients);
      }
    });

    startLoading();
    PortfolioService.getTags().then((result) => {
      finishLoading();
      if (result.error) {
        createToast({
          type: 'error',
          message: result.message || 'Loading tags has been failed.',
        });
      } else {
        setTags(result.data.tags);
      }
    });
  }, [createToast, finishLoading, setClients, setInitialOpenedClient, setTags, startLoading]);

  useEffect(() => {
    if (!user)
      return;

    startLoading();
    PortfolioService.getContacts(user._id).then((result) => {
      finishLoading();
      if (result.error) {
        createToast({
          type: 'error',
          message: result.message || 'Loading contacts has been failed.',
        });
      } else {
        setContacts(result.data.contacts);
      }
    });
  }, [createToast, finishLoading, setContacts, startLoading, user]);

  const onCreateClient = useCallback(() => {
    setEditingClient({
      _id: null,
      name: '',
      logo: null,
    });
  }, [setEditingClient]);

  const onEditClient = (client) => {
    setEditingClient(client);
  };

  const onSelectClient = (client) => {
    if (openedClient && client._id === openedClient._id) {
      setOpenedClient(null);
    } else {
      setOpenedClient(client);
    }
  };

  if (!user) {
    return null;
  }

  return (
    <div className={`sidebar ${collapsed ? 'collapsed' : ''}`}>
      <div className="sidebar-header">
        <Link to={ROUTES.PROJECTS.url}>
          <img className="logo" src="/resources/images/logo.png" alt="Portfolio Tool" />
        </Link>
      </div>

      <div className="sidebar-content">
        <h2 className="text-13p text-uppercase">Portfolio Items</h2>

        <div className="client-list">
          {
            clients
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((client) => (
                <ClientMenu
                  key={client._id}
                  client={client}
                  editable={user.role !== ROLES.CLIENT}
                  opened={openedClient && client._id === openedClient._id}
                  onEditClient={onEditClient}
                  onSelectClient={onSelectClient}
                />
              ))
          }
        </div>
      </div>

      {user.role !== ROLES.CLIENT && (
        <div className="sidebar-footer">
          <Button fullWidth className="btn-warning size-sm font-weight-bold" onClick={onCreateClient}>
            + Create Client
          </Button>
        </div>
      )}

      {editingClient && (
        <EditClientModal
          open
          editingClient={editingClient}
          onClose={() => setEditingClient(null)}
        />
      )}
      {editingProject && (
        <EditProjectModal open />
      )}
    </div>
  );
};

SideBar.propTypes = {
  user: PropTypes.object,
  client: PropTypes.object,
  clients: PropTypes.array,
  editingProject: PropTypes.object,
  collapsed: PropTypes.bool,
  setClients: PropTypes.func.isRequired,
  setTags: PropTypes.func.isRequired,
  setContacts: PropTypes.func.isRequired,
  setActiveProject: PropTypes.func.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  setWeeklyReportedProjects: PropTypes.func.isRequired,
  createToast: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
};

SideBar.defaultProps = {
  user: null,
  client: null,
  clients: [],
  editingProject: null,
  collapsed: false,
};

const mapStateToProps = (store) => ({
  user: store.authReducer.user,
  client: store.portfolioReducer.client,
  clients: store.portfolioReducer.clients,
  editingClient: store.portfolioReducer.editingClient,
  editingProject: store.portfolioReducer.editingProject,
  collapsed: store.portfolioReducer.sidebarCollapsed,
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators(
    {
      setClient: PortfolioAction.setClient,
      setClients: PortfolioAction.setClients,
      setTags: PortfolioAction.setTags,
      setContacts: PortfolioAction.setContacts,
      setActiveProject: PortfolioAction.setActiveProject,
      setActiveTab: PortfolioAction.setActiveTab,
      setWeeklyReportedProjects: PortfolioAction.setWeeklyReportedProjects,
      createToast: ToastAction.createToast,
      startLoading: CommonAction.startLoading,
      finishLoading: CommonAction.finishLoading,
    },
    dispatch,
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
