import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import './style.scss';

import RecentImage from '../../components/RecentImages/RecentImage';
import * as ToastAction from '../../store/actions/toast.action';
import * as PortfolioService from '../../services/portfolio.service';
import * as CommonAction from '../../store/actions/common.action';

class RecentImages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      images: [],
    };
  }

  componentDidMount() {
    const count = parseInt(this.props.match.params.count, 10);
    if (count > 0) {
      this.props.startLoading();
      PortfolioService.getRecentImages(count).then((result) => {
        this.props.finishLoading();
        if (result.error) {
          this.props.createToast({
            message: result.message || 'Loading images has been failed!',
            type: 'error',
          });
          this.setState({
            images: [],
          });
        } else {
          this.setState({
            images: result.images,
          });
        }
      });
    } else {
      this.setState({
        images: [],
      });
    }
  }

  render() {
    const { images } = this.state;

    return (
      <div className="recent-images-page">
        <div className="d-flex flex-wrap justify-content-around">
          {
            images.map((image) => <RecentImage key={image._id} image={image} />)
          }
          {
            !images.length
              ? 'No available images'
              : null
          }
        </div>
      </div>
    );
  }
}

RecentImages.propTypes = {
  match: PropTypes.object.isRequired,
  createToast: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createToast: ToastAction.createToast,
      startLoading: CommonAction.startLoading,
      finishLoading: CommonAction.finishLoading,
    },
    dispatch,
  );
}

export default connect(null, mapDispatchToProps)(RecentImages);
