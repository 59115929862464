import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './PreviewModal.scss';

import config from '../../../../constants/config';

const PreviewModal = ({
  image, images, show, onClose,
}) => {
  const [activeImage, setActiveImage] = useState(null);
  const imageElement = useRef(null);

  useEffect(() => {
    setActiveImage(image);
  }, [image]);

  const url = useMemo(() => {
    if (!activeImage)
      return '';
    if (activeImage.previewUrl)
      return activeImage.previewUrl;
    const rootUrl = activeImage.type.startsWith('image') ? config.PORTFOLIO_URL : config.VIDEO_URL;
    return `${rootUrl}/${activeImage._id}.${activeImage.type.split(/\W/)[1]}?q=${activeImage.updatedAt}`;
  }, [activeImage]);

  const onBackdropClick = (e) => {
    if (e.target.className === 'preview-wrapper') {
      onClose();
    }
  };

  const onImageLoad = () => {
    const el = imageElement.current;
    if (el) {
      let width = el.naturalWidth;
      let height = el.naturalHeight;
      if (width < 200)
        width = 200;
      const maxWidth = window.innerWidth - 50;
      const maxHeight = window.innerHeight - 50;
      if (width > maxWidth) {
        height = (height / width) * maxWidth;
        width = maxWidth;
      }
      if (height > maxHeight) {
        width = (width / height) * maxHeight;
        height = maxHeight;
      }
      el.width = width;
    }
  };

  const onZoom = (ratio) => {
    const el = imageElement.current;
    if (el) {
      if (ratio < 1 && (el.width < 150 || el.height < 150)) {
        return;
      }

      el.width *= ratio;
      if (el.width > window.innerWidth) {
        el.parentNode.style.justifyContent = 'flex-start';
      } else {
        el.parentNode.style.justifyContent = 'center';
      }
      if (el.height > window.innerHeight) {
        el.parentNode.style.alignItems = 'flex-start';
      } else {
        el.parentNode.style.alignItems = 'center';
      }
    }
  };

  const onGotoNextImage = (offset) => {
    let index = images.indexOf(activeImage);
    if (index === -1)
      return;

    index = (index + offset + images.length) % images.length;
    setActiveImage(images[index]);
  };

  return (
    <>
      {
        show && activeImage
          ? (
            <div className="preview-modal">
              <div className="preview-wrapper" onClick={onBackdropClick}>
                {
                  activeImage.type.startsWith('image')
                    ? <img src={url} alt={activeImage._id} ref={imageElement} onLoad={onImageLoad} />
                    // eslint-disable-next-line jsx-a11y/media-has-caption
                    : <video controls src={url} />
                }
              </div>
              {
                imageElement && (
                  <div className="action-buttons w-100">
                    {
                      activeImage.previewTitle && (
                        <h4 className="text-center text-white">{activeImage.previewTitle}</h4>
                      )
                    }
                    <div className="d-flex-center">
                      <i className="fa fa-search-minus action-button" data-toggle="tooltip" title="Zoom Out" onClick={() => onZoom(0.6667)} />
                      <i className="fa fa-search-plus action-button" data-toggle="tooltip" title="Zoom In" onClick={() => onZoom(1.5)} />
                    </div>
                  </div>
                )
              }
              {
                images.length > 1 && (
                  <>
                    <i className="btn-prev fa fa-arrow-left action-button" data-toggle="tooltip" title="Prev" onClick={() => onGotoNextImage(-1)} />
                    <i className="btn-next fa fa-arrow-right action-button" data-toggle="tooltip" title="Next" onClick={() => onGotoNextImage(1)} />
                  </>
                )
              }
              <i className="fa fa-times action-button close-button" data-toggle="tooltip" title="Close" onClick={onClose} />
            </div>
          )
          : null
      }
    </>
  );
};

PreviewModal.propTypes = {
  image: PropTypes.object,
  images: PropTypes.array,
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

PreviewModal.defaultProps = {
  image: null,
  images: [],
  show: false,
  onClose: null,
};

export default PreviewModal;
