import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import './style.scss';

import FormControl from '../../components/common/FormControl';
import PreviewModal from '../../components/ProjectScrum/PreviewModal';
import { parseQuery } from '../../utils';
import config from '../../constants/config';
import { MEDIA_STATUSES } from '../../constants';
import { patchFormControl, setFormControlOptions } from '../../components/common/FormControl/utils';
import * as ToastAction from '../../store/actions/toast.action';
import * as CommonAction from '../../store/actions/common.action';
import * as PortfolioService from '../../services/portfolio.service';

const ProjectScrum = ({
  location, createToast, startLoading, finishLoading,
}) => {
  const form = useMemo(() => ({
    project: {
      name: 'project',
      type: 'autocomplete',
      placeholder: 'Select project',
      options: [],
      labelField: 'name',
      controlOptions: {
        disableClearable: true,
      },
    },
    status: {
      name: 'status',
      type: 'autocomplete',
      placeholder: 'Select status',
      options: MEDIA_STATUSES,
    },
  }), []);

  const [clientId, setClientId] = useState(null);
  const [initialProjectId, setInitialProjectId] = useState(null);
  const [projects, setProjects] = useState([]);
  const [activeProject, setActiveProject] = useState(null);
  const [activeStatus, setActiveStatus] = useState('');
  const [previewImage, setPreviewImage] = useState(null);

  useEffect(() => {
    setFormControlOptions(form.project, {
      options: projects,
    });
  }, [form.project, projects]);

  useEffect(() => {
    patchFormControl(form.project, activeProject);
  }, [activeProject, form.project]);

  useEffect(() => {
    const queries = parseQuery(location.search);
    setClientId(queries.client || '');
    setInitialProjectId(queries.project || '');
  }, [createToast, finishLoading, location.search, startLoading]);

  useEffect(() => {
    if (!clientId) {
      setProjects([]);
      return;
    }

    startLoading();
    PortfolioService.getProjects(clientId).then((result) => {
      finishLoading();
      if (result.error) {
        createToast({
          message: result.message || 'Loading projects has been failed',
          type: 'error',
        });
      } else {
        setProjects(result.data.client.projects);
      }
    });
  }, [clientId, createToast, finishLoading, startLoading]);

  useEffect(() => {
    if (!activeProject && projects && projects.length) {
      setActiveProject(projects.find((p) => p._id === initialProjectId) || projects[0]);
    }
    setPreviewImage(null);
  }, [activeProject, initialProjectId, projects]);

  const activeImages = useMemo(() => {
    if (!activeProject)
      return [];
    if (!activeStatus)
      return activeProject.images;
    return activeProject.images.filter((image) => image.status === activeStatus);
  }, [activeProject, activeStatus]);

  const getImageSize = (index) => {
    const sizes = ['col-12 col-md-8', 'col-6 col-md-4', 'col-6 col-md-4', 'col-6 col-md-4', 'col-6 col-md-4', 'col-6', 'col-6'];
    return sizes[index % sizes.length];
  };

  const onPreviewImage = (image) => {
    setPreviewImage(image);
  };

  return (
    <div className="project-scrum-page">
      <div className="search-box card-box d-flex justify-content-center p-3 mb-3">
        <div className="d-flex align-items-center mx-4">
          <label className="text-muted text-sm mb-0 mr-2">Project:</label>
          <FormControl
            control={form.project}
            size="sm"
            fullWidth={false}
            className="mb-0"
            onChange={(_, value) => setActiveProject(value)}
          />
        </div>
        <div className="d-flex align-items-center mx-4">
          <label className="text-muted text-sm mb-0 mr-2">Status:</label>
          <FormControl
            control={form.status}
            size="sm"
            fullWidth={false}
            className="mb-0"
            onChange={(_, value) => setActiveStatus(value)}
          />
        </div>
      </div>

      <div className="page-content card-box">
        {
          activeImages.length
            ? (
              <div className="row flex-wrap">
                {
                  activeImages.map((image, index) => (
                    <div className={`media-item ${getImageSize(index)}`} key={image._id}>
                      {
                        image.type.startsWith('image')
                          ? (
                            <img
                              src={`${config.PORTFOLIO_URL}/${image._id}.${image.type.split(/\W/)[1]}?q=${image.updatedAt}`}
                              alt={image._id}
                              onClick={() => onPreviewImage(image)}
                            />
                          )
                          : (
                            <div className="video-frame" onClick={() => onPreviewImage(image)}>
                              <img
                                src="resources/images/video/video_frame.png"
                                alt={image._id}
                                className="video-frame-img"
                              />
                              <video
                                className="mw-100 mh-100"
                                src={`${config.VIDEO_URL}/${image._id}.${image.type.split(/\W/)[1]}?q=${image.updatedAt}`}
                              />
                            </div>
                          )
                      }
                    </div>
                  ))
                }
              </div>
            )
            : (
              <div className="text-center text-2xl p-5">
                No medias are available.
              </div>
            )
        }
      </div>
      {
        previewImage && (
          <PreviewModal
            show
            image={previewImage}
            images={activeImages}
            project={activeProject}
            onClose={() => setPreviewImage(null)}
          />
        )
      }
    </div>
  );
};

ProjectScrum.propTypes = {
  location: PropTypes.object.isRequired,
  createToast: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createToast: ToastAction.createToast,
      startLoading: CommonAction.startLoading,
      finishLoading: CommonAction.finishLoading,
    },
    dispatch,
  );
}

export default connect(null, mapDispatchToProps)(ProjectScrum);
