import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import authReducer from './auth.reducer';
import portfolioReducer from './portfolio.reducer';
import toastReducer from './toast.reducer';
import commonReducer from './common.reducer';

export default (history) => combineReducers({
  router: connectRouter(history),
  authReducer,
  portfolioReducer,
  toastReducer,
  commonReducer,
});
