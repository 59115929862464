import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './style.scss';

import { ArrowUpward, GetAppOutlined, WidgetsOutlined } from '@material-ui/icons';
import { IconButton, Tooltip } from '@material-ui/core';
import GalleryHeader from './Header';
import ScreenshotsTab from './ScreenshotsTab';
import LogoTab from './LogoTab';
import BackgroundImageTab from './BackgroundImageTab';
import ProgressTab from './ProgressTab';
import CaseStudyTab from './CaseStudyTab';
import LiveLinkTab from './LiveLinkTab';
import WeeklyReportTab from './WeeklyReportTab';
import * as ToastAction from '../../../store/actions/toast.action';
import * as PortfolioAction from '../../../store/actions/portfolio.action';
import * as PortfolioService from '../../../services/portfolio.service';
import * as CommonAction from '../../../store/actions/common.action';
import BrandingTab from './BrandingTab';
import { ROLES } from '../../../constants';

const ImageGallery = ({
  user, activeProject, activeTab, setActiveTab, selectedImages,
  setGlobalSearch, setSelectedImages, setPreviewImage, startLoading, finishLoading, createToast,
}) => {
  useEffect(() => {
    setPreviewImage(null);
  }, [activeProject, setPreviewImage]);

  const onResetSelectedImages = () => {
    setSelectedImages([]);
  };

  const onScrollToTop = useCallback(() => {
    const container = document.querySelector('.layout-top-left-corner');
    if (container)
      container.scrollIntoView({
        behavior: 'smooth',
      });
  }, []);

  useEffect(() => {
    setGlobalSearch(false);
    onScrollToTop();
  }, [activeProject, onScrollToTop, setGlobalSearch]);

  const onDownloadMedias = () => {
    if (!selectedImages.length) {
      return;
    }

    startLoading();
    PortfolioService.downloadMedias(selectedImages.map((img) => img._id)).then((result) => {
      finishLoading();
      if (result.error) {
        createToast({
          message: result.message,
          type: 'error',
        });
      } else {
        onResetSelectedImages();
        createToast({
          type: 'success',
          message: `You have been downloaded ${selectedImages.length} file(s)`,
        });
      }
    });
  };

  if (!activeProject) {
    return (
      <div className="image-gallery pt-5 mt-5">
        <div className="no-data">
          <WidgetsOutlined fontSize="large" />
          <p className="mt-3 mb-0">There are no created projects.</p>
          <p>Please create one.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="image-gallery" id="image-gallery">
      <GalleryHeader user={user} project={activeProject} />

      <div className="tab-container">
        <div
          className={`tab-item ${activeTab === 'screenshot' ? 'active' : ''}`}
          onClick={() => setActiveTab('screenshot')}
        >
          Screenshots
          <span className="badge">{activeProject.images.length}</span>
        </div>
        <div
          className={`tab-item ${activeTab === 'brand' ? 'active' : ''}`}
          onClick={() => setActiveTab('brand')}
        >
          Brand
          <span className="badge">{(activeProject.logo ? 1 : 0) + (activeProject.background ? 1 : 0)}</span>
        </div>
        {user.role !== ROLES.CLIENT && (
          <>
            <div
              className={`tab-item ${activeTab === 'progress' ? 'active' : ''}`}
              onClick={() => setActiveTab('progress')}
            >
              Progress
            </div>
            <div
              className={`tab-item ${activeTab === 'case-study' ? 'active' : ''}`}
              onClick={() => setActiveTab('case-study')}
            >
              Case Study
              <span className="badge">{activeProject.caseStudy ? 1 : 0}</span>
            </div>
          </>
        )}
        <div
          className={`tab-item ${activeTab === 'live-links' ? 'active' : ''}`}
          onClick={() => setActiveTab('live-links')}
        >
          Live Links
          <span className="badge">
            {activeProject.liveLinks ? activeProject.liveLinks.length : 0}
          </span>
        </div>
        {user.allowReport && (
          <div
            className={`tab-item ${activeTab === 'weekly-report' ? 'active' : ''}`}
            onClick={() => setActiveTab('weekly-report')}
          >
            Weekly Report
          </div>
        )}
      </div>

      {
        activeTab === 'screenshot' && (
          <div className={`tab-content tab-screenshot ${activeTab === 'screenshot' ? 'active' : ''}`}>
            <ScreenshotsTab user={user} />
          </div>
        )
      }
      {
        activeTab === 'brand' && (
          <div className={activeTab === 'brand' ? '' : 'd-none'}>
            <BrandingTab user={user} />
          </div>
        )
      }
      {
        activeTab === 'logo' && (
          <div className={`tab-content tab-logo  ${activeTab === 'logo' ? 'active' : ''}`}>
            <LogoTab user={user} />
          </div>
        )
      }
      {
        activeTab === 'background' && (
          <div className={`tab-content tab-background  ${activeTab === 'background' ? 'active' : ''}`}>
            <BackgroundImageTab user={user} />
          </div>
        )
      }
      {
        activeTab === 'progress' && (
          <div className={`tab-content tab-progress  ${activeTab === 'progress' ? 'active' : ''}`}>
            <ProgressTab user={user} project={activeProject} />
          </div>
        )
      }
      {
        activeTab === 'case-study' && (
          <div className={`tab-content tab-case-study  ${activeTab === 'case-study' ? 'active' : ''}`}>
            <CaseStudyTab user={user} active={activeTab === 'case-study'} />
          </div>
        )
      }
      {
        activeTab === 'live-links' && (
          <div className={`tab-content tab-live-links  ${activeTab === 'live-links' ? 'active' : ''}`}>
            <LiveLinkTab user={user} />
          </div>
        )
      }
      {
        activeTab === 'weekly-report' && (
          <div className={`tab-content tab-weekly-report  ${activeTab === 'weekly-report' ? 'active' : ''}`}>
            <WeeklyReportTab user={user} project={activeProject} />
          </div>
        )
      }

      <div className="float-buttons">
        {Boolean(selectedImages.length) && (
          <Tooltip title="Download Original Media" placement="top" arrow>
            <IconButton
              className="btn-outline-primary size-sm mb-2"
              onClick={onDownloadMedias}
            >
              <GetAppOutlined />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title="Scroll To Top" placement="top" arrow>
          <IconButton
            className="btn-outline-primary size-sm"
            onClick={onScrollToTop}
          >
            <ArrowUpward />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};

ImageGallery.propTypes = {
  user: PropTypes.object.isRequired,
  activeProject: PropTypes.object,
  activeTab: PropTypes.string,
  selectedImages: PropTypes.array,
  setActiveTab: PropTypes.func.isRequired,
  setSelectedImages: PropTypes.func.isRequired,
  setPreviewImage: PropTypes.func.isRequired,
  setGlobalSearch: PropTypes.func.isRequired,
  createToast: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
};

ImageGallery.defaultProps = {
  activeProject: null,
  activeTab: 'screenshot',
  selectedImages: [],
};

const mapStateToProps = (store) => ({
  user: store.authReducer.user,
  activeProject: store.portfolioReducer.activeProject,
  activeTab: store.portfolioReducer.activeTab,
  selectedImages: store.portfolioReducer.selectedImages,
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators(
    {
      setActiveTab: PortfolioAction.setActiveTab,
      setSelectedImages: PortfolioAction.setSelectedImages,
      setPreviewImage: PortfolioAction.setPreviewImage,
      setGlobalSearch: PortfolioAction.setGlobalSearch,
      createToast: ToastAction.createToast,
      startLoading: CommonAction.startLoading,
      finishLoading: CommonAction.finishLoading,
    },
    dispatch,
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(ImageGallery);
