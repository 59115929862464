import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import './style.scss';

import Dialog from '../../../common/Dialog';
import * as PortfolioAction from '../../../../store/actions/portfolio.action';
import { patchFormData, setFormControlOptions } from '../../../common/FormControl/utils';
import { Button, FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core';
import FormControl from '../../../common/FormControl';

const ProjectFilterModal = ({
  open, projectFilter, setProjectFilter, onClose, clients,
}) => {
  const form = useMemo(() => ({
    sortBy: {
      name: 'sortBy',
      type: 'autocomplete',
      label: 'Sort By',
      options: ['Project Name', 'Last Added', 'Last Updated', 'Project Type', 'Project Year'],
    },
    types: {
      name: 'types',
      type: 'autocomplete',
      label: 'Filter By Project Type',
      placeholder: 'Add tags',
      value: [],
      options: [],
      controlOptions: {
        multiple: true,
      },
    },
    years: {
      name: 'years',
      type: 'autocomplete',
      label: 'Filter By Year',
      placeholder: 'Add years',
      value: [],
      options: [],
      controlOptions: {
        multiple: true,
      },
    },
  }), []);

  const [newFilter, setNewFilter] = useState(projectFilter);

  useEffect(() => setNewFilter(projectFilter), [projectFilter]);

  const tagNames = useMemo(() => {
    let names = [];
    clients.forEach((client) => {
      client.projects.forEach((project) => {
        names = names.concat(project.tags.map((tag) => tag.name));
      });
    });
    return [...new Set(names)].sort();
  }, [clients]);

  const projectYears = useMemo(() => {
    const years = [];
    clients.forEach((client) => {
      client.projects.forEach((project) => {
        if (project.year) {
          years.push(project.year);
        }
      });
    });
    return [...new Set(years)].sort();
  }, [clients]);

  useEffect(() => {
    setFormControlOptions(form.types, { options: tagNames });
  }, [form.types, tagNames]);

  useEffect(() => {
    setFormControlOptions(form.years, { options: projectYears });
  }, [form.years, projectYears]);

  useEffect(() => {
    patchFormData(form, newFilter);
  }, [form, newFilter]);

  const onInputChange = (field, value) => {
    setNewFilter({
      ...newFilter,
      [field]: value,
    });
  };

  const onClear = () => {
    setNewFilter({
      ...newFilter,
      sortBy: 'Project Name',
      sortOrder: 'asc',
      types: [],
      years: [],
    });
  };

  const onApply = () => {
    setProjectFilter({
      ...newFilter,
      isSet: newFilter.types.length || newFilter.years.length,
    });
    onClose();
  };

  return (
    <Dialog
      title="Sort and Filter"
      className="project-filter-modal"
      open={open}
      onClose={onClose}
      footerActions={(
        <>
          <Button className="size-sm px-3 mr-2" onClick={onClear}>Clear</Button>
          <Button className="btn-primary size-sm px-3" onClick={() => onApply(newFilter)}>Apply</Button>
        </>
      )}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl control={form.sortBy} size="sm" className="mb-0" onChange={onInputChange} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RadioGroup
            row
            value={newFilter.sortOrder}
            className="justify-content-around mt-4 pt-2"
            onChange={(_, value) => onInputChange('sortOrder', value)}
          >
            <FormControlLabel value="asc" control={<Radio color="primary" />} label="Ascending" />
            <FormControlLabel value="desc" control={<Radio color="primary" />} label="Descending" />
          </RadioGroup>
        </Grid>
        <Grid item xs={12}>
          <FormControl control={form.types} size="sm" className="mb-0" onChange={onInputChange} />
        </Grid>
        <Grid item xs={12}>
          <FormControl control={form.years} size="sm" className="mb-0" onChange={onInputChange} />
        </Grid>
      </Grid>
    </Dialog>
  );
};

ProjectFilterModal.propTypes = {
  open: PropTypes.bool,
  projectFilter: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  clients: PropTypes.array,
  setProjectFilter: PropTypes.func.isRequired,
};

ProjectFilterModal.defaultProps = {
  open: false,
  onClose: null,
  clients: [],
};

const mapStateToProps = (store) => ({
  clients: store.portfolioReducer.clients,
  projectFilter: store.portfolioReducer.projectFilter,
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators(
    {
      setProjectFilter: PortfolioAction.setProjectFilter,
    },
    dispatch,
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(ProjectFilterModal);
