export const validateFormControl = (control) => {
  control.touched = true;
  if (control.setError) {
    control.setError(null);
  } else {
    control.error = null;
    control.invalid = false;
  }

  if (!control.validators)
    return true;

  for (const validator of control.validators) {
    const error = validator(control);
    if (error && error !== control.error) {
      if (control.setError) {
        control.setError(error);
      } else {
        control.error = error;
        control.invalid = true;
      }
      return false;
    }
  }

  return true;
};

export const isFormGroup = (form) => {
  if (typeof form !== 'object')
    return false;

  for (const key of Object.keys(form)) {
    const field = form[key];
    if (typeof field !== 'object' || field.name !== key)
      return false;
  }
  return true;
};

export const validateFormGroup = (formGroup) => {
  let isValid = true;
  Object.keys(formGroup).forEach((name) => {
    const control = formGroup[name];
    if (isFormGroup(control)) {
      if (!validateFormGroup(control))
        isValid = false;
    } else {
      if (control.markAsTouched)
        control.markAsTouched();
      else
        control.touched = true;

      if (control.invalid)
        isValid = false;
    }
  });
  return isValid;
};

export const getFormData = (formGroup) => {
  const data = {};
  Object.keys(formGroup).forEach((name) => {
    const field = formGroup[name];
    if (isFormGroup(field))
      data[field.name] = getFormData(field);
    else
      data[field.name] = field.value;
  });
  return data;
};

export const patchFormControl = (field, value) => {
  if (!field)
    return;

  if (field.patchValue) {
    field.patchValue(value);
  } else {
    field.value = value;
  }
};

export const patchFormData = (formGroup, data) => {
  if (!formGroup || !data)
    return;

  Object.keys(data).forEach((name) => {
    const field = formGroup[name];
    const value = data[name];
    if (isFormGroup(field))
      patchFormData(field, value);
    else
      patchFormControl(field, value);
  });
};

export const setFormControlOptions = (control, options) => {
  if (!control || !options)
    return;

  Object.assign(control, options);
  if (control.refresh)
    control.refresh();
};

export function getHtmlTextLength(description) {
  if (!description)
    return 0;
  return description.replace(/<[^<]*>/g, '').replace(/&\w+;/g, ' ').length;
}
