import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './style.scss';

import Dropzone from 'react-dropzone';
import { Button } from '@material-ui/core';
import { convertSvgFiles, getImagesFromClipboard } from '../../../utils';
import Dialog from '../../common/Dialog';

const ImageChangeModal = ({
  title, image, accept, placeholder, open, onChange, onClose,
}) => {
  const [file, setFile] = useState(null);
  const [changed, setChanged] = useState(false);
  const [errMsg, setErrMsg] = useState(null);

  useEffect(() => {
    if (image) {
      setFile({
        name: 'Drag and Drop / Click',
        type: image.type,
        preview: image.preview,
      });
    }
  }, [image]);

  const onDrop = async (files) => {
    files = await convertSvgFiles(files);
    setFile(files[0]);
    setErrMsg(null);
    setChanged(true);
  };

  const onPasteImage = (e) => {
    const images = getImagesFromClipboard(e);
    if (images.length) {
      onDrop(images).then();
    }
  };

  const onFinish = () => {
    if (!file || !changed) {
      setErrMsg(`Please select file to ${image ? 'change' : 'upload'}.`);
      return;
    }

    onChange(file);
    onClose();
  };

  const getPreview = () => {
    if (file?.type.startsWith('image')) {
      return (<img className="image" alt="Preview" src={file.preview} />);
    }
    if (file?.type.startsWith('video')) {
      // eslint-disable-next-line jsx-a11y/media-has-caption
      return (<video className="image" src={file.preview} controls />);
    }
    if (file?.type.startsWith('audio')) {
      // eslint-disable-next-line jsx-a11y/media-has-caption
      return (<audio className="image" src={file.preview} controls />);
    }
    return null;
  };

  return (
    <Dialog
      title={title}
      className="image-change-modal"
      open={open}
      onClose={onClose}
      onPaste={onPasteImage}
      footerActions={(
        <>
          <Button className="size-sm px-3 mr-2" onClick={onClose}>Cancel</Button>
          <Button className="btn-primary size-sm px-3" onClick={onFinish}>OK</Button>
        </>
      )}
    >
      <label className="text-gray text-sm">{`Select file to ${image ? 'change' : 'upload'}:`}</label>
      <Dropzone
        name="file"
        className="dropzone"
        accept={accept}
        onDrop={onDrop}
        multiple={false}
      >
        {
          file
            ? (
              <div className="preview">
                {getPreview()}

                <span className="file-info">
                  {file.name}
                </span>

                <span className="backdrop">
                  Click to change
                </span>
              </div>
            )
            : (
              <div className="dropify-message">
                <i className="fa fa-file-image-o" />
                <p>{placeholder}</p>
              </div>
            )
        }
      </Dropzone>

      {errMsg && (
        <div className="alert alert-danger mt-3 mb-0">
          {errMsg}
        </div>
      )}
    </Dialog>
  );
};

ImageChangeModal.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  image: PropTypes.object,
  accept: PropTypes.array,
  placeholder: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

ImageChangeModal.defaultProps = {
  title: 'Change Media',
  image: null,
  accept: ['image/*', 'video/*', 'audio/*'],
  placeholder: 'Drag and drop image or video here or click',
};

export default ImageChangeModal;
