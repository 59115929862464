import React, { useEffect, useMemo, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './style.scss';

import { Button, Grid } from '@material-ui/core';
import Dropzone from 'react-dropzone';
import * as PortfolioAction from '../../../store/actions/portfolio.action';
import * as PortfolioService from '../../../services/portfolio.service';
import * as ToastAction from '../../../store/actions/toast.action';
import * as CommonAction from '../../../store/actions/common.action';
import config from '../../../constants/config';
import { getImagesFromClipboard } from '../../../utils';
import Validators from '../../common/FormControl/validators';
import Dialog from '../../common/Dialog';
import FormControl from '../../common/FormControl';
import { patchFormData, validateFormControl, validateFormGroup } from '../../common/FormControl/utils';

const EditClientModal = ({
  open, clients, editingClient,
  onClose, addClient, editClient, createToast, startLoading, finishLoading,
}) => {
  const form = useMemo(() => ({
    name: {
      name: 'name',
      label: 'Client Name *',
      placeholder: 'Client name',
      validators: [Validators.required()],
    },
    website: {
      name: 'website',
      label: 'Client\'s Website',
      placeholder: 'Client\'s website',
      validators: [Validators.url()],
    },
    applyAsCompanyWebsite: {
      name: 'applyAsCompanyWebsite',
      label: 'Apply as company website',
      type: 'checkbox',
    },
    phone: {
      name: 'phone',
      label: 'Client\'s Phone',
      placeholder: 'Client\'s phone',
      validators: [],
    },
    applyAsCompanyPhone: {
      name: 'applyAsCompanyPhone',
      label: 'Apply as company phone',
      type: 'checkbox',
    },
  }), []);

  const [currentClient, setCurrentClient] = useState({
    name: '',
    website: '',
    applyAsCompanyWebsite: false,
    phone: '',
    applyAsCompanyPhone: false,
    logo: null,
    applyAsCompanyLogo: false,
  });

  useEffect(() => {
    const nameValidator = ({ value }) => {
      if (value.toLowerCase() === editingClient.name.toLowerCase())
        return null;

      const client = clients.find((p) => p.name.toLowerCase() === value.toLowerCase());
      if (client) {
        return `Client name already exists - ${client.name}`;
      }
      return null;
    };

    form.name.validators = [
      Validators.required(),
      nameValidator,
    ];
  }, [clients, editingClient.name]);

  useEffect(() => {
    setCurrentClient({
      name: editingClient.name || '',
      website: editingClient.website || '',
      applyAsCompanyWebsite: !!editingClient.applyAsCompanyWebsite,
      phone: editingClient.phone || '',
      applyAsCompanyPhone: !!editingClient.applyAsCompanyPhone,
      applyAsCompanyLogo: !!editingClient.applyAsCompanyLogo,
      logo: (editingClient.logo
        ? ({
          name: '_ORG_LOGO_',
          preview: `${config.CLIENT_URL}/${editingClient.logo}?q=${editingClient.updatedAt}`,
        })
        : null),
    });

    form.website.validators = editingClient.applyAsCompanyWebsite
      ? [Validators.required(), Validators.url()]
      : [Validators.url()];
    form.phone.validators = editingClient.applyAsCompanyPhone
      ? [Validators.required()]
      : [];
  }, [editingClient]);

  useEffect(() => {
    patchFormData(form, currentClient);
  }, [currentClient]);

  const onInputChange = (field, value) => {
    if (field === 'applyAsCompanyWebsite') {
      form.website.validators = value
        ? [Validators.required(), Validators.url()]
        : [Validators.url()];
      validateFormControl(form.website);
    }
    if (field === 'applyAsCompanyPhone') {
      form.phone.validators = value
        ? [Validators.required(), Validators.url()]
        : [Validators.url()];
      validateFormControl(form.phone);
    }

    setCurrentClient({
      ...currentClient,
      [field]: value,
    });
  };

  const onDrop = (field, files) => {
    setCurrentClient({
      ...currentClient,
      [field]: files ? files[0] : null,
    });
  };

  const onPasteImage = (e) => {
    const images = getImagesFromClipboard(e);
    if (images.length) {
      onDrop('logo', images);
    }
  };

  const onCreate = async () => {
    if (!validateFormGroup(form)) {
      return;
    }

    startLoading();
    const result = await PortfolioService.createClient({
      name: currentClient.name,
      website: currentClient.website,
      applyAsCompanyWebsite: currentClient.applyAsCompanyWebsite,
      phone: currentClient.phone,
      applyAsCompanyPhone: currentClient.applyAsCompanyPhone,
      applyAsCompanyLogo: currentClient.applyAsCompanyLogo,
    });
    finishLoading();

    if (result.error || !result.data.client) {
      createToast({
        message: result.message || 'Creating client has been failed',
        type: 'error',
      });
      return;
    }

    const { client } = result.data;

    if (currentClient.logo) {
      const logoName = currentClient.logo.name;
      const fileExt = logoName.slice(logoName.lastIndexOf('.'));
      client.logo = client._id + fileExt;
      startLoading();
      const res = await PortfolioService.setClientLogo(client._id, currentClient.logo, client.logo);
      finishLoading();
      if (res.error) {
        client.logo = null;
        createToast({
          message: res.message || 'Setting client logo has been failed',
          type: 'error',
        });
      }
    }

    addClient(client);
    createToast({
      message: 'New client has been created!',
      type: 'success',
    });
    onClose();
  };

  const onEdit = async () => {
    if (!validateFormGroup(form)) {
      return;
    }

    startLoading();
    const result = await PortfolioService.editClient({
      _id: editingClient._id,
      name: currentClient.name,
      website: currentClient.website,
      applyAsCompanyWebsite: currentClient.applyAsCompanyWebsite,
      phone: currentClient.phone,
      applyAsCompanyPhone: currentClient.applyAsCompanyPhone,
      applyAsCompanyLogo: currentClient.applyAsCompanyLogo,
    });
    finishLoading();
    if (result.error || !result.data.client) {
      createToast({
        message: result.message || 'Editing client has been failed',
        type: 'error',
      });
      return;
    }

    const { client } = result.data;

    if (currentClient.logo && currentClient.logo.name !== '_ORG_LOGO_') {
      const logoName = currentClient.logo.name;
      const fileExt = logoName.slice(logoName.lastIndexOf('.'));
      client.logo = client._id + fileExt;
      startLoading();
      const res = await PortfolioService.setClientLogo(client._id, currentClient.logo, client.logo);
      finishLoading();
      if (res.error) {
        client.logo = null;
        createToast({
          message: res.message || 'Setting client logo has been failed.',
          type: 'error',
        });
      }
    }
    if (!currentClient.logo && editingClient.logo) {
      startLoading();
      const res = await PortfolioService.removeClientLogo(client._id);
      finishLoading();
      if (res.error) {
        createToast({
          message: res.message || 'Removing client logo has been failed.',
          type: 'error',
        });
      } else {
        client.logo = null;
      }
    }

    editClient(client);
    createToast({
      message: 'Clients has been edited successfully.',
      type: 'success',
    });
    onClose();
  };

  return (
    <Dialog
      title={editingClient._id ? 'Edit Client' : 'Create Client'}
      open={open}
      className="create-client-modal"
      onClose={onClose}
      onPaste={onPasteImage}
      footerActions={(
        <>
          <Button className="size-sm px-3 mr-2" onClick={onClose}>Cancel</Button>
          {
            editingClient._id
              ? <Button className="btn-primary size-sm px-3" onClick={onEdit}>Save</Button>
              : <Button className="btn-primary size-sm px-3" onClick={onCreate}>Create</Button>
          }
        </>
      )}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl control={form.name} size="sm" className="mb-0" onChange={onInputChange} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl control={form.website} size="sm" className="mb-0" onChange={onInputChange} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl control={form.applyAsCompanyWebsite} size="sm" className="mb-0" onChange={onInputChange} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl control={form.phone} size="sm" className="mb-0" onChange={onInputChange} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl control={form.applyAsCompanyPhone} size="sm" className="mb-0" onChange={onInputChange} />
        </Grid>
        <Grid item xs={12}>
          <div className="client-logo">
            <label className="text-gray text-sm mb-2">Client Logo</label>
            <Dropzone
              name="file"
              className="dropzone"
              accept="image/*"
              multiple={false}
              onDrop={(files) => onDrop('logo', files)}
            >
              {
                editingClient._id || currentClient.logo
                  ? (
                    <div className="preview">
                      {
                        currentClient.logo
                          ? <img className="image" alt={currentClient.logo.name} src={currentClient.logo.preview} />
                          : <img className="image" alt="" src={`${config.ASSETS_URL}/images/protovate.png`} />
                      }
                      <div className="backdrop">Change Logo</div>
                    </div>
                  )
                  : (
                    <div className="dropify-message">
                      <i className="fa fa-file-image-o" />
                      <p>Drag and drop logo here or click</p>
                    </div>
                  )
              }
            </Dropzone>
            {
              currentClient.logo
                ? (
                  <div className="text-center mt-1">
                    <span className="text-primary text-sm hover-underline cursor-pointer" onClick={() => onInputChange('logo', null)}>Remove</span>
                  </div>
                )
                : null
            }
          </div>
        </Grid>
      </Grid>
    </Dialog>
  );
};

EditClientModal.propTypes = {
  open: PropTypes.bool,
  editingClient: PropTypes.object.isRequired,
  clients: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  addClient: PropTypes.func.isRequired,
  editClient: PropTypes.func.isRequired,
  createToast: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
};

EditClientModal.defaultProps = {
  open: false,
  clients: [],
};

const mapStateToProps = (store) => ({
  clients: store.portfolioReducer.clients,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addClient: PortfolioAction.addClient,
      editClient: PortfolioAction.editClient,
      createToast: ToastAction.createToast,
      startLoading: CommonAction.startLoading,
      finishLoading: CommonAction.finishLoading,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EditClientModal);
