import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import './style.scss';

import MaterialTable from 'material-table';
import CaseStudyTableActions from '../TableActions';
import LogViewModal from '../LogViewModal';
import tableIcons from '../../../constants/TableIcons';
import ROUTES from '../../../constants/routes.json';
import * as PortfolioAction from '../../../store/actions/portfolio.action';

const CaseStudiesTable = ({
  caseStudies, setActiveProject, setActiveTab,
}) => {
  const history = useHistory();
  const [viewLog, setViewLog] = useState(null);

  const onGotoDetailPage = (project) => {
    setActiveProject(project);
    setActiveTab('screenshot');
    history.push(ROUTES.PROJECTS.url);
  };

  return (
    <div className="case-studies-table mt-3">
      <MaterialTable
        icons={tableIcons}
        columns={[
          {
            title: 'No',
            field: 'no',
            width: '50px',
          },
          {
            title: 'Project',
            field: 'projectName',
            render: (data) => (
              <span className="text-link" onClick={() => onGotoDetailPage(data.project)}>{data.projectName}</span>
            ),
            width: '150px',
          },
          {
            title: 'Case Study Title',
            field: 'title',
            emptyValue: '--------',
            width: '300px',
          },
          {
            title: 'Status',
            field: 'active',
            render: (data) => (
              data.active
                ? <span className="text-success">Active</span>
                : <span className="text-danger">Draft</span>
            ),
            width: '70px',
          },
          {
            title: 'Change Log',
            sorting: false,
            render: (data) => (
              data.history && data.history.length
                ? <span className="text-primary link" onClick={() => setViewLog(data)}>View</span>
                : '--------'
            ),
            width: '110px',
          },
          {
            title: 'Action',
            render: (data) => (
              <CaseStudyTableActions caseStudy={data} />
            ),
            cellStyle: { textAlign: 'center' },
            headerStyle: { textAlign: 'center' },
            sorting: false,
            width: '230px',
          },
        ]}
        data={caseStudies}
        options={{
          pageSize: 10,
          pageSizeOptions: [5, 10, 20, 50, 100],
          headerStyle: { fontWeight: 'bold' },
        }}
      />

      {viewLog && (
        <LogViewModal
          caseStudy={viewLog}
          open
          onClose={() => setViewLog(null)}
        />
      )}
    </div>
  );
};

CaseStudiesTable.propTypes = {
  caseStudies: PropTypes.array,
  setActiveProject: PropTypes.func.isRequired,
  setActiveTab: PropTypes.func.isRequired,
};

CaseStudiesTable.defaultProps = {
  caseStudies: [],
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setActiveProject: PortfolioAction.setActiveProject,
      setActiveTab: PortfolioAction.setActiveTab,
    },
    dispatch,
  );
}

export default connect(null, mapDispatchToProps)(CaseStudiesTable);
