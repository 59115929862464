import ACTION from '../actionTypes';

export function signIn(user) {
  return {
    type: ACTION.SIGN_IN,
    user,
  };
}

export function signOut() {
  return {
    type: ACTION.SIGN_OUT,
  };
}

export function setUser(user) {
  return {
    type: ACTION.SET_USER,
    user,
  };
}
