import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import './style.scss';

import { Button } from '@material-ui/core';
import Dialog from '../../common/Dialog';
import FormControl from '../../common/FormControl';
import * as PortfolioAction from '../../../store/actions/portfolio.action';
import * as PortfolioService from '../../../services/portfolio.service';
import * as ToastAction from '../../../store/actions/toast.action';
import * as CommonAction from '../../../store/actions/common.action';
import Validators from '../../common/FormControl/validators';
import { patchFormData, setFormControlOptions, validateFormGroup } from '../../common/FormControl/utils';

const ProjectMoveModal = ({
  open, onClose, clients, project, moveProject, startLoading, finishLoading, createToast,
}) => {
  const form = useMemo(() => ({
    currentClient: {
      name: 'currentClient',
      label: 'Current Client',
      disabled: true,
    },
    project: {
      name: 'project',
      type: 'autocomplete',
      label: 'Select project to move',
      options: [],
      labelField: 'name',
      validators: [Validators.required()],
    },
    client: {
      name: 'client',
      type: 'autocomplete',
      label: 'Select new client',
      options: [],
      labelField: 'name',
      validators: [Validators.required()],
    },
  }), []);

  const client = useMemo(() => (
    clients.find((c) => c._id === project.client)
  ), [clients, project]);

  useEffect(() => {
    patchFormData(form, {
      currentClient: client.name,
      project,
    });
  }, [client.name, form, project]);

  useEffect(() => {
    setFormControlOptions(form.client, {
      options: clients.filter((c) => c._id !== project.client),
    });
  }, [clients, form, project.client]);

  useEffect(() => {
    setFormControlOptions(form.project, {
      options: client.projects,
    });
  }, [client.projects, form.project]);

  const onMove = async () => {
    if (!validateFormGroup(form))
      return;

    const source = form.project.value;
    const target = form.client.value;

    startLoading();
    const result = await PortfolioService.moveProject(source._id, target._id);
    finishLoading();

    if (result.error) {
      createToast({
        type: 'error',
        title: 'Failed!',
        message: result.message || 'Move project has been failed.',
      });
    } else {
      moveProject(source, target);
      createToast({
        type: 'success',
        message: `${source.name} been moved to ${target.name} successfully.`,
      });
      onClose();
    }
  };

  return (
    <Dialog
      title="Move Project"
      className="move-project-modal"
      open={open}
      onClose={onClose}
      footerActions={(
        <>
          <Button className="size-sm px-3 mr-2" onClick={onClose}>Cancel</Button>
          <Button className="btn-primary size-sm px-3" onClick={onMove}>Move</Button>
        </>
      )}
    >
      <FormControl control={form.currentClient} size="sm" />
      <FormControl control={form.project} size="sm" />
      <FormControl control={form.client} size="sm" className="mb-0" />
    </Dialog>
  );
};

ProjectMoveModal.propTypes = {
  open: PropTypes.bool,
  clients: PropTypes.array,
  project: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  moveProject: PropTypes.func.isRequired,
  createToast: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
};

ProjectMoveModal.defaultProps = {
  open: false,
  clients: [],
  onClose: null,
};

const mapStateToProps = (store) => ({
  clients: store.portfolioReducer.clients,
  client: store.portfolioReducer.client,
  projects: store.portfolioReducer.projects,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      moveProject: PortfolioAction.moveProject,
      createToast: ToastAction.createToast,
      startLoading: CommonAction.startLoading,
      finishLoading: CommonAction.finishLoading,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectMoveModal);
