import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ROUTES from '../constants/routes.json';
import './SimpleLayout.scss';

export default function SimpleLayout({ children: content }) {
  return (
    <div className="simple-layout">
      <div className="layout-header p-4">
        <Link className="logo" to={ROUTES.PROJECTS.url}>
          <img className="logo" src="/resources/images/logo.png" alt="Portfolio Tool" />
        </Link>
      </div>
      <div className="layout-content p-3">
        {content}
      </div>
    </div>
  );
}

SimpleLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]).isRequired,
};
