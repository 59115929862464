import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import GridViewItem from './GridViewItem';
import './style.scss';

const ImagesGridView = ({
  images, isGlobalSearch, onRemoveMedia,
}) => {
  const items = useMemo(() => (
    images.map((image) => {
      if (isGlobalSearch) {
        return {
          ...image,
          section: `${image.project.name} / ${image.section}`,
        };
      }
      return image;
    })
  ), [images, isGlobalSearch]);

  return (
    <div className="images-grid-view">
      {
        items.map((image) => (
          <GridViewItem key={image._id} image={image} onRemoveMedia={onRemoveMedia} />
        ))
      }
    </div>
  );
};

ImagesGridView.propTypes = {
  images: PropTypes.array.isRequired,
  isGlobalSearch: PropTypes.bool,
  onRemoveMedia: PropTypes.func,
};

ImagesGridView.defaultProps = {
  isGlobalSearch: false,
  onRemoveMedia: null,
};

const mapStateToProps = (store) => ({
  isGlobalSearch: store.portfolioReducer.isGlobalSearch,
});

export default connect(mapStateToProps)(ImagesGridView);
