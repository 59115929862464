import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './SearchBox.scss';

import { Accordion, AccordionDetails, AccordionSummary, Button, Grid } from '@material-ui/core';
import { Clear, KeyboardArrowRight } from '@material-ui/icons';
import * as PortfolioAction from '../../../../store/actions/portfolio.action';
import * as PortfolioService from '../../../../services/portfolio.service';
import * as ToastAction from '../../../../store/actions/toast.action';
import * as CommonAction from '../../../../store/actions/common.action';
import { getFilteredImages } from '../../../../utils';
import FormControl from '../../../common/FormControl';

const SearchBox = ({
  project, tags, selectedImages, filteredImages,
  setSelectedImages, setFilteredImages, createToast, startLoading, finishLoading,
}) => {
  const form = useMemo(() => ({
    section: {
      name: 'section',
      type: 'autocomplete',
      label: 'Section',
      placeholder: 'Select section',
      options: [],
    },
    subSection: {
      name: 'subSection',
      type: 'autocomplete',
      label: 'Sub Section',
      placeholder: 'Select sub section',
      options: [],
    },
    tags: {
      name: 'tags',
      type: 'autocomplete',
      label: 'Tags',
      placeholder: 'Add tags',
      value: [],
      options: [],
      controlOptions: {
        multiple: true,
      },
    },
    globalSearch: {
      name: 'globalSearch',
      type: 'checkbox',
      label: 'Global Search',
    },
    selectAll: {
      name: 'selectAll',
      type: 'checkbox',
      label: 'Select/Deselect All',
    },
  }), []);

  const [globalInfo, setGlobalInfo] = useState({
    sections: [],
    subSections: [],
  });
  const [isGlobalSearch, setGlobalSearch] = useState(false);

  const isFilterSet = form.section.value || form.subSection.value || form.tags.value.length || isGlobalSearch;

  useEffect(() => {
    PortfolioService.getGlobalSearchInfo().then((result) => {
      if (result.error) {
        createToast({
          message: result.message || 'Loading global sections has been failed.',
          type: 'error',
        });
      } else {
        setGlobalInfo({
          sections: result.data.sections.filter((section) => !!section),
          subSections: result.data.subSections.filter((subSection) => !!subSection),
        });
      }
    });
  }, [createToast]);

  useEffect(() => {
    const isSelectedAll = !filteredImages.some((image) => (
      !selectedImages.some((selectedImg) => selectedImg._id === image._id)
    ));
    form.selectAll.patchValue(isSelectedAll);
  }, [filteredImages, selectedImages]);

  const projectInfo = useMemo(() => {
    let sections = [];
    let subSections = [];
    if (project) {
      sections = [...new Set(project.images.map((image) => image.section))].filter((section) => !!section);
      subSections = [...new Set(project.images.map((image) => image.subSection))].filter((subSection) => !!subSection);
    }
    return {
      sections,
      subSections,
    };
  }, [project]);

  useEffect(() => {
    const sections = isGlobalSearch ? globalInfo.sections : projectInfo.sections;
    Object.assign(form.section, { options: sections });
  }, [globalInfo.sections, isGlobalSearch, projectInfo.sections]);

  useEffect(() => {
    const subSections = isGlobalSearch ? globalInfo.subSections : projectInfo.subSections;
    Object.assign(form.subSection, { options: subSections });
  }, [globalInfo.subSections, isGlobalSearch, projectInfo.subSections]);

  useEffect(() => {
    let scopedTags = [];
    if (isGlobalSearch) {
      scopedTags = tags;
    } else if (project) {
      (project.images || []).forEach((image) => {
        image.tags.forEach((tag) => {
          if (!scopedTags.find((t) => t._id === tag._id)) {
            scopedTags.push(tag);
          }
        });
      });
    }

    scopedTags = scopedTags.sort((tag1, tag2) => tag2.cnt - tag1.cnt).map((tag) => tag.name);
    Object.assign(form.tags, { options: scopedTags });
  }, [isGlobalSearch, project, tags]);

  const onSearch = useCallback(async () => {
    if (form.globalSearch.value) {
      startLoading();
      const result = await PortfolioService.globalSearch(form.section.value, form.subSection.value, form.tags.value);
      finishLoading();
      if (result.error) {
        createToast({
          message: result.message || 'Search has been failed.',
          type: 'error',
        });
      } else {
        setFilteredImages(result.images);
      }
    } else {
      const images = getFilteredImages(project, form.section.value, form.subSection.value, form.tags.value);
      setFilteredImages(images);
    }
  }, [createToast, finishLoading, project, setFilteredImages, startLoading]);

  useEffect(() => {
    onSearch().then();
  }, []);

  const onResetQuery = useCallback((e) => {
    if (e) {
      e.stopPropagation();
    }

    form.section.patchValue(null);
    form.subSection.patchValue(null);
    form.tags.patchValue([]);
    form.globalSearch.patchValue(false);
    setGlobalSearch(false);

    onSearch().then();
  }, [onSearch]);

  useEffect(() => {
    onResetQuery();
  }, [onResetQuery, project]);

  const onToggleGlobalSearch = () => {
    setGlobalSearch(form.globalSearch.value);
    onSearch().then();
  };

  const onToggleSelectAll = (field, checked) => {
    let images = selectedImages.filter((selectedImg) => !filteredImages.find((image) => image._id === selectedImg._id));
    if (checked) {
      images = [
        ...images,
        ...filteredImages.map((image) => (
          {
            ...image,
            project: typeof image.project === 'string' ? project : image.project,
          }
        )),
      ];
    }
    setSelectedImages(images);
  };

  return (
    <Accordion className="search-box">
      <AccordionSummary
        expandIcon={<KeyboardArrowRight />}
      >
        <div className="d-flex align-items-center">
          <h6 className="text-uppercase">Filter</h6>
          <Button
            className="size-sm ml-3"
            disabled={!isFilterSet}
            onClick={onResetQuery}
            onFocus={(event) => event.stopPropagation()}
          >
            <Clear className="text-sm mr-2" />
            Reset all
          </Button>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="w-100">
          <Grid container spacing={2}>
            <Grid item md={4} sm={6}>
              <FormControl control={form.section} size="sm" onChange={onSearch} />
            </Grid>
            <Grid item md={4} sm={6}>
              <FormControl control={form.subSection} size="sm" onChange={onSearch} />
            </Grid>
            <Grid item md={4} sm={12}>
              <FormControl control={form.tags} size="sm" onChange={onSearch} />
            </Grid>
          </Grid>
          <Grid container>
            <FormControl control={form.globalSearch} fullWidth={false} size="sm" onChange={onToggleGlobalSearch} />
            <FormControl control={form.selectAll} fullWidth={false} size="sm" className="ml-3" onChange={onToggleSelectAll} />
          </Grid>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

SearchBox.propTypes = {
  project: PropTypes.object.isRequired,
  tags: PropTypes.array,
  selectedImages: PropTypes.array,
  filteredImages: PropTypes.array,
  setSelectedImages: PropTypes.func.isRequired,
  setFilteredImages: PropTypes.func.isRequired,
  createToast: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
};

SearchBox.defaultProps = {
  tags: [],
  selectedImages: [],
  filteredImages: [],
};

const mapStateToProps = (store) => ({
  tags: store.portfolioReducer.tags,
  selectedImages: store.portfolioReducer.selectedImages,
  filteredImages: store.portfolioReducer.filteredImages,
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators(
    {
      setSelectedImages: PortfolioAction.setSelectedImages,
      setFilteredImages: PortfolioAction.setFilteredImages,
      createToast: ToastAction.createToast,
      startLoading: CommonAction.startLoading,
      finishLoading: CommonAction.finishLoading,
    },
    dispatch,
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(SearchBox);
