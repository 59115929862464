import React, { useEffect, useMemo, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './style.scss';

import Dropzone from 'react-dropzone';
import { Button, Grid } from '@material-ui/core';
import * as PortfolioAction from '../../../store/actions/portfolio.action';
import * as PortfolioService from '../../../services/portfolio.service';
import * as ToastAction from '../../../store/actions/toast.action';
import * as CommonAction from '../../../store/actions/common.action';
import config from '../../../constants/config';
import { getImagesFromClipboard } from '../../../utils';
import Dialog from '../../common/Dialog';
import Validators from '../../common/FormControl/validators';
import { patchFormData, validateFormGroup } from '../../common/FormControl/utils';
import FormControl from '../../common/FormControl';

const EditContactModal = ({
  open, contact, onClose, addContact, editContact, startLoading, finishLoading, createToast,
}) => {
  const form = useMemo(() => ({
    primary: {
      name: 'primary',
      label: 'Primary Contact *',
      placeholder: 'Primary contact',
      validators: [Validators.required()],
    },
    email: {
      name: 'email',
      label: 'Email Address *',
      placeholder: 'Email address',
      validators: [Validators.required(), Validators.email()],
    },
    phone: {
      name: 'phone',
      label: 'Phone Number *',
      placeholder: 'Phone number',
      validators: [Validators.required(), Validators.pattern(/^\d+$/)],
    },
    website: {
      name: 'website',
      label: 'Website',
      placeholder: 'Website',
      validators: [Validators.url()],
    },
    linkedIn: {
      name: 'linkedIn',
      label: 'Linked In',
      placeholder: 'Linked In',
    },
    reference: {
      name: 'reference',
      type: 'summer-note',
      label: 'Reference',
      maxLength: 400,
      validators: [Validators.maxLength(400, true)],
    },
  }), []);

  const [currentContact, setCurrentContact] = useState({
    primary: '',
    email: '',
    phone: '',
    website: '',
    linkedIn: '',
    image: null,
  });
  const [reference, setReference] = useState(null);

  useEffect(() => {
    patchFormData(form, currentContact);
  }, [currentContact, form]);

  useEffect(() => {
    setCurrentContact({
      primary: contact.primary,
      email: contact.email,
      phone: contact.phone,
      website: contact.website,
      linkedIn: contact.linkedIn,
      reference: contact.reference,
      image: (contact.image
        ? ({
          name: 'Image',
          preview: contact.preview || `${config.CONTACT_URL}/${contact.image}`,
        })
        : null),
    });
    setReference(contact.reference);
  }, [contact]);

  const onInputChange = (field, value) => {
    setCurrentContact({
      ...currentContact,
      [field]: value,
    });
  };

  const onDrop = (files) => {
    onInputChange('image', files ? files[0] : null);
  };

  const onPasteImage = (e) => {
    const images = getImagesFromClipboard(e);
    if (images.length) {
      onDrop(images);
    }
  };

  const onCreate = () => {
    if (!validateFormGroup(form)) {
      return;
    }

    const {
      primary, email, phone, website, linkedIn, image,
    } = currentContact;

    startLoading();
    PortfolioService.createContact({
      primary,
      email,
      phone,
      website,
      linkedIn,
      reference,
    }).then((result) => {
      finishLoading();
      if (result.error) {
        createToast({
          message: result.message || 'Creating contact has been failed',
          type: 'error',
        });
      } else {
        const { contact } = result.data;

        if (image) {
          contact.image = `${contact._id}.png`;
          startLoading();
          PortfolioService.setContactImage(contact._id, image, contact.image).then((result) => {
            finishLoading();
            if (result.error) {
              createToast({
                message: result.message || 'Setting contact image has been failed',
                type: 'error',
              });
            } else {
              addContact(contact);
              createToast({
                message: 'New contact has been created!',
                type: 'success',
              });
            }
            onClose();
          });
        } else {
          addContact(contact);
          createToast({
            message: 'New contact has been created!',
            type: 'success',
          });
          onClose();
        }
      }
    });
  };

  const onEditFinished = (contact) => {
    editContact(contact);
    createToast({
      message: 'Contact has been edited successfully.',
      type: 'success',
    });
    onClose();
  };

  const onEdit = () => {
    if (!validateFormGroup(form)) {
      return;
    }

    const {
      primary, email, phone, website, linkedIn, image,
    } = currentContact;

    const editingContact = contact;

    startLoading();
    PortfolioService.editContact({
      _id: editingContact._id,
      primary,
      email,
      phone,
      website,
      linkedIn,
      reference,
    }).then((result) => {
      finishLoading();
      if (result.error) {
        createToast({
          message: result.message || 'Editing contact has been failed',
          type: 'error',
        });
      } else {
        const { contact } = result.data;

        if (image && image.name !== 'Image') {
          contact.image = `${contact._id}.png`;
          startLoading();
          PortfolioService.setContactImage(contact._id, image, contact.image).then((result) => {
            finishLoading();
            if (result.error) {
              createToast({
                message: result.message || 'Setting contact image has been failed.',
                type: 'error',
              });
            } else {
              contact.preview = image.preview;
              onEditFinished(contact);
            }
          });
        } else if (!image && editingContact.image) {
          startLoading();
          PortfolioService.removeContactImage(contact._id).then((result) => {
            finishLoading();
            if (result.error) {
              createToast({
                message: result.message || 'Removing contact image has been failed.',
                type: 'error',
              });
            } else {
              contact.image = null;
              onEditFinished(contact);
            }
          });
        } else {
          onEditFinished(contact);
        }
      }
    });
  };

  return (
    <Dialog
      title={contact._id ? 'Edit Contact' : 'Create Contact'}
      className="edit-contact-modal"
      open={open}
      onClose={onClose}
      onPaste={onPasteImage}
      footerActions={(
        <>
          <Button className="size-sm px-3 mr-2" onClick={onClose}>Cancel</Button>
          {
            contact._id
              ? <Button className="btn-primary size-sm px-3" onClick={onEdit}>Save</Button>
              : <Button className="btn-primary size-sm px-3" onClick={onCreate}>Create</Button>
          }
        </>
      )}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} className="d-flex flex-column">
          <label className="text-gray text-sm mb-2">Contact Image</label>
          <Dropzone
            name="file"
            className="dropzone flex-grow-1"
            accept="image/*"
            multiple={false}
            onDrop={onDrop}
          >
            {
              currentContact.image
                ? (
                  <div className="preview h-100">
                    <img className="image mh-100" alt={currentContact.image.name} src={currentContact.image.preview} />
                    <div className="backdrop">Change Image</div>
                  </div>
                )
                : (
                  <div className="dropify-message">
                    <i className="fa fa-file-image-o" />
                    <p>Drag and drop image here or click</p>
                  </div>
                )
            }
          </Dropzone>
          {Boolean(currentContact.image) && (
            <div className="text-center">
              <span className="text-sm hover-underline cursor-pointer" onClick={() => onInputChange('image', null)}>Remove</span>
            </div>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl control={form.primary} size="sm" onChange={onInputChange} />
          <FormControl control={form.email} size="sm" onChange={onInputChange} />
          <FormControl control={form.phone} size="sm" className="mb-0" onChange={onInputChange} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl control={form.website} size="sm" className="mb-0" onChange={onInputChange} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl control={form.linkedIn} size="sm" className="mb-0" onChange={onInputChange} />
        </Grid>
        <Grid item xs={12}>
          <FormControl control={form.reference} size="sm" className="mb-0" onChange={(_, value) => setReference(value)} />
        </Grid>
      </Grid>
    </Dialog>
  );
};

EditContactModal.propTypes = {
  open: PropTypes.bool.isRequired,
  contact: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  addContact: PropTypes.func.isRequired,
  editContact: PropTypes.func.isRequired,
  createToast: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addContact: PortfolioAction.addContact,
      editContact: PortfolioAction.editContact,
      createToast: ToastAction.createToast,
      startLoading: CommonAction.startLoading,
      finishLoading: CommonAction.finishLoading,
    },
    dispatch,
  );
}

export default connect(null, mapDispatchToProps)(EditContactModal);
