export default {
  SIGN_IN: 'SIGN_IN',
  SIGN_OUT: 'SIGN_OUT',
  SET_USER: 'SET_USER',
  SET_CLIENT: 'SET_CLIENT',
  SET_TAGS: 'SET_TAGS',
  SET_CLIENTS: 'SET_CLIENTS',
  ADD_CLIENT: 'ADD_CLIENT',
  EDIT_CLIENT: 'EDIT_CLIENT',
  ADD_PROJECT: 'ADD_PROJECT',
  EDIT_PROJECT: 'EDIT_PROJECT',
  SET_ACTIVE_PROJECT: 'SET_ACTIVE_PROJECT',
  REMOVE_PROJECT: 'REMOVE_PROJECT',
  SET_ACTIVE_TAB: 'SET_ACTIVE_TAB',
  TOGGLE_SIDE_BAR: 'TOGGLE_SIDE_BAR',
  SHOW_SIDE_BAR: 'SHOW_SIDE_BAR',
  SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
  SET_UPLOADING_INFO: 'SET_UPLOADING_INFO',
  GO_TO_NEXT_IMAGE: 'GO_TO_NEXT_IMAGE',
  GO_TO_PREV_IMAGE: 'GO_TO_PREV_IMAGE',
  ADD_MEDIA: 'ADD_MEDIA',
  EDIT_MEDIA: 'EDIT_MEDIA',
  REMOVE_MEDIA: 'REMOVE_MEDIA',
  REMOVE_MEDIAS: 'REMOVE_MEDIAS',
  SET_EDITING_IMAGE: 'SET_EDITING_IMAGE',
  DELETE_TOAST: 'DELETE_TOAST',
  CREATE_TOAST: 'CREATE_TOAST',
  PREVIEW_IMAGE: 'PREVIEW_IMAGE',
  START_LOADING: 'START_LOADING',
  FINISH_LOADING: 'FINISH_LOADING',
  FINISH_ALL_LOADING: 'FINISH_ALL_LOADING',
  SET_EDITING_PROJECT: 'SET_EDITING_PROJECT',
  TOGGLE_IMAGE_SELECT: 'TOGGLE_IMAGE_SELECT',
  SET_SELECTED_IMAGES: 'SET_SELECTED_IMAGES',
  SET_FILTERED_IMAGES: 'SET_FILTERED_IMAGES',
  SET_CONTACTS: 'SET_CONTACTS',
  ADD_CONTACT: 'ADD_CONTACT',
  EDIT_CONTACT: 'EDIT_CONTACT',
  REMOVE_CONTACT: 'REMOVE_CONTACT',
  SET_EDITING_CONTACT: 'SET_EDITING_CONTACT',
  REORDER_MEDIAS: 'REORDER_MEDIAS',
  START_UPLOADING: 'START_UPLOADING',
  FINISH_UPLOADING: 'FINISH_UPLOADING',
  SET_UPLOADING_PROGRESS: 'SET_UPLOADING_PROGRESS',
  SET_CASE_STUDY: 'SET_CASE_STUDY',
  ADD_LIVE_LINK: 'ADD_LIVE_LINK',
  EDIT_LIVE_LINK: 'EDIT_LIVE_LINK',
  REMOVE_LIVE_LINK: 'REMOVE_LIVE_LINK',
  SET_GLOBAL_SEARCH: 'SET_GLOBAL_SEARCH',
  MASS_EDIT_MEDIAS: 'MASS_EDIT_MEDIAS',
  MASS_MOVE_IMAGES: 'MASS_MOVE_IMAGES',
  SHOW_EDIT_PANEL: 'SHOW_EDIT_PANEL',
  SET_HAS_UNSAVED_DATA: 'SET_HAS_UNSAVED_DATA',
  SET_CAPTURED_IMAGES: 'SET_CAPTURED_IMAGES',
  MOVE_PROJECT: 'MOVE_PROJECT',
  SET_WEEKLY_REPORTED_PROJECTS: 'SET_WEEKLY_REPORTED_PROJECTS',
  ADD_WEEKLY_REPORTED_PROJECT: 'ADD_WEEKLY_REPORTED_PROJECT',
  REMOVE_WEEKLY_REPORTED_PROJECT: 'REMOVE_WEEKLY_REPORTED_PROJECT',
  SET_PROJECT_FILTER: 'SET_PROJECT_FILTER',
};
