import React from 'react';
import {
  Route, Switch, withRouter, Redirect,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SnackbarProvider } from 'notistack';

import AuthLayout from 'layout/AuthLayout';
import NotFound from 'containers/NotFound';
import PublicRoute from 'routes/PublicRoute';
import ScaleLoader from 'react-spinners/ScaleLoader';
import UploadingNow from './components/common/UploadingNow';

import ROUTES from './constants/routes.json';
import ToastView from './components/common/ToastVIew';
import PrivateRoute from './routes/PrivateRoute';
import SignIn from './containers/SignIn';
import ForgotPassword from './containers/ForgotPassword';
import ResetPassword from './containers/ResetPassword';
import ConfirmEmail from './containers/ConfirmEmail';
import ActivateAccount from './containers/ActivateAccount';
import FullLayout from './layout/FullLayout';
import Projects from './containers/Projects';
import ImageView from './containers/ImageView';
import SharePage from './containers/Share';
import RecentImages from './containers/RecentImages';
import SharedCaseStudy from './containers/SharedCaseStudy';
import CaseStudies from './containers/CaseStudies';
import SharedProgress from './containers/SharedProgress';
import SimpleLayout from './layout/SimpleLayout';
import ProjectScrum from './containers/ProjectScrum';
import SharedReport from './containers/SharedReport';

const App = ({
  uploading, progress, uploadMessage, loadingCnt, ...rest
}) => (
  <SnackbarProvider maxSnack={3}>
    <Switch>
      <Route
        exact
        path="/"
        render={() => (<Redirect to={ROUTES.PROJECTS.url} />)}
      />

      <PublicRoute
        path={ROUTES.SIGN_IN.url}
        layout={AuthLayout}
        component={SignIn}
        isAuthPage
        props={rest}
      />

      <PublicRoute
        path={ROUTES.FORGOT_PASSWORD.url}
        layout={AuthLayout}
        component={ForgotPassword}
        isAuthPage
        props={rest}
      />

      <PublicRoute
        path={ROUTES.RESET_PASSWORD.url}
        layout={AuthLayout}
        component={ResetPassword}
        isAuthPage
        props={rest}
      />

      <PublicRoute
        path={ROUTES.CONFIRM_EMAIL.url}
        layout={AuthLayout}
        component={ConfirmEmail}
        isAuthPage
        props={rest}
      />

      <PublicRoute
        path={ROUTES.ACTIVATE_ACCOUNT.url}
        layout={AuthLayout}
        component={ActivateAccount}
        isAuthPage
        props={rest}
      />

      <PrivateRoute
        path={ROUTES.PROJECTS.url}
        layout={FullLayout}
        component={Projects}
        props={rest}
      />

      <PrivateRoute
        path={ROUTES.CASE_STUDIES.url}
        layout={FullLayout}
        component={CaseStudies}
        props={rest}
      />

      <PublicRoute
        path={ROUTES.IMAGE_VIEW.url}
        layout={SimpleLayout}
        component={ImageView}
        props={rest}
      />

      <Route
        path={ROUTES.SHARE_PAGE.url}
        component={SharePage}
      />

      <Route
        path={ROUTES.SHARED_CASE_STUDY.url}
        component={SharedCaseStudy}
      />

      <PublicRoute
        path={ROUTES.RECENT_IMAGES.url}
        layout={SimpleLayout}
        component={RecentImages}
        props={rest}
      />

      <PublicRoute
        path={ROUTES.SHARED_PROGRESS.url}
        layout={SimpleLayout}
        component={SharedProgress}
        props={rest}
      />

      <PrivateRoute
        path={ROUTES.PROJECT_SCRUM.url}
        layout={SimpleLayout}
        component={ProjectScrum}
        props={rest}
      />

      <PublicRoute
        path={ROUTES.SHARED_REPORT.url}
        layout={SimpleLayout}
        component={SharedReport}
        props={rest}
      />

      <Route component={NotFound} />
    </Switch>

    <ToastView />

    {
      loadingCnt
        ? (
          <div className="spinner">
            <ScaleLoader loading color="#1c3943" />
          </div>
        )
        : null
    }
    <UploadingNow
      loading={uploading}
      progress={progress}
      uploadMessage={uploadMessage}
    />
  </SnackbarProvider>
);

App.propTypes = {
  uploading: PropTypes.bool,
  progress: PropTypes.number,
  uploadMessage: PropTypes.string,
  loadingCnt: PropTypes.number,
};

App.defaultProps = {
  uploading: false,
  progress: 0,
  uploadMessage: '',
  loadingCnt: 0,
};

const mapStateToProps = (store) => ({
  loadingCnt: store.commonReducer.loadingCnt,
  uploading: store.commonReducer.uploading,
  progress: store.commonReducer.progress,
  uploadMessage: store.commonReducer.uploadMessage,
});

export default connect(mapStateToProps)(withRouter(App));
