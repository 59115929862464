import React from 'react';
import './style.scss';
import PropTypes from 'prop-types';

const ToggleBtn = ({ className, onClick }) => (
  <button className={`toggle-icon text-muted ${className}`} onClick={onClick}>
    <i className="fa fa-bars" />
  </button>
);

ToggleBtn.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

ToggleBtn.defaultProps = {
  className: '',
  onClick: null,
};

export default ToggleBtn;
