import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './style.scss';

import Reorder, { reorder } from 'react-reorder';
import Modal from '../../common/Modal';
import * as ToastAction from '../../../store/actions/toast.action';
import * as PortfolioAction from '../../../store/actions/portfolio.action';
import * as PortfolioService from '../../../services/portfolio.service';
import * as CommonAction from '../../../store/actions/common.action';
import config from '../../../constants/config';

const ExportModal = ({
  projects, selectedImages, show, format, onCancel,
  setSelectedImages, startLoading, finishLoading, createToast,
}) => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const exportingImages = [];
    selectedImages.forEach((image) => {
      if (format !== 'PDF' || image.type.startsWith('image')) {
        exportingImages.push({
          ...image,
          projectName: image.project.name,
        });
      }
    });
    setImages(exportingImages);
  }, [format, projects, selectedImages]);

  const onRemoveMedia = (imageId, type) => {
    if (!window.confirm(`Are you sure to remove this ${type.split(/\W/)[0]}?`)) {
      return;
    }

    const filteredImages = images.filter((image) => image._id !== imageId);
    if (!filteredImages.length) {
      onCancel();
    }
    setImages(filteredImages);
  };

  const onExport = () => {
    if (!images.length) {
      createToast({
        message: 'No images are selected!',
        type: 'error',
      });
      return;
    }

    let exportFunc = null;
    if (format === 'HTML') {
      exportFunc = PortfolioService.exportToHTML;
    } else if (format === 'PDF') {
      exportFunc = PortfolioService.exportToPDF;
    } else if (format === 'PPT') {
      exportFunc = PortfolioService.exportToPPT;
    }
    if (!exportFunc) {
      createToast({
        message: 'Unknown export format!',
        type: 'error',
      });
      return;
    }
    startLoading();
    exportFunc(images).then((result) => {
      finishLoading();
      if (result.error) {
        createToast({
          message: result.message,
          type: 'error',
        });
      } else {
        setSelectedImages([]);
        createToast({
          message: `Media has been exported to ${format} successfully`,
          type: 'success',
        });
        onCancel();
      }
    });
  };

  const onReorder = (event, previousIndex, nextIndex) => {
    setImages(reorder(images, previousIndex, nextIndex));
  };

  return (
    <Modal title={`Export to ${format}`} className="export-modal" show={show} onClose={onCancel}>
      <div className="modal-body">
        <Reorder
          className="images"
          reorderId="export-images"
          holdTime={200}
          onReorder={onReorder}
        >
          {
            images.map((image) => (
              <div key={image._id} className="export-image">
                <div className="image pointer-event-none">
                  {
                    image.type.startsWith('image')
                      ? (
                        <img
                          src={`${config.PORTFOLIO_URL}/${image._id}.${image.type.split(/\W/)[1]}?q=${image.updatedAt}`}
                          alt={image._id}
                        />
                      )
                      : (
                        <div className="video-frame">
                          <img
                            src="resources/images/video/video_frame.png"
                            alt={image._id}
                            className="video-frame-img"
                          />
                          <video
                            className="mw-100 mh-100"
                            src={`${config.VIDEO_URL}/${image._id}.${image.type.split(/\W/)[1]}?q=${image.updatedAt}`}
                          />
                        </div>
                      )

                  }
                  <span className="back-drop section-name">{`${image.projectName} / ${image.section}`}</span>
                </div>
                <div className="title">{image.subSection}</div>
                <i className="fa fa-times-circle remove-button" onClick={() => onRemoveMedia(image._id, image.type)} />
              </div>
            ))
          }
        </Reorder>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-default" onClick={onCancel}>Cancel</button>
        <button type="button" className="btn btn-primary" onClick={onExport}>{`Export to ${format}`}</button>
      </div>
    </Modal>
  );
};

ExportModal.propTypes = {
  projects: PropTypes.array,
  selectedImages: PropTypes.array,
  show: PropTypes.bool,
  format: PropTypes.string,
  onCancel: PropTypes.func,
  setSelectedImages: PropTypes.func.isRequired,
  createToast: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
};

ExportModal.defaultProps = {
  projects: [],
  selectedImages: [],
  show: false,
  format: '',
  onCancel: null,
};

const mapStateToProps = (store) => ({
  projects: store.portfolioReducer.projects,
  selectedImages: store.portfolioReducer.selectedImages,
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators(
    {
      setSelectedImages: PortfolioAction.setSelectedImages,
      createToast: ToastAction.createToast,
      startLoading: CommonAction.startLoading,
      finishLoading: CommonAction.finishLoading,
    },
    dispatch,
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(ExportModal);
