import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import config from '../../constants/config';

const SharedReport = ({
  match,
}) => {
  const [srcUrl, setSrcUrl] = useState(undefined);

  useEffect(() => {
    fetch(`${config.REPORT_URL}/${match.params.file}`)
      .then((res) => {
        if (res.status !== 200)
          throw res;
        return res.blob();
      })
      .then((blob) => URL.createObjectURL(blob))
      .then((url) => setSrcUrl(url))
      .catch(() => setSrcUrl(null));
  }, [match.params.file]);

  return (
    <div className="shared-report w-100 h-100">
      {
        srcUrl
          ? (
            // <iframe className="w-100 h-100" title="Report" src={srcUrl} />
            <embed
              name="report-pdf"
              className="w-100 h-100"
              src={srcUrl}
              type="application/pdf"
            />
          )
          : (
            <div className="w-100 h-100 d-flex-center font-20p">
              {srcUrl === undefined ? 'Loading ...' : 'No report is available.'}
            </div>
          )
      }
    </div>
  );
};

SharedReport.propTypes = {
  match: PropTypes.object.isRequired,
};

export default SharedReport;
