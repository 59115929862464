import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './style.scss';

import MaterialTable from 'material-table';
import { Button } from '@material-ui/core';
import EditReportModal from './EditReportModal';
import PreviewReportModal from './PreviewReportModal';
import tableIcons from '../../../../constants/TableIcons';
import * as PortfolioService from '../../../../services/portfolio.service';
import * as ToastAction from '../../../../store/actions/toast.action';
import * as CommonAction from '../../../../store/actions/common.action';
import * as PortfolioAction from '../../../../store/actions/portfolio.action';
import { getCurrentWeek } from '../../../../utils';

const WeeklyReportTab = ({
  project, user, addWeeklyReportedProject, removeWeeklyReportedProject, createToast, startLoading, finishLoading,
}) => {
  const [reports, setReports] = useState([]);
  const [currentWeekReport, setCurrentWeekReport] = useState();
  const [editingReport, setEditingReport] = useState(null);
  const [previewReport, setPreviewReport] = useState(null);

  useEffect(() => {
    if (!user)
      return;

    startLoading();
    PortfolioService.listWeeklyReports(project).then((result) => {
      finishLoading();
      if (result.error) {
        createToast({
          message: result.message || 'Loading weekly reports has been failed.',
          type: 'error',
        });
      } else {
        const weeklyReports = result.data.reports.map((r, index) => ({ ...r, no: index + 1 }));
        const currentReport = weeklyReports.find((report) => report.isCurrentWeek && report.user._id === user._id);
        setCurrentWeekReport(currentReport);
        setReports(weeklyReports);
      }
    });
  }, [createToast, finishLoading, project, startLoading, user]);

  const onUploadReport = (report) => {
    if (editingReport) {
      report.no = editingReport.no;
      setReports(reports.map((r) => (
        r === editingReport
          ? ({
            ...r,
            accomplish: report.accomplish,
            actions: report.actions,
            blockers: report.blockers,
            created: report.created,
            plan: report.plan,
            status: report.status,
            color: report.color,
            updatedAt: report.updatedAt,
          })
          : r
      )));
    } else {
      report = {
        ...report,
        no: reports.length + 1,
        tableDate: { id: reports.length },
        user: {
          _id: report.user,
          name: user.name,
        },
        isCurrentWeek: true,
        created: false,
      };
      setReports([...reports, report]);
      addWeeklyReportedProject(project._id);
    }
    if (editingReport === currentWeekReport) {
      setCurrentWeekReport(report);
    }
    setEditingReport(null);
  };

  const onRemoveReport = () => {
    if (editingReport === currentWeekReport) {
      setCurrentWeekReport(null);
      removeWeeklyReportedProject(project._id);
    }
    setReports(reports.filter((report) => report !== editingReport));
    setEditingReport(null);
  };

  if (!user) {
    return null;
  }

  return (
    <>
      {reports.length ? (
        <>
          {!currentWeekReport && (
            <div className="text-right">
              <Button className="btn-primary size-sm px-3" onClick={() => setEditingReport(undefined)}>
                Create My Weekly Report
              </Button>
            </div>
          )}
          <MaterialTable
            icons={tableIcons}
            columns={[
              { title: 'No', field: 'no', width: '2%', defaultSort: 'desc' },
              {
                title: 'Report Date',
                field: 'createdAt',
                render(data) {
                  return <pre>{getCurrentWeek(data.createdAt)}</pre>;
                },
                width: '40%',
              },
              {
                title: 'Last Edited By',
                field: 'user',
                render(data) {
                  return <pre>{data.user.name}</pre>;
                },
                width: '20%',
              },
              {
                title: 'Uploaded',
                field: 'created',
                render: (data) => (
                  data.created
                    ? <span className="badge badge-success text-white">Yes</span>
                    : <span className="badge badge-danger text-white">No</span>
                ),
                cellStyle: { textAlign: 'center' },
                headerStyle: { textAlign: 'center' },
                width: '2%',
              },
              {
                title: 'Actions',
                render: (data) => (
                  <>
                    {(data.isCurrentWeek && !data.created) ? (
                      <span className="action-button" data-toggle="tooltip" title="Edit" onClick={() => setEditingReport(data)}>
                        <i className="fa fa-edit text-success" />
                      </span>
                    ) : (
                      <span className="action-button" data-toggle="tooltip" title="View" onClick={() => setPreviewReport(data)}>
                        <i className="fa fa-eye text-primary" />
                      </span>
                    )}
                  </>
                ),
                cellStyle: { textAlign: 'center' },
                headerStyle: { textAlign: 'center' },
                sorting: false,
                width: '10%',
              },
            ]}
            data={reports}
            options={{
              pageSize: 5,
              pageSizeOptions: [5, 10, 20, 50, 100],
              headerStyle: { fontWeight: 'bold' },
              search: false,
              showTitle: false,
            }}
          />
        </>
      ) : (
        <div className="py-5 my-4 text-center">
          <Button className="btn-primary size-sm px-3 mb-4" onClick={() => setEditingReport(undefined)}>
            Create My Weekly Report
          </Button>
          <p className="no-data m-0 p-0">No reports have been created for this project yet.</p>
        </div>
      )}

      {editingReport !== null && (
        <EditReportModal
          open
          report={editingReport}
          project={project}
          onClose={() => setEditingReport(null)}
          onUpload={onUploadReport}
          onDelete={onRemoveReport}
        />
      )}

      {previewReport && (
        <PreviewReportModal
          open
          report={previewReport}
          onClose={() => setPreviewReport(null)}
        />
      )}
    </>
  );
};

WeeklyReportTab.propTypes = {
  user: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  addWeeklyReportedProject: PropTypes.func.isRequired,
  removeWeeklyReportedProject: PropTypes.func.isRequired,
  createToast: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
};

WeeklyReportTab.defaultProps = {
};

const mapStateToProps = (store) => ({
  user: store.authReducer.user,
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators(
    {
      addWeeklyReportedProject: PortfolioAction.addWeeklyReportedProject,
      removeWeeklyReportedProject: PortfolioAction.removeWeeklyReportedProject,
      createToast: ToastAction.createToast,
      startLoading: CommonAction.startLoading,
      finishLoading: CommonAction.finishLoading,
    },
    dispatch,
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(WeeklyReportTab);
