import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import './MediaCarousel.scss';

import OwlCarousel from 'react-owl-carousel3';
import config from '../../../../constants/config';
import PreviewModal from '../ScreenshotsTab/PreviewModal';

const MediaCarousel = ({
  medias, noMediaMessage,
}) => {
  const [previewMedia, setPreviewMedia] = useState(null);

  const carouselOptions = useMemo(() => ({
    loop: true,
    nav: false,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    navText: ['‹', '›'],
    margin: 10,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: Math.min(2, medias.length),
      },
      1200: {
        items: Math.min(3, medias.length),
      },
    },
  }), [medias.length]);

  const getPreviewUrl = (media) => {
    const baseUrl = media.type.startsWith('image') ? config.PORTFOLIO_URL : config.VIDEO_URL;
    return `${baseUrl}/${media._id}.${media.type.split(/\W/)[1]}?q=${media.updatedAt}`;
  };

  const imageCarousel = useMemo(() => (
    <OwlCarousel
      className="owl-carousel owl-theme"
      {...carouselOptions}
    >
      {
        medias.map((media) => (
          <div key={media._id} className="history-item">
            <div className="image-container" onClick={() => setPreviewMedia(media)}>
              {
                media.type.startsWith('image')
                  ? (
                    <img src={getPreviewUrl(media)} alt={media._id} />
                  )
                  : (
                    <video src={getPreviewUrl(media)} />
                  )
              }
            </div>
            <div className="last-modified-time text-center">
              {media.version}
            </div>
          </div>
        ))
      }
    </OwlCarousel>
  ), [carouselOptions, medias]);

  return (
    <div className="past-versions w-100">
      {
        medias.length
          ? imageCarousel
          : <p className="text-center p-2 m-0">{noMediaMessage}</p>
      }
      {
        previewMedia && (
          <PreviewModal show image={previewMedia} images={medias} onClose={() => setPreviewMedia(null)} />
        )
      }
    </div>
  );
};

MediaCarousel.propTypes = {
  medias: PropTypes.array,
  noMediaMessage: PropTypes.string,
};

MediaCarousel.defaultProps = {
  medias: [],
  noMediaMessage: 'No Medias',
};

export default MediaCarousel;
