import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import './ProjectItem.scss';

import { Tooltip } from '@material-ui/core';
import ProjectMoveModal from '../Project/ProjectMoveModal';
import ROUTES from '../../constants/routes.json';
import * as PortfolioAction from '../../store/actions/portfolio.action';
import * as PortfolioService from '../../services/portfolio.service';
import * as ToastAction from '../../store/actions/toast.action';
import * as CommonAction from '../../store/actions/common.action';

const ProjectItem = ({
  project, editable, activeProject, selectedImages, currentPage, activeTab, weeklyReportedProjects,
  setActiveProject, removeProject, setEditingProject, createToast, startLoading, finishLoading,
}) => {
  const history = useHistory();
  const [isMoving, setIsMoving] = useState(false);

  const selectedCnt = useMemo(() => (
    project.images.filter((image) => !!selectedImages.find((selectedImg) => selectedImg._id === image._id)).length
  ), [project.images, selectedImages]);

  const hasWeeklyReport = useMemo(() => weeklyReportedProjects.includes(project._id), [project._id, weeklyReportedProjects]);

  const onSelectProject = (project) => {
    setActiveProject(project);
    if (currentPage !== ROUTES.PROJECTS.url) {
      history.push(ROUTES.PROJECTS.url);
    }
  };

  const onEditProject = () => {
    setEditingProject(project);
  };

  const onMoveProject = () => {
    setIsMoving(true);
  };

  const onRemoveProject = () => {
    if (!window.confirm(`Are you sure to remove ${project.name} project?`)) {
      return;
    }

    startLoading();
    PortfolioService.removeProject(project).then((result) => {
      finishLoading();

      if (result.error) {
        createToast({
          type: 'error',
          title: 'Failed!',
          message: result.message || 'Removing project has been failed.',
        });
      } else {
        removeProject(project);
        createToast({
          type: 'success',
          message: 'You have removed project successfully.',
        });
      }
    });
  };

  // const logoUrl = project.preview || `${config.PROJECT_URL}/${project.logo}`;

  return (
    <div className={`project-item-wrapper ${project === activeProject ? 'active' : ''}`}>
      <Tooltip
        arrow
        classes={{
          tooltip: 'project-tooltip',
        }}
        title={(
          <div>
            <p>
              <span>Name: </span>
              {project.name}
            </p>
            <p>
              <span>Type: </span>
              {project.tags.map((tag) => tag.name).join(', ')}
            </p>
            {
              project.year
                ? (
                  <p>
                    <span>Year: </span>
                    {project.year}
                  </p>
                )
                : null
            }
          </div>
        )}
      >
        <div
          className="project-item"
          onClick={() => onSelectProject(project)}
        >
          {/* <div className="project-logo"> */}
          {/*  <div className="logo-container"> */}
          {/*    { */}
          {/*      project.logo */}
          {/*        ? <img src={logoUrl} alt="Logo" /> */}
          {/*        : <i className="fa fa-image" /> */}
          {/*    } */}
          {/*  </div> */}
          {/* </div> */}
          <div className="project-name text-ellipsis">
            {project.name}
          </div>
          {
            (activeTab !== 'weekly-report' && selectedCnt)
              ? <span className="badge">{selectedCnt}</span>
              : null
          }
          {
            activeTab === 'weekly-report' && (
              <Tooltip
                title={hasWeeklyReport ? 'Weekly report is ready' : 'Weekly report is missing'}
                placement="top"
                arrow
              >
                <i className={`report-icon fa fa-check-circle mr-1 ${hasWeeklyReport ? 'active' : ''}`} />
              </Tooltip>
            )
          }
        </div>
      </Tooltip>

      {editable && (
        <div className="project-actions">
          <i className="menu-button fa fa-ellipsis-v" />
          <div className="actions-menu">
            <i className="fa fa-trash" title="Delete Project" onClick={onRemoveProject} />
            <i className="fa fa-edit" title="Edit Project" onClick={onEditProject} />
            <i className="fa fa-external-link" title="Move Project to Another Client" onClick={onMoveProject} />
          </div>
        </div>
      )}

      {isMoving && (
        <ProjectMoveModal open project={project} onClose={() => setIsMoving(false)} />
      )}
    </div>
  );
};

ProjectItem.propTypes = {
  currentPage: PropTypes.string.isRequired,
  project: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  activeProject: PropTypes.object,
  selectedImages: PropTypes.array,
  activeTab: PropTypes.string,
  weeklyReportedProjects: PropTypes.array,
  setActiveProject: PropTypes.func.isRequired,
  removeProject: PropTypes.func.isRequired,
  setEditingProject: PropTypes.func.isRequired,
  createToast: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
};

ProjectItem.defaultProps = {
  editable: true,
  activeProject: null,
  selectedImages: [],
  activeTab: null,
  weeklyReportedProjects: [],
};

const mapStateToProps = (store) => ({
  activeProject: store.portfolioReducer.activeProject,
  selectedImages: store.portfolioReducer.selectedImages,
  currentPage: store.portfolioReducer.currentPage,
  activeTab: store.portfolioReducer.activeTab,
  weeklyReportedProjects: store.portfolioReducer.weeklyReportedProjects,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setActiveProject: PortfolioAction.setActiveProject,
      removeProject: PortfolioAction.removeProject,
      setEditingProject: PortfolioAction.setEditingProject,
      createToast: ToastAction.createToast,
      startLoading: CommonAction.startLoading,
      finishLoading: CommonAction.finishLoading,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectItem);
