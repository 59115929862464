import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

import { Button } from '@material-ui/core';
import moment from 'moment-timezone';
import Dialog from '../../common/Dialog';

const LogViewModal = ({
  caseStudy, open, onClose,
}) => (
  <Dialog
    title={caseStudy.title || 'Change Log'}
    className="log-view-modal"
    open={open}
    onClose={onClose}
    footerActions={(
      <Button className="size-sm px-3" onClick={onClose}>Close</Button>
    )}
  >
    <table className="table-striped w-100">
      <thead>
        <tr>
          <th>No</th>
          <th>Timestamp</th>
          <th>Log</th>
        </tr>
      </thead>
      <tbody>
        {
          caseStudy.history.map((log, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{moment(log.updatedAt).tz('America/Los_Angeles').format('lll')}</td>
              <td>
                {`${log.isCreate ? 'Created' : 'Updated'} by`}
                &nbsp;
                <b>{log.updatedBy}</b>
              </td>
            </tr>
          ))
        }
      </tbody>
    </table>
  </Dialog>
);

LogViewModal.propTypes = {
  caseStudy: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

LogViewModal.defaultProps = {
  open: false,
  onClose: null,
};

export default LogViewModal;
