import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './style.scss';

import { Button, IconButton, Tooltip } from '@material-ui/core';
import { PersonAdd, Search } from '@material-ui/icons';
import EditContactModal from '../EditContactModal';
import ContactItem from '../ContactItem';
import Dialog from '../../common/Dialog';
import FormControl from '../../common/FormControl';

const ContactsModal = ({
  open, contacts, selectable, selectedContacts, onSelectionChanged, onClose,
}) => {
  const form = useMemo(() => ({
    search: {
      name: 'search',
      placeholder: 'Search',
    },
  }), []);

  const [search, setSearch] = useState('');
  const [selected, setSelected] = useState([]);
  const [editingContact, setEditingContact] = useState(null);

  const filteredContacts = useMemo(() => {
    const keyword = search.toLowerCase().trim();
    if (!keyword)
      return contacts;

    return contacts.filter((contact) => contact.primary.toLowerCase().includes(keyword));
  }, [contacts, search]);

  useEffect(() => {
    setSelected(selectedContacts);
  }, [selectedContacts]);

  const onCreate = () => {
    setEditingContact({
      _id: null,
      primary: '',
      email: '',
      phone: '',
      website: '',
      linkedIn: '',
      reference: '',
      image: null,
    });
  };

  const onSelect = (contact) => {
    if (!selected.includes(contact._id)) {
      setSelected([...selected, contact._id]);
    } else {
      setSelected(selected.filter((id) => id !== contact._id));
    }
  };

  const onFinish = () => {
    if (selectable && onSelectionChanged)
      onSelectionChanged(selected);
    onClose();
  };

  return (
    <>
      <Dialog
        title={selectable ? 'Select Contacts' : 'Contacts'}
        className="contacts-modal"
        open={open}
        onClose={onClose}
        footerActions={(
          <>
            <Button className="size-sm px-3 mr-2" onClick={onClose}>Cancel</Button>
            <Button className="btn-primary size-sm px-3" onClick={onFinish}>OK</Button>
          </>
        )}
      >
        <div className="d-flex align-items-center justify-content-between">
          <FormControl
            control={form.search}
            size="sm"
            fullWidth={false}
            className="mb-0"
            startAdornment={<Search className="text-muted text-xl pointer-event-none" />}
            onChange={(_, value) => setSearch(value)}
          />
          <Tooltip title="Create contact" placement="top" arrow>
            <IconButton className="btn-primary size-sm" onClick={onCreate}>
              <PersonAdd />
            </IconButton>
          </Tooltip>
        </div>
        <div className="contacts mt-3">
          {
            filteredContacts.map((contact) => (
              <ContactItem
                key={contact._id}
                contact={contact}
                selectable
                hasAction
                selected={selected.includes(contact._id)}
                onSelect={() => onSelect(contact)}
                onEdit={() => setEditingContact(contact)}
              />
            ))
          }
          {!filteredContacts.length && (
            <p className="text-center mb-0">No contacts are available.</p>
          )}
        </div>
      </Dialog>

      {Boolean(editingContact) && (
        <EditContactModal
          open
          contact={editingContact}
          onClose={() => setEditingContact(null)}
        />
      )}
    </>
  );
};

ContactsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  selectedContacts: PropTypes.array,
  selectable: PropTypes.bool,
  onSelectionChanged: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  contacts: PropTypes.array,
};

ContactsModal.defaultProps = {
  selectedContacts: [],
  contacts: [],
  selectable: false,
  onSelectionChanged: () => {},
};

const mapStateToProps = (store) => ({
  contacts: store.portfolioReducer.contacts,
});

export default connect(mapStateToProps)(ContactsModal);
