import { $ } from 'grapesjs';

export default (editor) => {
  const am = editor.AssetManager;
  am.addType('video', {
    model: {
      defaults: {
        type: 'video',
        src: '',
        name: 'Empty Video',
      },
      getName() {
        return this.get('name');
      },
    },
    view: {
      getPreview() {
        return `<video src="${this.model.get('src')}" style="width: 100%; height: 100%;"></video>`;
      },
      getInfo() {
        return this.model.get('name');
      },
      updateTarget(target) {
        if (target) {
          target.set('src', this.model.get('src'));
        }
      },
    },
    isType(value) {
      if (typeof value === 'object' && value.type === 'video') {
        return value;
      }
    },
  });

  editor.on('run:open-assets', (from, options) => {
    const modal = editor.Modal;
    if (options.types[0] === 'video') {
      modal.setTitle('Select Video');

      const formEl = $(modal.getContentEl()).find('.gjs-am-assets-cont form.gjs-am-add-asset');
      if (formEl) {
        const inputEl = $(formEl).find('input');
        if (inputEl) {
          inputEl.attr('placeholder', 'http://path/to/the/video.mp4');
        }
        const buttonEl = $(formEl).find('button');
        if (buttonEl) {
          buttonEl.text('Add video');
        }
      }
    } else {
      modal.setTitle('Select Image');

      const formEl = $(modal.getContentEl()).find('.gjs-am-assets-cont form.gjs-am-add-asset');
      if (formEl) {
        const inputEl = $(formEl).find('input');
        if (inputEl) {
          inputEl.attr('placeholder', 'http://path/to/the/image.jpg');
        }
        const buttonEl = $(formEl).find('button');
        if (buttonEl) {
          buttonEl.text('Add image');
        }
      }
    }
  });
};
