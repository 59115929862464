import ACTION from '../actionTypes';

const initialState = {
  user: null,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION.SIGN_IN:
    case ACTION.SET_USER:
      return {
        ...state,
        user: action.user,
      };

    case ACTION.SIGN_OUT:
      return {
        ...state,
        user: null,
      };

    default:
      return state;
  }
}
