import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';
import Dialog from '../../../../common/Dialog';

const CaseStudyPreviewModal = ({
  title, preview, open, onClose,
}) => (
  <Dialog
    title={title}
    className="case-study-preview-modal"
    open={open}
    onClose={onClose}
  >
    <iframe title={title} srcDoc={preview} />
  </Dialog>
);

CaseStudyPreviewModal.propTypes = {
  title: PropTypes.string,
  preview: PropTypes.string.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

CaseStudyPreviewModal.defaultProps = {
  title: '',
  open: false,
  onClose: () => {},
};

export default CaseStudyPreviewModal;
