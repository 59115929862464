import React, { useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './style.scss';

import { Button } from '@material-ui/core';
import Dialog from '../../common/Dialog';
import FormControl from '../../common/FormControl';
import * as AuthService from '../../../services/auth.service';
import * as ToastAction from '../../../store/actions/toast.action';
import * as CommonAction from '../../../store/actions/common.action';
import Validators from '../../common/FormControl/validators';
import { validateFormGroup } from '../../common/FormControl/utils';

const ChangePasswordModal = ({
  open, onClose, createToast, startLoading, finishLoading,
}) => {
  const form = useMemo(() => ({
    oldPassword: {
      name: 'oldPassword',
      type: 'password',
      label: 'Old Password',
      validators: [Validators.required()],
    },
    newPassword: {
      name: 'newPassword',
      type: 'password',
      label: 'New Password',
      validators: [Validators.required()],
    },
    confirmPassword: {
      name: 'confirmPassword',
      type: 'password',
      label: 'Password Confirm',
      validators: [Validators.required()],
    },
  }), []);

  const validateForm = () => {
    if (!validateFormGroup(form))
      return false;

    if (form.newPassword.value !== form.confirmPassword.value) {
      form.confirmPassword.setError('Confirm Password is not match.');
      return false;
    }
    return true;
  };

  const onSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    startLoading();
    const result = await AuthService.changePassword(form.oldPassword.value, form.newPassword.value);
    finishLoading();

    if (result.error) {
      createToast({
        type: 'error',
        message: result.message || 'Change password has been failed',
      });
      return;
    }

    createToast({
      type: 'success',
      message: 'Password has been changed successfully!',
    });
    onClose();
  };

  return (
    <Dialog
      title="Change Password"
      className="change-password-modal"
      open={open}
      onClose={onClose}
      footerActions={(
        <>
          <Button className="size-sm px-3 mr-2" onClick={onClose}>Cancel</Button>
          <Button className="btn-primary size-sm px-3" onClick={onSubmit}>Change Password</Button>
        </>
      )}
    >
      <FormControl control={form.oldPassword} size="sm" />
      <FormControl control={form.newPassword} size="sm" />
      <FormControl control={form.confirmPassword} size="sm" className="mb-0" />
    </Dialog>
  );
};

ChangePasswordModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  createToast: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
};

ChangePasswordModal.defaultProps = {
  open: false,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createToast: ToastAction.createToast,
      startLoading: CommonAction.startLoading,
      finishLoading: CommonAction.finishLoading,
    },
    dispatch,
  );
}

export default connect(null, mapDispatchToProps)(ChangePasswordModal);
