import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import './ProjectImage.scss';

import { Checkbox } from '@material-ui/core';
import * as PortfolioAction from '../../../../store/actions/portfolio.action';
import config from '../../../../constants/config';
import MediaEditModal from './MediaEditModal';
import { ROLES } from '../../../../constants';

const ProjectImage = ({
  user, image, selectedImages, isGlobalSearch, toggleImageSelect,
}) => {
  const [editing, setEditing] = useState(false);
  let mouseDownTimeStamp;

  const onSelect = () => {
    toggleImageSelect(image);
  };

  const onMouseDown = () => {
    if (!mouseDownTimeStamp) {
      mouseDownTimeStamp = new Date().getTime();
    }
  };

  const onMouseUp = () => {
    const delay = new Date().getTime() - mouseDownTimeStamp;
    if (isGlobalSearch || delay < 200) {
      setEditing(true);
    }
    mouseDownTimeStamp = undefined;
  };

  const selected = useMemo(() => (
    selectedImages.some((i) => i._id === image._id)
  ), [image._id, selectedImages]);

  const url = useMemo(() => (
    `${config.PORTFOLIO_URL}/${image._id}.${image.type.split(/\W/)[1]}?q=${image.updatedAt}`
  ), [image]);

  return (
    <>
      <div className={`project-media ${selected ? 'active' : ''}`}>
        <div className="media-preview" onMouseDown={onMouseDown} onMouseUp={onMouseUp}>
          {
            image.type.startsWith('image')
              ? <img className="pointer-event-none" src={url} alt={image._id} />
              : (
                <div className="video-frame pointer-event-none">
                  <img
                    src="/resources/images/video/video_frame.png"
                    alt={image._id}
                    className="video-frame-img"
                  />
                  {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                  <video
                    className="mw-100 mh-100"
                    src={`${config.VIDEO_URL}/${image._id}.${image.type.split(/\W/)[1]}?q=${image.updatedAt}`}
                  />
                </div>
              )
          }
          <Checkbox
            className="checkbox"
            color="primary"
            checked={selected}
            onMouseDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onChange={onSelect}
          />
        </div>
        <div className="media-title">
          <Link to={`/portfolio/${image._id}`} target="_blank">{image.subSection || image.section}</Link>
        </div>
      </div>

      {user.role !== ROLES.CLIENT && (
        <MediaEditModal open={editing} media={image} onClose={() => setEditing(false)} />
      )}
    </>
  );
};

ProjectImage.propTypes = {
  user: PropTypes.object.isRequired,
  image: PropTypes.object.isRequired,
  selectedImages: PropTypes.array,
  isGlobalSearch: PropTypes.bool,
  toggleImageSelect: PropTypes.func.isRequired,
};

ProjectImage.defaultProps = {
  selectedImages: [],
  isGlobalSearch: false,
};

const mapStateToProps = (store) => ({
  user: store.authReducer.user,
  selectedImages: store.portfolioReducer.selectedImages,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      toggleImageSelect: PortfolioAction.toggleImageSelect,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectImage);
