import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import './style.scss';

import SearchBox from '../../components/CaseStudies/SearchBox';
import CaseStudiesTable from '../../components/CaseStudies/CaseStudiesTable';
import * as ToastAction from '../../store/actions/toast.action';
import * as CommonAction from '../../store/actions/common.action';
import * as PortfolioAction from '../../store/actions/portfolio.action';
import * as PortfolioService from '../../services/portfolio.service';

const CaseStudies = ({
  clients, setClients, showSideBar, setHasUnsavedData, createToast, startLoading, finishLoading,
}) => {
  const [filter, setFilter] = useState({
    search: '',
    client: null,
    projects: [],
    industries: [],
    locations: [],
  });
  const [caseStudies, setCaseStudies] = useState([]);

  useEffect(() => {
    setHasUnsavedData(false);
  }, [setHasUnsavedData]);

  useEffect(() => {
    showSideBar(false);
  }, [showSideBar]);

  useEffect(() => {
    startLoading();
    PortfolioService.getClients({ params: { expand: ['project.all', 'project.caseStudy'] } }).then((result) => {
      finishLoading();
      if (result.error) {
        createToast({
          message: result.message,
          type: 'error',
        });
      } else {
        setClients(result.data.clients);
      }
    });
  }, [createToast, finishLoading, startLoading, setClients]);

  useEffect(() => {
    let projects = [];
    if (filter.projects.length) {
      projects = filter.projects;
    } else if (filter.client) {
      projects = filter.client.projects;
    } else {
      clients.forEach((client) => {
        projects = projects.concat(client.projects);
      });
    }

    const filtered = [];
    const keyword = filter.search.trim().toLowerCase();
    projects.forEach((project) => {
      const { caseStudy } = project;
      if (filter.industries.length && (!caseStudy || !filter.industries.includes(caseStudy.industry)))
        return;
      if (filter.locations.length && (!caseStudy || !filter.locations.includes(caseStudy.location)))
        return;
      if (keyword && !(caseStudy?.title || '').toLowerCase().includes(keyword) && !project.name.toLowerCase().includes(keyword))
        return;

      if (!caseStudy) {
        filtered.push({
          _id: null,
          no: filtered.length + 1,
          project,
          projectId: project._id,
          projectName: project.name,
          note: project.note,
          title: '',
          active: false,
          history: [],
        });
      } else {
        filtered.push({
          _id: caseStudy._id,
          no: filtered.length + 1,
          project,
          projectId: project._id,
          projectName: project.name,
          note: project.note,
          title: caseStudy.title,
          active: true,
          history: (
            (caseStudy.history && caseStudy.history.length)
              ? caseStudy.history
              : [{ updatedAt: caseStudy.updatedAt, updatedBy: caseStudy.updatedBy?.name }]
          ),
        });
      }
    });
    setCaseStudies(filtered);
  }, [clients, filter]);

  return (
    <div className="case-studies-page">
      <h4 className="page-title pl-2 pb-2">Case Studies</h4>
      <SearchBox clients={clients} filter={filter} onFilterChange={setFilter} />
      <CaseStudiesTable caseStudies={caseStudies} />
    </div>
  );
};

CaseStudies.propTypes = {
  clients: PropTypes.array,
  setClients: PropTypes.func.isRequired,
  showSideBar: PropTypes.func.isRequired,
  setHasUnsavedData: PropTypes.func.isRequired,
  createToast: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
};

CaseStudies.defaultProps = {
  clients: [],
};

const mapStateToProps = (store) => ({
  clients: store.portfolioReducer.clients,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setClients: PortfolioAction.setClients,
      showSideBar: PortfolioAction.showSideBar,
      setHasUnsavedData: PortfolioAction.setHasUnsavedData,
      createToast: ToastAction.createToast,
      startLoading: CommonAction.startLoading,
      finishLoading: CommonAction.finishLoading,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CaseStudies);
