import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './style.scss';

import { Search } from '@material-ui/icons';
import FormControl from '../../../common/FormControl';
import * as PortfolioAction from '../../../../store/actions/portfolio.action';
import FilterIconButton from '../FilterIconButton';

const searchControl = {
  name: 'search',
  placeholder: 'Search project',
};

const ProjectSearchBox = ({
  projectFilter, setProjectFilter,
}) => {
  const onFilterChange = (name, value) => {
    setProjectFilter({
      ...projectFilter,
      [name]: value,
    });
  };

  return (
    <div className="project-search mr-2">
      <FormControl
        control={searchControl}
        className="mb-0"
        size="sm"
        startAdornment={<Search className="text-muted text-xl pointer-event-none" />}
        endAdornment={<FilterIconButton />}
        onChange={onFilterChange}
      />
    </div>
  );
};

ProjectSearchBox.propTypes = {
  projectFilter: PropTypes.object.isRequired,
  setProjectFilter: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  projectFilter: store.portfolioReducer.projectFilter,
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators(
    {
      setProjectFilter: PortfolioAction.setProjectFilter,
    },
    dispatch,
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSearchBox);
