import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory, useLocation } from 'react-router';
import './style.scss';

import { PersonAdd } from '@material-ui/icons';
import { Button, IconButton } from '@material-ui/core';
import ToggleBtn from '../common/ToggleBtn';
import ProjectSearchBox from '../Project/ProjectFilter/SearchBox';
import ReportDropdown from './ReportDropdown';
import ProfileMenu from './ProfileMenu';
import UsersModal from '../Auth/UsersModal';
import ROUTES from '../../constants/routes.json';
import { ROLES } from '../../constants';
import * as PortfolioAction from '../../store/actions/portfolio.action';

const Header = ({
  user, client, activeTab, hasUnsavedData, setEditingProject, toggleSideBar,
}) => {
  const [showUsersModal, setShowUsersModal] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const canNavigate = () => (
    activeTab !== 'case-study'
      || !hasUnsavedData
      || window.confirm('If you navigate away from here without saving your data, changes will be lost. Are you sure to navigate?')
  );

  const onCreateProject = () => {
    setEditingProject({
      client: client ? client._id : '',
      _id: null,
      name: '',
      logo: null,
    });
  };

  const onGotoCaseStudiesPage = () => {
    if (canNavigate()) {
      history.push(ROUTES.CASE_STUDIES.url);
    }
  };

  const onGotoProjectsPage = () => {
    history.push(ROUTES.PROJECTS.url);
  };

  if (!user) {
    return null;
  }

  return (
    <div className="app-header">
      <ToggleBtn className="d-none" onClick={toggleSideBar} />

      <ProjectSearchBox />

      <div className="d-flex align-items-center">
        {user.role !== ROLES.CLIENT && (
          <>
            {
              location.pathname === ROUTES.CASE_STUDIES.url
                ? <Button className="size-sm mr-2" onClick={onGotoProjectsPage}>Projects</Button>
                : <Button className="size-sm mr-2" onClick={onGotoCaseStudiesPage}>Case Studies</Button>
            }

            <ReportDropdown />

            <Button className="btn-warning size-sm font-weight-bold px-3 mr-2" onClick={onCreateProject}>
              + Create Project
            </Button>
          </>
        )}

        {user.role === ROLES.ADMIN && (
          <IconButton className="btn-outline-secondary mr-2" onClick={() => setShowUsersModal(true)}>
            <PersonAdd />
          </IconButton>
        )}

        <ProfileMenu user={user} canNavigate={canNavigate} />

        {showUsersModal && (
          <UsersModal open onClose={() => setShowUsersModal(false)} />
        )}
      </div>
    </div>
  );
};

Header.propTypes = {
  user: PropTypes.object,
  client: PropTypes.object,
  activeTab: PropTypes.string,
  hasUnsavedData: PropTypes.bool,
  setEditingProject: PropTypes.func.isRequired,
  toggleSideBar: PropTypes.func.isRequired,
};

Header.defaultProps = {
  user: null,
  client: null,
  activeTab: 'screenshots',
  hasUnsavedData: false,
};

const mapStateToProps = (store) => ({
  user: store.authReducer.user,
  client: store.portfolioReducer.client,
  activeTab: store.portfolioReducer.activeTab,
  hasUnsavedData: store.portfolioReducer.hasUnsavedData,
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators(
    {
      setEditingProject: PortfolioAction.setEditingProject,
      toggleSideBar: PortfolioAction.toggleSideBar,
    },
    dispatch,
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(Header);
