import React, { useEffect, useMemo, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './style.scss';

import { Button, capitalize, Grid } from '@material-ui/core';
import Dialog from '../../common/Dialog';
import FormControl from '../../common/FormControl';
import * as AuthService from '../../../services/auth.service';
import * as ToastAction from '../../../store/actions/toast.action';
import * as CommonAction from '../../../store/actions/common.action';
import { ROLES } from '../../../constants';
import Validators from '../../common/FormControl/validators';
import {
  patchFormData,
  setFormControlOptions,
  validateFormControl,
  validateFormGroup,
} from '../../common/FormControl/utils';

const roles = Object.values(ROLES).map((role) => ({
  name: capitalize(role),
  value: role,
}));

const EditUserModal = ({
  open, user, clients, onSave, onClose, createToast, startLoading, finishLoading,
}) => {
  const form = useMemo(() => ({
    email: {
      name: 'email',
      label: 'Email *',
      placeholder: 'Email address',
      validators: [Validators.required(), Validators.email()],
    },
    name: {
      name: 'name',
      label: 'Name *',
      placeholder: 'Full name',
      validators: [Validators.required()],
    },
    role: {
      name: 'role',
      type: 'autocomplete',
      label: 'Role *',
      placeholder: 'Select role',
      options: roles,
      labelField: 'name',
      validators: [Validators.required()],
    },
    allowReport: {
      name: 'allowReport',
      type: 'checkbox',
      label: 'Allow Report',
    },
    client: {
      name: 'client',
      type: 'autocomplete',
      label: 'Client *',
      placeholder: 'Select client',
      options: [],
      labelField: 'name',
      validators: [Validators.required()],
    },
  }), []);

  const [formData, setFormData] = useState({
    email: '',
    name: '',
    role: roles[0],
    allowReport: false,
  });

  useEffect(() => {
    patchFormData(form, formData);
  }, [form, formData]);

  useEffect(() => {
    setFormControlOptions(form.client, {
      options: clients,
    });
  }, [clients, form.client]);

  useEffect(() => {
    if (!user)
      return;

    let client = null;
    if (user.client) {
      client = clients.find((c) => c._id === user.client);
    }

    const role = user.role || ROLES.USER;
    setFormData({
      name: user.name || '',
      email: user.email || '',
      role: roles.find((r) => r.value === role),
      allowReport: user.allowReport || false,
      client,
    });
    form.client.validators = (user.role === ROLES.CLIENT) ? [Validators.required()] : [];
  }, [clients, form.client, user]);

  const onInputChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
    if (field === 'role') {
      form.client.validators = value.value === ROLES.CLIENT
        ? [Validators.required()]
        : [];
      validateFormControl(form.client);
    }
  };

  const onCreate = async () => {
    if (!validateFormGroup(form)) {
      return;
    }

    startLoading();
    const result = await AuthService.createUser({
      ...formData,
      client: formData.client?._id,
      role: formData.role.value,
    });
    finishLoading();

    if (result.error) {
      let message = result.message || 'Creating user has been failed';
      if (message.indexOf('duplicate key') !== -1)
        message = 'Duplicate user!';
      createToast({
        type: 'error',
        message,
      });
      return;
    }

    createToast({
      type: 'success',
      message: 'User has been created successfully',
    });
    onSave(result.user);
  };

  const onEdit = async () => {
    if (!validateFormGroup(form)) {
      return;
    }

    startLoading();
    const result = await AuthService.updateUser(user, {
      ...formData,
      client: formData.client?._id,
      role: formData.role.value,
    });
    finishLoading();

    if (result.error) {
      let message = result.message || 'Updating user has been failed';
      if (message.indexOf('duplicate key') !== -1)
        message = 'Duplicate user!';
      createToast({
        type: 'error',
        message,
      });
      return;
    }

    createToast({
      type: 'success',
      message: 'User has been updated successfully',
    });
    onSave(result.user);
  };

  if (!user) {
    return null;
  }

  return (
    <Dialog
      title={user._id ? 'Edit User' : 'Create User'}
      className="edit-user-modal"
      open={open}
      onClose={onClose}
      footerActions={(
        <>
          <Button className="size-sm px-3 mr-2" onClick={onClose}>Cancel</Button>
          {
            user._id
              ? <Button className="btn-primary size-sm px-3" onClick={onEdit}>Save</Button>
              : <Button className="btn-primary size-sm px-3" onClick={onCreate}>Create</Button>
          }
        </>
      )}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl control={form.email} size="sm" className="mb-0" onChange={onInputChange} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl control={form.name} size="sm" className="mb-0" onChange={onInputChange} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl control={form.role} size="sm" className="mb-0" onChange={onInputChange} />
        </Grid>
        {(formData.role.value !== ROLES.PM && formData.role.value !== ROLES.CLIENT) && (
          <Grid item xs={12} sm={6}>
            <FormControl control={form.allowReport} size="sm" className="mb-0" onChange={onInputChange} />
          </Grid>
        )}
        {formData.role.value === ROLES.CLIENT && (
          <Grid item xs={12} sm={6}>
            <FormControl control={form.client} size="sm" className="mb-0" onChange={onInputChange} />
          </Grid>
        )}
      </Grid>
    </Dialog>
  );
};

EditUserModal.propTypes = {
  user: PropTypes.object.isRequired,
  open: PropTypes.bool,
  clients: PropTypes.array,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  createToast: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
};

EditUserModal.defaultProps = {
  open: false,
  clients: [],
};

const mapStateToProps = (store) => ({
  clients: store.portfolioReducer.clients,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createToast: ToastAction.createToast,
      startLoading: CommonAction.startLoading,
      finishLoading: CommonAction.finishLoading,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EditUserModal);
