import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './style.scss';

import ImageGallery from '../../components/Project/ImageGallery';
import * as PortfolioAction from '../../store/actions/portfolio.action';

const Projects = ({
  showSideBar,
}) => {
  useEffect(() => {
    showSideBar(true);
  }, [showSideBar]);

  return (
    <div className="projects-page">
      <ImageGallery />
    </div>
  );
};

Projects.propTypes = {
  showSideBar: PropTypes.func.isRequired,
};

Projects.defaultProps = {
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showSideBar: PortfolioAction.showSideBar,
    },
    dispatch,
  );
}

export default connect(null, mapDispatchToProps)(Projects);
