import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

import LogoImageTab from '../LogoTab';
import BackgroundImageTab from '../BackgroundImageTab';

const BrandingTab = ({ user }) => (
  <>
    <div className="tab-content tab-logo active">
      <LogoImageTab user={user} />
    </div>
    <div className="tab-content tab-background active mt-4">
      <BackgroundImageTab user={user} />
    </div>
  </>
);

BrandingTab.propTypes = {
  user: PropTypes.object.isRequired,
};

export default BrandingTab;
