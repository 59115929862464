import HttpService from './http.service';
import config from '../constants/config';
import StorageService from './storage.service';

const tokenKey = config.TOKEN_KEY;

export function setToken(token) {
  StorageService.setItem(tokenKey, token);
}

export function signIn(email, password) {
  return HttpService.post('login', {
    email,
    password,
  }).then((result) => {
    const { data } = result;
    if (!data.error) {
      setToken(data.data.token);
    }
    return data;
  });
}

export function signUp(email, name, password) {
  return HttpService.post('register', {
    name,
    email,
    password,
  });
}

export async function reloadUserInfo() {
  const accessToken = StorageService.getItem(tokenKey);
  if (accessToken) {
    return HttpService.get('verify-token').then((result) => {
      const { data } = result;
      if (data.error) {
        setToken(null);
        return null;
      }
      return data.data.user;
    });
  }
}

export function signOut() {
  setToken(null);
}

export function canActivateAccount(token) {
  return HttpService.get(`can-activate-account?token=${token}`)
    .then((result) => result.data);
}

export function activateAccount(token) {
  return HttpService.post('activate-account', { token })
    .then((result) => result.data);
}

export function resendVerificationEmail(email) {
  return HttpService.post('resend-verification-email', { email })
    .then((result) => result.data);
}

export function forgotPassword(email) {
  return HttpService.post('forgot-password', { email })
    .then((result) => result.data);
}

export function canResetPassword(token) {
  return HttpService.get(`can-reset-password?token=${token}`)
    .then((result) => result.data);
}

export function resetPassword(token, password) {
  return HttpService.post('reset-password', { token, password })
    .then((result) => result.data);
}

export function changePassword(oldPassword, newPassword) {
  return HttpService.post('change-password', { oldPassword, newPassword })
    .then((result) => result.data);
}

export function updateProfile(newProfile) {
  return HttpService.post('update-profile', { ...newProfile })
    .then((result) => {
      const { data } = result;
      if (!data.error) {
        setToken(data.data.token);
      }
      return data;
    });
}

export function getUsers() {
  return HttpService.get('users')
    .then((result) => result.data);
}

export function createUser(user) {
  return HttpService.post('users', user)
    .then((result) => result.data);
}

export function updateUser(user, newUser) {
  return HttpService.put(`user/${user._id}`, newUser)
    .then((result) => result.data);
}

export function deleteUser(user) {
  return HttpService.remove(`user/${user._id}`)
    .then((result) => result.data);
}

export function setLastVisit(data) {
  return HttpService.post('set-last-visit', data)
    .then((result) => {
      const { data } = result;
      if (!data.error) {
        setToken(data.data.token);
      }
      return data;
    });
}
