import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import './style.scss';

import config from '../../../constants/config';
import * as PortfolioService from '../../../services/portfolio.service';
import { bindActionCreators } from 'redux';
import * as ToastAction from '../../../store/actions/toast.action';
import * as CommonAction from '../../../store/actions/common.action';
import { connect } from 'react-redux';
import MediaFeedback from '../../Project/MediaFeedback';

const PreviewModal = ({
  image, images, project, show, onClose, createToast, startLoading, finishLoading,
}) => {
  const [activeImage, setActiveImage] = useState(null);
  const [feedback, setFeedback] = useState('');

  useEffect(() => {
    setActiveImage(image);
  }, [image]);

  useEffect(() => {
    setFeedback(activeImage ? (activeImage.feedback || '') : '');
  }, [activeImage]);

  const url = useMemo(() => {
    if (!activeImage)
      return '';
    const rootUrl = activeImage.type.startsWith('image') ? config.PORTFOLIO_URL : config.VIDEO_URL;
    return `${rootUrl}/${activeImage._id}.${activeImage.type.split(/\W/)[1]}?q=${activeImage.updatedAt}`;
  }, [activeImage]);

  const statusIcon = useMemo(() => {
    if (!activeImage)
      return '';
    if (activeImage.status === 'In Development')
      return 'fa-desktop';
    if (activeImage.status === 'Accepted')
      return 'fa-check';
    return 'fa-photo';
  }, [activeImage]);

  const onGotoNextImage = (offset) => {
    let index = images.indexOf(activeImage);
    if (index === -1)
      return;

    index = (index + offset + images.length) % images.length;
    setActiveImage(images[index]);
  };

  const onSubmitFeedback = () => {
    startLoading();
    PortfolioService.submitFeedback(activeImage, feedback).then((result) => {
      finishLoading();
      if (result.error) {
        createToast({
          message: result.message || 'Submitting feedback has been failed',
          type: 'error',
        });
      } else {
        createToast({
          message: 'Feedback has been submitted successfully',
          type: 'success',
        });
        activeImage.feedback = feedback;
      }
    });
  };

  if (!show || !activeImage)
    return null;

  return (
    <div className="scrum-preview-modal">
      <div className="backdrop" onClick={onClose} />
      <div className="modal-content">
        <div className="row m-0 h-100">
          <div className="media-preview col-xl-9 col-lg-8 col-md-7 col-12">
            <div className="w-100 h-100 d-flex-center">
              {
                activeImage.type.startsWith('image')
                  ? <img src={url} alt={activeImage._id} />
                  // eslint-disable-next-line jsx-a11y/media-has-caption
                  : <video controls src={url} />
              }
            </div>
          </div>
          <div className="media-info col-xl-3 col-lg-4 col-md-5 col-12">
            <div className="media-title d-flex align-items-center">
              <div className={`status-icon mr-2 ${(activeImage.status || '').toLowerCase().replace(' ', '-')}`}>
                <i className={`fa ${statusIcon}`} />
              </div>
              <div className="d-flex align-items-center flex-wrap">
                <div className="section-title">{project.name}</div>
                <div>&nbsp;|&nbsp;</div>
                <div>{activeImage.section}</div>
              </div>
            </div>
            <hr />
            {
              activeImage.description && (
                <div className="mt-2 mb-5">
                  <div className="section-title">Description</div>
                  <div className="media-desc" dangerouslySetInnerHTML={{ __html: activeImage.description }} />
                </div>
              )
            }
            <div className="mt-2">
              <MediaFeedback media={activeImage} />
            </div>
          </div>
        </div>

        <i className="fa fa-times-circle-o close-button" data-toggle="tooltip" title="Close" onClick={onClose} />
      </div>

      {
        images.length > 1 && (
          <>
            <i className="btn-prev fa fa-angle-left action-button" data-toggle="tooltip" title="Prev" onClick={() => onGotoNextImage(-1)} />
            <i className="btn-next fa fa-angle-right action-button" data-toggle="tooltip" title="Next" onClick={() => onGotoNextImage(1)} />
          </>
        )
      }
    </div>
  );
};

PreviewModal.propTypes = {
  image: PropTypes.object.isRequired,
  images: PropTypes.array,
  project: PropTypes.object.isRequired,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  createToast: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
};

PreviewModal.defaultProps = {
  images: [],
  show: false,
  onClose: null,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createToast: ToastAction.createToast,
      startLoading: CommonAction.startLoading,
      finishLoading: CommonAction.finishLoading,
    },
    dispatch,
  );
}

export default connect(null, mapDispatchToProps)(PreviewModal);
