import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import './style.scss';

import * as ToastAction from '../../store/actions/toast.action';
import * as PortfolioService from '../../services/portfolio.service';
import * as CommonAction from '../../store/actions/common.action';
import { getCaseStudyHTML } from '../../utils';

const SharedCaseStudy = ({
  match, createToast, startLoading, finishLoading,
}) => {
  const [caseStudy, setCaseStudy] = useState(undefined);

  useEffect(() => {
    const caseStudyId = match.params.id;
    startLoading();
    PortfolioService.getCaseStudy(caseStudyId).then((result) => {
      finishLoading();
      if (result.error) {
        createToast({
          type: 'error',
          message: result.message || 'Cannot read case study content.',
        });
        setCaseStudy(null);
      } else {
        const doc = result.data.caseStudy;
        if (doc.type === 'grapesjs') {
          doc.content = getCaseStudyHTML(doc.type, doc.content.html, doc.content.css);
        } else {
          doc.content = getCaseStudyHTML(doc.type, doc.content);
        }
        setCaseStudy(doc);
      }
    });
  }, [createToast, finishLoading, match.params.id, startLoading]);

  return (
    <div className="shared-case-study-page">
      {
        caseStudy
          ? (
            <iframe title="Case Study" srcDoc={caseStudy.content} />
          )
          : (
            <div className="message h-100 d-flex-center">
              {
                caseStudy === undefined
                  ? 'Loading...'
                  : 'No preview available.'
              }
            </div>
          )
      }
    </div>
  );
};

SharedCaseStudy.propTypes = {
  match: PropTypes.object.isRequired,
  createToast: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createToast: ToastAction.createToast,
      startLoading: CommonAction.startLoading,
      finishLoading: CommonAction.finishLoading,
    },
    dispatch,
  );
}

export default connect(null, mapDispatchToProps)(SharedCaseStudy);
